import { Component, OnInit, ElementRef, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { ChatService } from '../shared/chat.service';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { UserService } from '../shared/user.service';
import { Observable } from 'rxjs';
import { Location, LocationStrategy, PathLocationStrategy, DatePipe } from '@angular/common';
import { SignUpComponent } from '../sign-up/sign-up.component';
// import { EmojiService } from '../emoji.service';
// import { forEach } from '@angular/router/src/utils/collection';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
// import { strict } from 'assert';
// const CryptoJS = require('crypto-js');

import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { DataService } from 'src/app/shared/Helper/services/data.service';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
// import { debug } from 'console';
import { Subscription } from 'rxjs';
import * as firebase2 from 'firebase/app';
import { UserReportComponent } from 'src/app/home/profile/edit-profile/user-report/user-report.component';
// import { MatDialog } from '@angular/material';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;


@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.css'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy },
    SignUpComponent]
})

export class ChatFormComponent implements OnInit {
  // @Output()menuClosed: EventEmitter<void> ;
  // @Output() menuOpened: EventEmitter<void>;
  userStaus : any;
  IsMenuOpen: boolean = false;
  connectstatus: any;
  currentreceiveduser: any;
  public subscriptions$: Subscription[] = [];
  showsearchnot: boolean = false;

  constructor(
    private chatService: ChatService,
    public signUpComp: SignUpComponent,
    public loc: Location,
    public db: AngularFireDatabase,
    private userService: UserService,
    private firebase: AngularFireDatabase,
    private signup: SignUpComponent,
    private getSetService: GetSetService,
    private behavioursubservice: BehavioursubService,
    // public emojiService: EmojiService,
    private authService: CommonService,
    // private fb:FormBuilder
    private dataService: DataService,
    private datePipe: DatePipe,
    public dialog: MatDialog
  ) {

    // this.msgtext=this.fb.group({
    //   textmsg:['']
    // });
    // this is an way for observable on the textbox using the form control
    //     this.textmsg=this.msgtext.controls["textmsg"];
    //     this.textmsg.valueChanges.subscribe(val=>{
    //       console.log(val);
    //     });

  }


  // ************************ message comp
  mesageList = [];
  sender: string;
  receiver: string;
  public msgs: any;
  userList: any = [];
  data = [];
  animals: any;
  senderUser: string;
  receiverUser: string;
  messageLists = [];
  msgCount = {
    id: '',
    count: '',
  };
  countMsg = [];
  pendingMessageList: any;
  msg = [];
  thismsg = [];

  // defualtimg = '../../../assets/img/defaultuser.jsp';
  // pt = 'http://dev.knackbe.com/';
  slectedUserImage = '';

  message: string = null;
  location: Location;
  Changewindow = false;

  // openEmoji() {
  //   document.getElementById('emoji').style.display = 'block';
  // }
  // closeEmoji() {
  //   document.getElementById('emoji').style.display = 'none';
  // }
  // addEmoji(event) {
  //   this.input = this.input + '' + event.emoji.native;
  // }
  // tslint:disable-next-line:member-ordering
  // tslint:disable-next-line:ban-types
  public openPopup: Function;

  // tslint:disable-next-line:member-ordering
  public now: Date = new Date();



  allUserList: any = [];
  allusers: any = [];

  textValue = '';

  // getUsersList()
  // {
  //   this.userList=[];
  //   this.userService.getAllUsers().subscribe(
  //     list =>{
  //       this.userList=list.map(item=>{
  //         this.senderUser=localStorage.getItem('UserId');
  //         return {
  //             $key:item.key,
  //           ... item.payload.val()
  //         }
  //       });
  //     }
  //   );
  // }



  // send an message
  actualmsg = '';

  timer = null;
  k = 0;
  // tslint:disable-next-line:quotemark
  input = "";

  toggled = false;

  // automatic scrolling of the msg container
  // tslint:disable-next-line:member-ordering
  @ViewChild('scroller', { static: true }) private feedcontainer: ElementRef;



  // ************************************* get SelectedUser chat **************************************************
  selectedUser = 0;
  filtersAnimals: any;
  decryptedMsg = [];
  family: string;
  weight: number;
  filters = {};
  animal: any = [];
  status: any;
  selectedName: any;

  sr: any;

  filtersAnimal: any;
  familly: string;
  filter = {};
  emails: any;
  messagestatus: any;
  count: any = 0;


  // *****************search new user that we didn't maked conversation with theme ***************
  flagForNewUser = 0;
  public isSuccess = true;
  public styleClasses = {};
  // logout the user
  // userlist=[];
  user: any;


  msgcount: any = 0;
  // msgtext:FormGroup;
  // textmsg:AbstractControl;


  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    localStorage.removeItem('res');
    this.signup.signOut(localStorage.getItem('FirstName') + ' ' + localStorage.getItem('LastName'));

  }
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.signup.signOut(localStorage.getItem('FirstName') + ' ' + localStorage.getItem('LastName'));
  }
  setPopupAction(fn: any) {
    this.openPopup = fn;
    console.log(this.openPopup);
  }

  refresh() {
    this.location = this.loc;
  }


  getUserlist() {
    this.ngOnInit();
  }

  ngOnInit() {


    this.subscriptions$.push(
      this.behavioursubservice.getIsDashboard$.subscribe(data => {
        if (data == true) {
          this.Changewindow = true;
        }
      }),
      
      );

      this.subscriptions$.push(
      this.behavioursubservice.getIsMessagebox$.subscribe(data => {
        if (data) {
          this.Changewindow = false;
        }else{
          this.Changewindow = true;
        }
      }));

    document.getElementById('chat_window_1').style.display = 'none';
    window.onbeforeunload = () => {
      const msg = 'do you want to close';
      return msg;
    };

    // emoji

    this.userList = [];
    this.user = [];

    this.flagForNewUser = 0;
    const profileInfo = this.getSetService.getCurrentUserInfo();

    this.userService.getAllUsers(profileInfo.userProfileId.toString()).subscribe(
      list => {


        this.userList = [];
        this.userList = list.map(item => {

          this.senderUser = profileInfo.userProfileId.toString();
          return {
            $key: item.key,
            ...item.payload.val()
          };
        });
        this.allUserList = [];
        this.allusers = [];

        this.allUserList = this.userList;
        this.allusers = this.userList;

        this.userList = this.userList.filter(lstMs => lstMs.lastMessage !== '');

        // tslint:disable-next-line:no-string-literal
        // if (this.userList['lastMessage'] === '') {

        //   this.userList.removeItem('userId', [7]);
        // }

        this.firebase.list(`/ConnectedUsers/` + profileInfo.userProfileId.toString()
          + `/` + localStorage.getItem('res')).valueChanges().take(1).subscribe(userList => {


            // console.log(localStorage.getItem('res'));
            userList.forEach((element) => {

              if (element == 'active' || element == 'UnActive') {
                this.status = element.toString();
              }
            })

            if (userList.length > 0) {
              this.connectstatus = userList[1].toString();

            }
            // this.selectedName = userList[8].toString();
            // this.slectedUserImage = userList[2].toString();

          });

        // this.firebase.list(`/ConnectedUsers/` + localStorage.getItem('res')
        // + `/` + profileInfo.userId.toString()).valueChanges().take(1).subscribe(userList => {
        //   if( userList[5] != undefined) 
        //   {
        //     this.status = userList[5].toString();
        //   }
        //   if(userList[1] == 'Connected' || userList[1] == 'New')
        //   {
        //     this.connectstatus = userList[1].toString();

        //   }
        //   // this.selectedName = userList[8].toString();
        //   // this.slectedUserImage = userList[2].toString();

        // });

        this.userList.sort((a, b) => {
          // tslint:disable-next-line:no-angle-bracket-type-assertion
          return <any>new Date(b.time) - <any>new Date(a.time);
        });

      });


    // setting observable to count the pending messages which user not going to seen only message count
    $('#flip').hide('slow');

    $('#flip').click(() => {
      $('#panel').show('slow');
      localStorage.removeItem('res');
      $('#flip').hide('slow');
    });

    $('#closelist').click(() => {
      $('#panel').hide('fast');
      $('#flip').hide('fast');
    });

    // hide the chat container
    // tslint:disable-next-line:space-before-function-paren
    $(document).on('click', '.panel-heading span.icon_minim', function (e) {
      const $this = $(this);
      if (!$this.hasClass('panel-collapsed')) {
        $this.parents('.panel').find('.panel-body').slideUp();
        $this.addClass('panel-collapsed');
        $this.removeClass('glyphicon-minus').addClass('glyphicon-plus');
      } else {
        $this.parents('.panel').find('.panel-body').slideDown();
        $this.removeClass('panel-collapsed');
        $this.removeClass('glyphicon-plus').addClass('glyphicon-minus');
      }
    });
    $(document).on('focus', '.panel-footer input.chat_input', function (e) {
      const $this = $(this);
      if ($('#minim_chat_window').hasClass('panel-collapsed')) {
        $this.parents('.panel').find('.panel-body').slideDown();
        $('#minim_chat_window').removeClass('panel-collapsed');
        $('#minim_chat_window').removeClass('glyphicon-plus').addClass('glyphicon-minus');
      }
    });

  } // end ngOnInit
  // unBlock(){
  //   const profileInfo = this.getSetService.getCurrentUserInfo();
  //   // this.userService.updateConnectReqBlock(profileInfo.userProfileId, this.receiver, 'Connected','Connected');
  //   this.userStaus =  this.userService.getUserStatus(profileInfo.userProfileId, this.receiver)
  //            if(this.userStaus == 'Blocked'){
  //             this.userService.updateConnectReqBlock(profileInfo.userProfileId, this.receiver, 'Connected','Connected');
  //           }
  // }
  
  openReport(type){
		const dialogRefBlock =  this.dialog.open(UserReportComponent, {
			data:{
				type:type,
				profileId:this.receiverUser
			}

		});
		dialogRefBlock.afterClosed().subscribe(async (result) => {
      if(result == 4 ){
        			// this.route.navigate(['home']);
              // const profileInfo = this.getSetService.getCurrentUserInfo();

          //  this.userStaus =  this.userService.getUserStatus(profileInfo.userProfileId, this.receiver)
          //    if(this.userStaus == 'Connected'){
          //   this.userService.updateConnectReqBlock(profileInfo.userProfileId, this.receiver, 'Blocked','UnBlocked');
          // }
      }
			// this.route.navigate(['home']);
		 });

	}

  filterUsersList() {

    if (this.textValue === '' || !(/\S/.test(this.textValue))) {
      this.userList = this.allusers;
      this.userList.sort((a, b) => {
        // tslint:disable-next-line:no-angle-bracket-type-assertion
        return <any>new Date(b.time) - <any>new Date(a.time);
      });
      this.showsearchnot = false;

    } else {
      this.userList = this.allusers.filter(n => (n.username).match((new RegExp(this.textValue, 'i'))));
      if (this.userList.length == 0) {
        this.showsearchnot = true;
      }
      this.userList.sort((a, b) => {
        // tslint:disable-next-line:no-angle-bracket-type-assertion
        return <any>new Date(b.time) - <any>new Date(a.time);

      });
    }

  }

  menuOpened() {
    this.IsMenuOpen = true;
    console.log('menuOpened @configbug')
  }
 
  menuClosed() {
    this.IsMenuOpen = false;
    console.log('menuClosed @configbug')
  }

  removeConnected() {


    this.styleClasses = {};

    // tslint:disable-next-line:no-string-literal
    this.styleClasses['chatbox2'] = this.isSuccess;
    this.flagForNewUser = 0;
    const profileInfo = this.getSetService.getCurrentUserInfo();
    this.userService.updateStatus(localStorage.getItem('res'), 'UnActive');
    localStorage.removeItem('res');
    document.getElementById('chat_window_1').style.display = 'none';
    $('#flip').hide('slow');
    $('#panel').show('slow');
    // this.signUpComp.removeConnected(profileInfo.userId.toString());

  }
  send() {

    if (this.input !== "") {

      if (this.input !== ' ') {

        this.chatService.sendMessage(this.input, 'Connected', 0);
        const receiver = +localStorage.getItem('res');
        this.dataService.setNotification(receiver, this.input);
        this.input = "";
      }
      this.input = "";
    }
  }
  handleSelection(event) {
    this.input = this.input + '' + (event.char);
  }
  handleSubmit() {
    this.send();
  } // handel submit fun end
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewChecked() {
    this.scrollchat();
  }

  scrollchat(): void {
    this.feedcontainer.nativeElement.scrollTop
      = this.feedcontainer.nativeElement.scrollHeight;
  }


  // Get Connected  user chat with login user
  getData(id: any, username: any, status: any, imgPath: any, user: any) {

    this.animal = [];

    // update status of that user which we have clicked there (go online for the selected user for chat)
    // document.getElementById('emoji').style.display = 'none';
    this.textValue = '';
    this.allUserList = this.allusers;
    this.connectstatus = user.connectedStatus;
    this.currentreceiveduser = user;
    this.styleClasses = {};
    this.slectedUserImage = imgPath;
    // tslint:disable-next-line:no-string-literal
    this.styleClasses['chatbox2_side'] = this.isSuccess;
    this.flagForNewUser = 1;
    // if (localStorage.getItem('res')) { this.userService.updateStatus(localStorage.getItem('res'), 'active'); }
    localStorage.setItem('res', id);
    this.userService.updateStatus(id, 'active');

    const profileInfo = this.getSetService.getCurrentUserInfo();

    // update count of this selected user 0
    this.userService.updateCount(profileInfo.userProfileId, id, 0);

    this.selectedUser = id; // add is of selected user into this.selecteduser variable
    this.status = user.status; // display status of selected user
    this.selectedName = username; // display name of selected user
    this.receiverUser = id;
    this.receiver = id;
    const sender = profileInfo.userProfileId;
    this.senderUser = profileInfo.userProfileId;
    const receiver = +localStorage.getItem('res');
    //sender > receiver ? (this.sr = 'chat_id_' + receiver + '_' + sender) :
    this.sr = 'chat_id_' + sender + '_' + receiver;
    let st = 0;
    this.db.list('Messages/' + this.sr).valueChanges().subscribe(d => {
      this.db.list('Messages/' + this.sr).snapshotChanges().map(data => {
        return data.map(
          c => ({
            key: c.payload.key, ...c.payload.val() as {}
          }));
      }).first().subscribe(animals => {

        this.animal = animals;
        if (this.animal.length === 0) {
          this.animal.nochat = ' no any chats found';
        }

        if (localStorage.getItem('res') != null) {

          // const result = this.animal.filter(s => s.receiverId.includes(profileInfo.userProfileId.toString())
          //   && (s.isseen === false));
          const result = this.animal.filter(x => x.isseen === false && x.receiverId === sender.toString());

          result.forEach(element => {
            this.chatService.updateMsgAsSeen(element.key, 'chat_id_' + receiver + '_' + sender, true);
          });
        }

      });
    });

    document.getElementById('chat_window_1').style.display = 'block';
  } // end of getData()


  changeconstatus(status: any, msg: any) {

    const profileInfo = this.getSetService.getCurrentUserInfo();
    this.userService.updateConnectReq(profileInfo.userProfileId, this.receiver, status);
    this.connectstatus = status;
    this.currentreceiveduser.connectedStatus = status;

    this.chatService.sendMessage('', status, 1);


  }


  requstemessage() {

    const profileInfo = this.getSetService.getCurrentUserInfo();
    this.userService.updateConnectReqnew(profileInfo.userProfileId, this.receiver, 'Pending');

  }


  private applyFilters() {
    this.filtersAnimals = _.filter(this.animal, _.conforms(this.filters));
  }

  filterExact(property: string, rules: any) {
    this.filters[property] = val => val === rules;
    this.applyFilters();
  }

  searchNewUserToChat() {
    if (this.flagForNewUser === 0) {
      // tslint:disable-next-line:no-string-literal
      this.styleClasses['chatbox2'] = this.isSuccess;
    } else {
      // tslint:disable-next-line:no-string-literal
      this.styleClasses['chatbox2_side'] = this.isSuccess;
    }
    // document.getElementById('chat_window_2').style.display = 'block';
  }


  closeConnectedList() {
    // document.getElementById('chat_window_2').style.display = 'none';
  }

  logoutChat() {
    localStorage.removeItem('res');

    document.getElementById('chatComp').style.display = 'none';
    const profileInfo = this.getSetService.getCurrentUserInfo();
    this.signUpComp.removeConnected(profileInfo.userProfileId.toString());
    $('#flip').hide('slow');
    $('#panel').show('slow');
    // this.signup.signUpp('active');
  }
  getMsgCount(id) {
    return id;
  }

  // encryption
  public funEncryptMessage(msg) {
    const key = 'knack5*Be';
    const encryption = CryptoJS.AES.encrypt(msg.trim(), key.trim()).toString();
    return encryption;
  }
  public funDecryptMessage(msg) {
    const key = 'knack5*Be';
    const decrypt = CryptoJS.AES.decrypt(msg.trim(), key.trim()).toString(CryptoJS.enc.Utf8);
    return decrypt;
  }



  ChangeWindow() {

    this.Changewindow = !this.Changewindow;
  }


}