import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    // tslint:disable-next-line:no-shadowed-variable
    private firebase: AngularFireDatabase,
    private getSetService: GetSetService,
    private af: AngularFireAuth) {
  }
  data = {
    $key: null,
    name: '',
    email: '',
    status: '',
  };


  public connecteduserLists: AngularFireList<any>;

  // get all user in the list
  public userLists: AngularFireList<any>;

  useStaus :AngularFireList<any>;
  // OBJSERVE ON PERTICULAR USER STATUS
  public userStatus: AngularFireList<any>;

  public messageList: AngularFireList<any>;


  public connected: AngularFireList<any>;
  // *******************************// *******************************
  list: any;

  pendingMessageList: any;

  // user already inserted so that update user status online only
  // method call from sign up update status & chat-form component to logout user



  public userList: AngularFireList<any>;


  user: any;
  getAllUsers(id) {
    
    this.userLists = this.firebase.list('ConnectedUsers/' + id);
    return (this.userLists.snapshotChanges());
  }
  getUserStatus(myid, selectedUserId) {

//every user must have an connectedStatus
   firebase.database().ref(`ConnectedUsers/${myid}/${selectedUserId}/connectedStatus`).once("value", snapshot => {
  if (snapshot.exists()){
     const email = snapshot.val();
     this.userStatus = email;
  }
  
});
return this.userStatus;
    // this.userStatus = this.firebase.list('ConnectedUsers/' + myid + '/' + selectedUserId);
    // return (this.userStatus.snapshotChanges());
    
  }
  
  getAllMessage() {
    this.messageList = this.firebase.list('Messages');
    return (this.messageList.snapshotChanges());
  }
  getAllConnected() {
    
    this.connected = this.firebase.list('connected');
    return (this.messageList.snapshotChanges());
  }
  getIndividualVisitor(email: string) {

  }
  getMassageOfUnseen() {
    this.pendingMessageList = this.firebase.list('messages', ref => ref.orderByChild('isseen').equalTo('false'));
    return this.pendingMessageList;
  }
  // *******************************// *******************************

  insertConnectedUsers(userDetail: any, email: any) {
    const connecteduserLists = this.firebase.list('ConnectedUsers/' );
    connecteduserLists.set(email, userDetail);
    // const bucketRef = this.firebase.list('/ConnectedUsers/' + email );
    // bucketRef.push(userDetail);
  }
  insertUsers(userDetail: any, email: any) {
    this.userLists = this.firebase.list('Users/');
    this.userLists.set(email, userDetail);
  }

  // updateConnected(key: string, details: { receiver: string; sender: string; status: string; }): any {}

  updateConnected(key: string, details: any): any {
    this.getAllConnected();
    this.connected.update(key, details);
  }


  removeUser(key: any) {
    this.getAllConnected();
    this.connected.remove(key);
  }


  getAllUser(email: any) {
    
    this.userList = this.firebase.list(`ConnectedUsers/${email}`);
    return (this.userLists.snapshotChanges());
  }

  


  updateStatus(id, Status) {
    
    const profileInfo = this.getSetService.getCurrentUserInfo();
    const f = this.firebase.list('ConnectedUsers/' + id + '/');
    f.update( profileInfo.userProfileId.toString(), {
      status: Status
    });
  }

  updateImageStatus(id, Image) {
    
    const profileInfo = this.getSetService.getCurrentUserInfo();
    const f = this.firebase.list('ConnectedUsers/' + id + '/');
    f.update( profileInfo.userProfileId.toString(), {
      imagePath: Image
    });
  }

  updateCount(myid, receiverId, count): any {
    
    const f = this.firebase.list('ConnectedUsers/' + myid + '/');
    f.update(receiverId.toString(), {
      unreadMessageCount: count
    });
  }

  updateLstMessage(myid, receiverId, msg, time): any {
    
    // save latest message to receiver table of self id
    const f = this.firebase.list('ConnectedUsers/' + myid + '/');
    f.update(receiverId.toString(), {
      lastMessage: msg,
      time
    });

    // save latest message in to self document in the id of selected user to chat
    const f1 = this.firebase.list('ConnectedUsers/' + receiverId + '/');
    f1.update(myid, {
      lastMessage: msg,
      time
    });
  }



  updateConnectReq(myid, receiverId, constatus): any {
    const f = this.firebase.list('ConnectedUsers/' + myid + '/');
    f.update(receiverId.toString(), {
      connectedStatus: constatus,
    });

    // save latest message in to self document in the id of selected user to chat
    const f1 = this.firebase.list('ConnectedUsers/' + receiverId + '/');
    f1.update(myid.toString(), {
      connectedStatus: constatus,
    });
  }


  updateConnectReqnew(myid, receiverId, constatus): any {
    
    const f = this.firebase.list('ConnectedUsers/' + myid + '/');
    f.update(receiverId.toString(), {
      connectedStatus: constatus,
    });

    // save latest message in to self document in the id of selected user to chat
    const f1 = this.firebase.list('ConnectedUsers/' + receiverId + '/');
    f1.update(myid.toString(), {
      connectedStatus: 'Requested',
    });
  }

  updateConnectReqBlock(myid, receiverId, senderStatus:string,receiverStatus:string): any {
    
    const f = this.firebase.list('ConnectedUsers/' + myid + '/');
    f.update(receiverId.toString(), {
      connectedStatus: senderStatus,
    });

    // save latest message in to self document in the id of selected user to chat
    const f1 = this.firebase.list('ConnectedUsers/' + receiverId + '/');
    f1.update(myid.toString(), {
      connectedStatus: receiverStatus,
    });
  }



  // updateLstTime(myid,receiverId,time): any {
  //   let f=this.firebase.list("ConnectedUsers/"+myid+'/');
  //   f.update(receiverId,{
  //           time:time
  //       });
  //   let f1=this.firebase.list("ConnectedUsers/"+ receiverId +'/');
  //   f1.update(myid,{
  //           lastMessage:msg
  //       });
  // }



  //   var user = this.getMassageOfUnseen();
  //   user.valueChanges().subscribe(list=>{
  //       this.user = this.firebase.list('/messages', ref => ref
  //       .orderByChild('receiver')
  //       .equalTo(localStorage.getItem('UserId'))
  //     )
  //     .snapshotChanges()
  //     .map(changes => {
  //       return changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
  //     })
  //     .first()
  //     .subscribe(snapshots => {
  //       snapshots.forEach(snapshot => {
  //       this.pendingMessageList.update(snapshot.key,
  //       {
  //          status:'seen'
  //       });

  //       });
  //     });
  // })

  //  }
  updateuserStatus(id, status) {
    
    const f = this.firebase.list('Users/');
    f.update(id, {
      status
    });
  }
  updateuserImage(id, imagePath) {
        const f = this.firebase.list('Users/');
    f.update(id,{imagePath});
  }
  updateMessageStatus(key, data) {
    this.messageList.update(key, data);
  }

}
