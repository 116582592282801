import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
// import {// MatOptionModule,
// 	MatSelectModule,
// 	MatFormFieldModule,
// 	MatIconModule
// } from '@angular/material';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ApprovenotificationComponent } from './header/approvenotification/approvenotification.component';
import { RemovenotificationComponent } from './header/removenotification/removenotification.component';
import { RouterModule } from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import { RejectRequestPopupComponent } from '../home/profile/edit-profile/notification/reject-request-popup/reject-request-popup.component';
import { UserReportComponent } from '../home/profile/edit-profile/user-report/user-report.component';
import {MatListModule} from '@angular/material/list';
// import { MatOptionModule } from '@angular/material/core/option';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [SubHeaderComponent, ChangePasswordComponent, ApprovenotificationComponent, RemovenotificationComponent,RejectRequestPopupComponent,UserReportComponent],
	imports: [
		CommonModule,
		// MatOptionModule,
		MatSelectModule,
		MatDialogModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		MatTooltipModule,
		MatListModule


	],
	exports: [SubHeaderComponent, ChangePasswordComponent],
	entryComponents: [ChangePasswordComponent, ApprovenotificationComponent, RemovenotificationComponent,RejectRequestPopupComponent,UserReportComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
