import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	ResponseModel,
	HttpStatusCode
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { ICuratedResult, IRequestContact } from './left-profile-curated.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class LeftProfileCuratedService {
	prevPageNo: number = 0;


	constructor(
		private http: HttpClient
	) { }

	getCuratesResults(url: string, pageNo: number): Observable<ResponseModel<ICuratedResult[]>> {
		// if(this.prevPageNo !== pageNo){
		this.prevPageNo = pageNo;
		return this.http.get(url).pipe(
			map((response: ResponseModel<ICuratedResult[]>) => {
				this.prevPageNo = 0;
				return response;
			})
		);

	}

	requestContact(
		url: string,
		model: IRequestContact
	): Observable<ResponseModel<IRequestContact>> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<IRequestContact>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}
}
