import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { IFilterSearch } from 'src/app/home/dashboard/dashboard-search/startup-search/startup-search.model';

@Injectable({
	providedIn: 'root'
})
export class BehavioursubService {
	// private readonly onDestroy = new BehaviorSubject('');

	private updateuserloginstatus = new BehaviorSubject(false);
	loginstatus$ = this.updateuserloginstatus.asObservable();

	Updateloginuser(islogin: boolean) {
		this.updateuserloginstatus.next(islogin);
	}

	private updateswitchstatus = new BehaviorSubject(false);
	switchstatus$ = this.updateswitchstatus.asObservable();

	updateSwitch(isswitch:boolean){
		this.updateswitchstatus.next(isswitch);
	}

	private updatechatuserlist = new BehaviorSubject(false);
	updatelchatuserstatus$ = this.updatechatuserlist.asObservable();

	Updatechatuser(isget: boolean) {
		this.updatechatuserlist.next(isget);
	}

	/**
	 * Subject for filter search in news-feed-component
	 */
	private filterSearch = new BehaviorSubject({
		userProfileId: 0,
		commingFrom: 0,
		lookingFor: 0,
		budgetId: 0,
		industryId: 0,
		saleId: 0,
		stageId: 0,
		roleId: 0,
		exp_Year: 0,
		timeCommitment: 0,
		countryId: 0,
		cityId: 0,
		currencyId: 0,
		expectedJoiningId: 0,
		pageSize: 10,
		pageNumber: 1
	});
	filterSearchData$ = this.filterSearch.asObservable();

	currentFilterData(filterData: IFilterSearch) {
		this.filterSearch.next(filterData);
	}

	/**
	 * Subject for get looking for Id in sub-header component
	 */
	private lookingFor = new BehaviorSubject(0);
	getLookingFor$ = this.lookingFor.asObservable();

	currentLookingForId(Id: number) {
		this.lookingFor.next(Id);
	}

	private incompleteLookingId = new BehaviorSubject(0);
	getIncompleteId$ = this.incompleteLookingId.asObservable();

	currentIncompleteId(roleId: number) {
		this.incompleteLookingId.next(roleId);
	}

	/**
	 * Subject for get roleId in header component
	 */
	private role = new BehaviorSubject(0);
	getRoleId$ = this.role.asObservable();

	currentRole(Id: number) {
		this.role.next(Id);
	}

	private info = new BehaviorSubject({});
	getUserInfo$ = this.info.asObservable();

	logininfo = new ReplaySubject<any>(1);
	getloginInfo$ = this.logininfo.asObservable();

	currentUserInfo(info: any) {
		this.info.next(info);
	}

	loginuserinfo(logininfo: any) {
		this.logininfo.next(logininfo);
	}

	private isRoleData = new BehaviorSubject(false);
	getIsRoleData$ = this.isRoleData.asObservable();

	currentIsRoleData(flag: boolean) {
		this.isRoleData.next(flag);
	}

	// clear view all page filter
	private isclearFilter = new BehaviorSubject(false);
	getIsClearFilter$ = this.isclearFilter.asObservable();

	currentIsClearFilter(flag: boolean) {
		this.isclearFilter.next(flag);
	}


	private closeFilterModal = new BehaviorSubject(false);
	FilterModal$ = this.closeFilterModal.asObservable();

	currentFilterModalStatus(flag) {
		this.closeFilterModal.next(flag);
	}

	/**
	 * To call GetUserProfileDD API in header component
	 */
	private isGetRole = new BehaviorSubject(false);
	getRole$ = this.isGetRole.asObservable();

	refreshUserRole(flag) {
		this.isGetRole.next(flag);
	}

	/**
	 * To get Role and Looking profile
	 */
	private refreshRoleAndLookingId = new BehaviorSubject(false);
	getRoleLookingId$ = this.refreshRoleAndLookingId.asObservable();

	currentRoleLooking(flag) {
		this.refreshRoleAndLookingId.next(flag);
	}

	/**
	 * To check user is on dashboard
	 */
	private dashboard = new BehaviorSubject(false);
	getIsDashboard$ = this.dashboard.asObservable();

	isOnDashboard(flag) {
		this.dashboard.next(flag);
	}
		/**
	 * To check user update notification
	 */
	private notification = new BehaviorSubject(false);
	getIsNotification$ = this.notification.asObservable();

	isUpdateNotification(flag) {
		this.notification.next(flag);
	}
	/**
	 * To check user is on Message Box
	 */
	 private messagebox = new BehaviorSubject(false);
	 getIsMessagebox$ = this.messagebox.asObservable();
 
	 isOnmessagebox(flag) {
		 this.messagebox.next(flag);
	 }
 

	private Bgcolor = new BehaviorSubject(false);
	getIsBgcolor$ = this.Bgcolor.asObservable();

	isbgcolor(flag) {
		this.Bgcolor.next(flag);
	}


	/**  LookingFor  **/

	private Profile = new BehaviorSubject(false);
	getProfilefor$ = this.Profile.asObservable();

	IsProfileFor(flag) {
		this.Profile.next(flag);
	}


	/**
	 * Following
	 */

	private isFollowingAcive = new BehaviorSubject(false);
	getFollowingIsActiveStatus$ = this.isFollowingAcive.asObservable();

	currentIsFollowingActive(flag) {
		this.isFollowingAcive.next(flag);
	}

	/**
	 * Is Search user list data
	 */

	private UserList = new BehaviorSubject(false);
	getCurrentUserList$ = this.UserList.asObservable();

	currentUserList(flag) {
		this.UserList.next(flag);
	}

	/**When Login page is active */

	private OnLoginPage = new BehaviorSubject(false);
	getOnLoginPageStatus$ = this.OnLoginPage.asObservable();

	isOnLoginPage(status: boolean) {
		this.OnLoginPage.next(status);
	}

	private postRefresh = new BehaviorSubject(false);
	getPostListStatus$ = this.postRefresh.asObservable();

	currentPostList(flag) {
		this.postRefresh.next(flag);
	}

	private onMyPost = new BehaviorSubject(false);
	getMyPostPageStatus$ = this.onMyPost.asObservable();

	currentOnMyPostStatus(flag) {
		this.onMyPost.next(flag);
	}

	private ArticlePost = new BehaviorSubject(false);
	getArticlePost$ = this.ArticlePost.asObservable();

	currentArticlePost(flag) {
		this.ArticlePost.next(flag);
	}

	/**
	 * Is Post Newly uploaded or updated
	 */

	private postId = new BehaviorSubject(0);
	getPostId$ = this.postId.asObservable();

	currentMyPostId(id: number) {
		this.postId.next(id);
	}

	private onViewAll = new BehaviorSubject(false);
	getViewPageStatus$ = this.onViewAll.asObservable();

	currentOnViewPageStatus(flag) {
		this.onViewAll.next(flag);
	}

	private filterResult = new BehaviorSubject('');
	getFilterData$ = this.filterResult.asObservable();

	currentFilter(data) {
		this.filterResult.next(data);
	}

	private premiumSubscription = new BehaviorSubject(false);
	$_Observable_PremiumSuccess$ = this.premiumSubscription.asObservable();

	currentPremiumSuccess(flag) {
		this.premiumSubscription.next(flag);
	}

	private ratingSubscription = new BehaviorSubject(false);
	$_Observable_RatingSuccess$ = this.ratingSubscription.asObservable();

	currentRatingSuccess(flag) {
		this.ratingSubscription.next(flag);
	}

	private verifiedSubscription = new BehaviorSubject(false);
	$_Observable_VerifySuccess$ = this.verifiedSubscription.asObservable();

	currentVerifiedSuccess(flag) {
		this.ratingSubscription.next(flag);
	}

	private useronfiprofile = new BehaviorSubject('');
	userinformation$ = this.useronfiprofile.asObservable();

	userinfosend(useronfiprofile: any) {
		this.useronfiprofile.next(useronfiprofile);
	}

	private selectstaticdataid = new BehaviorSubject('');
	getselectstaticdataid$ = this.selectstaticdataid.asObservable();

	getstatictagid(selectstaticdataid: any) {
		this.selectstaticdataid.next(selectstaticdataid);
	}

}
