// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	// ApiUrl: 'https://testapi.entie.co',
	// ApiUrl: 'https://entieapi.knackbe.in/',
	 ApiUrl: 'https://entieapi.knackbe.in/',
		// ApiUrl: 'https://liveapi.entie.co/',
	//  ApiUrl: 'https://qaapi.entie.co/apidata',
	// ApiUrl: 'https://devapi.entie.co/',

	razorPayKey: 'rzp_test_4E7znZr9KwOe8l',
	Chatapiurl: 'https://chat.entie.co/Chat/',
// // For live enviornment firebase configuration
	// firebaseConfig: {
	// 	apiKey: "AIzaSyDmLNWAdxBDnUjIR4kv5yv3Wqv2VNQiq70",
	// 	databaseURL: "https://enite-42293.firebaseio.com/",
	// 	projectId: "enite-42293",
	// 	storageBucket: "enite-42293.appspot.com",
	// 	messagingSenderId: "136268916031"
	//   }

	// For Firebase JS SDK v7.20.0 and later, measurementId is optional
	//For QA Env
 firebaseConfig : {
	apiKey: "AIzaSyDM8_xzOiNm6lGVf_ghzxXb-Pe0rBEPziM",
	authDomain: "entie-flutter-cc744.firebaseapp.com",
	databaseURL: "https://entie-flutter-cc744-default-rtdb.firebaseio.com",
	projectId: "entie-flutter-cc744",
	storageBucket: "entie-flutter-cc744.appspot.com",
	messagingSenderId: "249749541921",
	appId: "1:249749541921:web:dbc04dd9411d1907697f60",
	measurementId: "G-2M2J2GYQ20"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
