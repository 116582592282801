import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KnowledgecenterComponent } from './knowledgecenter/knowledgecenter.component';
import { KnowledgecenterdetailComponent } from './knowledgecenterdetail/knowledgecenterdetail.component';

const routes: Routes = [
	{
		path: '',
		component: KnowledgecenterComponent
	},
	{
		path: 'details',
		component: KnowledgecenterdetailComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class KnowledgecenterRoutingModule {}
