import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
	ResponseModel,
	HttpStatusCode,
} from '../Helper/Responsemodel/responsemodel';
import { BehavioursubService } from '../Helper/services/behavioursub.service';
import { CommonService } from '../Helper/services/common.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { GetSetService } from '../Helper/services/get-set.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EncrDecrService } from '../Helper/services/encr-decr.service';

@Component({
	selector: 'app-serach',
	templateUrl: './serach.component.html',
	styleUrls: ['./serach.component.css'],
})
export class SerachComponent implements OnInit, OnDestroy {
	public subscriptions$: Subscription[] = [];
	profileInfo: any;
	isStartup = false;
	isInvestor = false;
	isCofounder = false;
	isMgf = false;
	text: any;
	Term: any;
	pageSize = 50;
	pageNumber = 1;
	Id = 0;
	searchlist: any[];
	filterForm: FormGroup;
	isPremium: boolean = false;
	isVerified: boolean = false;
	isAll = true;
	priceRange: any;
	userInfo: any;
	isLoading = false;

	constructor(
		private commonservice: CommonService,
		private behaviourSubject: BehavioursubService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private getSetService: GetSetService,
		private encrDecrService: EncrDecrService
	) {
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				this.Term = this.route.snapshot.paramMap.get('query');

				if (document.getElementById('searchinput') !== null) {
					(<HTMLInputElement>(
						document.getElementById('searchinput')
					)).value = this.Term;
				}
				this.searchlist = [];
				this.userInfo = this.getSetService.getCurrentUserInfo();

				switch (this.userInfo.profileId) {
					case 1:
						this.isInvestor = true;
						this.isStartup = false;
						this.isMgf = false;
						this.isCofounder = false;
						this.Id = 2;
						break;

					case 2:
						this.isInvestor = false;
						this.isStartup = true;
						this.isMgf = false;
						this.isCofounder = false;
						this.Id = 1;
						break;

					case 3:
						this.isInvestor = false;
						this.isStartup = true;
						this.isMgf = false;
						this.isCofounder = false;
						this.Id = 1;
						break;

					case 4:
						this.isInvestor = false;
						this.isStartup = false;
						this.isMgf = true;
						this.isCofounder = false;
						this.Id = 4;
						break;

					default:
						break;
				}
				this.UserSearchList();
			}
		});
		this.filterForm = this.fb.group({
			isPremium: [false],
			isVerified: [false],
		});
	}

	ngOnDestroy() {
		this.Term = '';
		if (document.getElementById('searchinput') !== null) {
			(<HTMLInputElement>document.getElementById('searchinput')).value = '';
		}
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}

	ngOnInit() {
		this.text = document.getElementById('searchinput');
		this.behaviourSubject.isbgcolor(true);
		this.getUserProfileInfo();
		// this.UserSearchList();
		this.subscriptions$.push(
			this.behaviourSubject.getCurrentUserList$.subscribe((flag) => {
				this.userInfo = this.getSetService.getCurrentUserInfo();

				switch (this.userInfo.profileId) {
					case 1:
						this.isInvestor = true;
						this.isStartup = false;
						this.isMgf = false;
						this.isCofounder = false;
						this.Id = 2;
						break;

					case 2:
						this.isInvestor = false;
						this.isStartup = true;
						this.isMgf = false;
						this.isCofounder = false;
						this.Id = 1;
						break;

					case 3:
						this.isInvestor = false;
						this.isStartup = true;
						this.isMgf = false;
						this.isCofounder = false;
						this.Id = 1;
						break;

					case 4:
						this.isInvestor = false;
						this.isStartup = false;
						this.isMgf = true;
						this.isCofounder = false;
						this.Id = 4;
						break;

					default:
						break;
				}

				// this.Id = this.userInfo.profileId;
				if (flag) {
					this.UserSearchList();
				}
			})
		);
	}

	getUserProfileInfo() {
		// const url = `${environment.ApiUrl}/api/User/Myprofie`;
		// this.commonservice.get(url).subscribe((response: ResponseModel<any>) => {
		// 	if (response.status === HttpStatusCode.OK) {
		// 		this.profileInfo = response.data;
		// 	}
		// });

		var delay;
		delay = setInterval(() => {
			if (this.profileInfo == null) {
				switch (localStorage.getItem('mainProfileId')) {
					case '1':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('1'));
						break;

					case '2':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('2'));
						break;

					case '3':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('3'));
						break;

					case '4':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('4'));
						break;

					default:
						break;
				}
			} else {
				clearInterval(delay);
				//this.IsProfile = false;
			}
		}, 1000);
	}

	selectRole(RoleId: any) {
		if (RoleId === 1) {
			this.Id = 1;
			this.isStartup = true;
			this.isInvestor = false;
			this.isCofounder = false;
			this.isMgf = false;
			this.isAll = false;
		} else if (RoleId === 2) {
			this.Id = 2;
			this.isInvestor = true;
			this.isStartup = false;
			this.isCofounder = false;
			this.isMgf = false;
			this.isAll = false;
		} else if (RoleId === 3) {
			this.Id = 3;
			this.isCofounder = true;
			this.isStartup = false;
			this.isInvestor = false;
			this.isMgf = false;
			this.isAll = false;
		} else if (RoleId === 4) {
			this.Id = 4;
			this.isMgf = true;
			this.isStartup = false;
			this.isInvestor = false;
			this.isCofounder = false;
			this.isAll = false;
		} else {
			this.Id = 0;
			this.isStartup = false;
			this.isInvestor = false;
			this.isCofounder = false;
			this.isMgf = false;
			this.isAll = true;
		}
		this.UserSearchList();
	}

	UserSearchList() {
		this.searchlist = [];
		this.isLoading = true;
		const obj = {
			userProfileId: 0,
			commingFrom: this.Id,
			lookingFor: this.Id,
			budgetId: 0,
			industryId: 0,
			saleId: 0,
			stageId: 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: 0,
			cityId: 0,
			currencyId: 0,
			expectedJoiningId: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
			premium: 0,
			verified: 0,
			rating: 0,
			searchStr: this.Term,
		};

		const url = environment.ApiUrl + '/api/User/FilteredSearch';
		this.commonservice
			.post(url, obj)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.searchlist = response.data;
					this.isLoading = false;
				} else {
					this.isLoading = false;
				}
			});
	}

	clearFilters() {
		this.filterForm.reset();
		this.priceRange = '';
	}

	filterData() {
		const data = {
			userProfileId: 0,
			commingFrom: 0,
			lookingFor: 0,
			budgetId: 0,
			industryId: 0,
			saleId: 0,
			stageId: 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: 0,
			cityId: 0,
			currencyId: 0,
			expectedJoiningId: 0,
			pageSize: 10,
			pageNumber: 1,
			premium: this.filterForm.value.isPremium ? 1 : 0,
			verified: this.filterForm.value.isVerified ? 1 : 0,
			rating: this.priceRange,
			searchStr: '',
		};

		const url = `${environment.ApiUrl}/api/Dashboard/NewsFeedFilter`;
		this.commonservice
			.post(url, data)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.searchlist = response.data;
				} else {
					// this.toastrService.success(response.statusMessage);
				}
			});
	}
}
