import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { ResponseModel, HttpStatusCode } from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { PostDetailComponent } from '../post-detail/post-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EncrDecrService } from 'src/app/shared/Helper/services/encr-decr.service';

@Component({
  selector: 'app-postslist',
  templateUrl: './postslist.component.html',
  styleUrls: ['./postslist.component.css']
})
export class PostslistComponent implements OnInit, OnDestroy {

  public subscriptions$: Subscription[] = [];
  postlist = [];
  pageSize = 50;
  pageNumber = 1;
  rpageSize = 10;
  rpageNumber = 1;
  comment: any;
  IsComment = false;
  ReplyCommentList: any[];
  IsReplyComment = false;
  commentlist: any[];
  profileInfo: any;
  commenttrue = false;
  openReplyBox = false;
  ishideReply = false;
  isdesc = false;
  IsFulldesc = false;
  statusMessage = false;

  constructor(
    private commonservice: CommonService,
    private behaviourSubjectService: BehavioursubService,
    public dialog: MatDialog,
    
    private encrDecrService: EncrDecrService ) { }

  ngOnInit() {
    this.getpostlist();
    this.getUserProfileInfo();
    this.subscriptions$.push(
      this.behaviourSubjectService.getPostListStatus$.subscribe((flag) => {
        if (flag) {
          this.getpostlist();
        }
      }));
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
  }

  getUserProfileInfo() {
    // const url = `${environment.ApiUrl}/api/User/Myprofie`;
    // this.commonservice.get(url).subscribe(
    //   (response: ResponseModel<any>) => {
    //     if (response.status === HttpStatusCode.OK) {
    //       this.profileInfo = response.data;
    //     }
    //   });


    var delay;
		delay = setInterval(() => {
			if (this.profileInfo == null) {
				switch (localStorage.getItem('profileId')) {
					case '1':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('1'));
						break;

					case '2':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('2'));
						break;

					case '3':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('3'));
						break;

					case '4':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('4'));
						break;

					default:
						break;
				}
			} else {
				clearInterval(delay);
				//this.IsProfile = false;
			}
		}, 1000);
  }

  getpostlist() {
    const url = `${environment.ApiUrl}/api/Post/listPost?PageSize=${this.pageSize}&PageNumber=${this.pageNumber}`;
    this.commonservice.get(url).subscribe(
      (response: ResponseModel<any[]>) => {
        if (response.status === HttpStatusCode.OK) {
          // this.postlist = response.data;
          this.postlist = [];
          (response.data as Array<any>).forEach((element) => {
            if (!element.hasOwnProperty('commentlist')) {
              element['commentlist'] = [];
              element['isCommentOpen'] = false;
              this.postlist.push(element);
            }
          });
        } else {
        }
      });
  }

  // GetCommentList
  getCommentList(post: any) {
    const obj = {
      userProfileId: post.userProfileId,
      postId: post.id,
      commentId: 0,
      pageSize: 5,
      pageNumber: this.pageNumber
    };
    const url = `${environment.ApiUrl}/api/Post/GetPostComments`;
    this.commonservice.post(url, obj).subscribe(
      (response: ResponseModel<any[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.IsComment = true;
          this.commentlist = response.data;
          if (post.commentlist !== undefined && post.commentlist !== null) {
            // post.commentlist = response.data;
            (response.data as Array<any>).forEach((ele) => {
              post.commentlist.push(ele);
            });
            this.pageNumber++;
          }

          if ((post.commentlist as Array<any>).length !== 0) {
            (post.commentlist as Array<any>).forEach((element) => {
              element['isShowLess'] = false;
              element['isReplyOpen'] = false;
            });
          }

          // if((post.commentlist as Array<any>).length !== 0) {
          //   (post.commentlist as Array<any>).forEach((element) => {
          //     if(!element.hasOwnProperty('replyComment'))
          //       element['replyComment'] = [];
          //       element['isShowLess'] = false;
          //       element['isReplyOpen'] = false;
          //   });
          // }
          // console.log(post.commentlist)
        } else {
          this.statusMessage = true;
        }
      });
  }

  // Add Comment
  AddComment(event: any, comment: any, post: any) {
    if (event.target.value.trim() !== '') {
      const obj = {
        commentId: 0,
        postId: post.id,
        comment: comment.value,
        fullName: post.postedBy,
        parentId: 0
      };
      const url = `${environment.ApiUrl}/api/Post/AddPostComment`;
      this.commonservice.post(url, obj).subscribe(
        (response: ResponseModel<any[]>) => {
          if (response.status === HttpStatusCode.OK) {
            comment.value = '';
            response.data['isReplyOpen'] = false;
            response.data['isShowLess'] = false;
            post.commentCount += 1;
            post.commentlist.splice(0, 0, response.data);
            if ((post.commentlist as Array<any>).length !== 0) {
              (post.commentlist as Array<any>).forEach((element) => {
                if (!element.hasOwnProperty('replyComment'))
                  element['replyComment'] = [];
                // element['isShowLess'] = false;
                // element['isReplyOpen'] = false;
              });
            }
          }
        }
      );
    }
  }

  AddCommentpost(comment: any, post: any) {
    if (comment.value !== '') {
      const obj = {
        commentId: 0,
        postId: post.id,
        comment: comment.value,
        fullName: post.postedBy,
        parentId: 0
      };
      const url = `${environment.ApiUrl}/api/Post/AddPostComment`;
      this.commonservice.post(url, obj).subscribe(
        (response: ResponseModel<any[]>) => {
          if (response.status === HttpStatusCode.OK) {
            comment.value = '';
            response.data['isReplyOpen'] = false;
            response.data['isShowLess'] = false;
            post.commentCount += 1;
            post.commentlist.splice(0, 0, response.data);
          }
        }
      );
    }
  }

  // Reply Comment
  replyComment(event, Replycomment: any, comment: any, post: any) {
    if (event.target.value.trim() !== '') {
      const obj = {
        commentId: 0,
        postId: post.id,
        comment: Replycomment.value,
        fullName: post.postedBy,
        parentId: comment.commentId
      };
      const url = `${environment.ApiUrl}/api/Post/AddCommentReply`;
      this.commonservice.post(url, obj).subscribe(
        (response: ResponseModel<any[]>) => {
          if (response.status === HttpStatusCode.OK) {
            Replycomment.value = '';
            this.IsReplyComment = true;
            comment.isReplyOpen = false;
            response.data['isReplyShowLess'] = false;
            // this.openReplyBox = false;
            document.getElementById(comment.commentId).style.display = 'none';
            (comment.replyComment as Array<any>).splice(0, 0, response.data);
            //  console.log(comment.replyComment);
          } else {
          }
        }
      );
    }
  }

  replyCommentPost(Replycomment: any, comment: any, post: any) {
    if (Replycomment.value !== '') {
      const obj = {
        commentId: 0,
        postId: post.id,
        comment: Replycomment.value,
        fullName: post.postedBy,
        parentId: comment.commentId
      };
      const url = `${environment.ApiUrl}/api/Post/AddCommentReply`;
      this.commonservice.post(url, obj).subscribe(
        (response: ResponseModel<any[]>) => {
          if (response.status === HttpStatusCode.OK) {
            Replycomment.value = '';
            this.IsReplyComment = true;
            comment.isReplyOpen = false;
            response.data['isReplyShowLess'] = false;
            // this.openReplyBox = false;
            document.getElementById(comment.commentId).style.display = 'none';
            (comment.replyComment as Array<any>).splice(0, 0, response.data);
          } else {
          }
        }
      );
    }
  }

  // Reply comment List
  getReplyCommentList(comment: any, post: any) {
    const obj = {
      userProfileId: this.profileInfo.userprofileId,
      commentId: comment.commentId,
      postId: post.id,
      pageSize: this.rpageSize,
      pageNumber: this.rpageNumber
    };
    const url = `${environment.ApiUrl}/api/Post/GetCommentReply`;
    this.commonservice.post(url, obj).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.IsReplyComment = true;
          comment.replyComment = [];
          (response.data as Array<any>).forEach((element) => {
            if (!element.hasOwnProperty('isReplyShowLess')) {
              element['isReplyShowLess'] = false;
            }
            (comment.replyComment as Array<any>).push(element);
          });
        } else {
        }
      }
    );

  }

  // Display reply comment
  showReplyBox(id: any) {
    this.ishideReply = true;
    document.getElementById(id).style.display = 'block';
  }

  HideReplyBox(id: any) {
    this.ishideReply = false;
    document.getElementById(id).style.display = 'none';
  }


  // Like Post
  LikePost(posts: any) {
    posts.isLiked = !posts.isLiked;
    if (posts.isLiked === true) {
      posts.likeCount += 1;
    } else {
      posts.likeCount -= 1;
    }
    const obj = {
      id: 0,
      userProfileId: 0,
      postId: posts.id
    };
    const url = `${environment.ApiUrl}/api/Post/likePost`;
    this.commonservice.post(url, obj).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          // posts.isLiked = !posts.isLiked;
          // posts.likeCount += 1;
        } else {
        }
      }
    );
  }

  changeIcon(post: any) {
    post.isCommentOpen = !post.isCommentOpen;
  }

  changeiconColour(comment: any) {
    comment.isReplyOpen = !comment.isReplyOpen;
  }

  Description(comment) {
    comment.isShowLess = !comment.isShowLess;
  }

  fullDescri(ReplyComment) {
    ReplyComment.isReplyShowLess = !ReplyComment.isReplyShowLess;
  }


  openDialog(postdetails): void {
    const dialogRef = this.dialog.open(PostDetailComponent, {
      width: '540px',
      height: 'auto',
      panelClass: 'postdetail-class',
      data: { postinfo: postdetails },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });
  }


}
