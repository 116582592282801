import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/Helper/services/auth.service';
import {ResponseModel,Dropdown,} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IFilterSearch } from '../startup-search/startup-search.model';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { Subscription } from 'rxjs';
@Component({
	selector: 'app-investor-search',
	templateUrl: './investor-search.component.html',
	styleUrls: ['./investor-search.component.css'],
})
export class InvestorSearchComponent implements OnInit, OnDestroy {
	public subscriptions$: Subscription[] = [];
	Currency = [];
	Ammounts = [];
	copyAmmounts = [];
	Industries = [];
	stages = [];
	Stages = [];
	Sales = [];
	copySales = [];
	Country = [];
	Cities = [];
	Role = [];
	ExpectedJoining = [];
	pageSize: number = 10;
	pageNumber: number = 1;
	lookingFor: any = [];
	InvestorForm: FormGroup;
	BuyerForm: FormGroup;
	UserInfo: any;
	submitted: boolean = true;
	RoleId: number = 1;

	constructor(
		private authservice: AuthService,
		private fb: FormBuilder,
		private behaviourSubjectService: BehavioursubService,
		private getSetService: GetSetService,
		private commonService: CommonService
	) {
		this.GetDropdowns();

		this.InvestorForm = this.fb.group({
			Currency: [''],
			Amount: [''],
			Industry: [''],
			Stage: [''],
			Sales: [''],
		});

		this.BuyerForm = this.fb.group({
			Currency: [''],
			Amount: [''],
			Industry: [''],
			Stage: [''],
			Sales: [''],
		});
	}

	ngOnInit() {
		this.UserInfo = this.getSetService.getCurrentUserInfo();
		this.subscriptions$.push(
			this.behaviourSubjectService.getLookingFor$.subscribe((data: number) => {
				if (data !== 0) {
					this.RoleId = data;
				}
			})
		);
		this.getLookingFor();
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}

	GetDropdowns() {
		const data = {
			list: [1, 2, 3, 4, 5, 9, 11, 14],
		};
		const url = environment.ApiUrl + '/api/MasterHandler/getAllDropdown';
		this.authservice
			.GetRole(url, data)
			.subscribe((response: ResponseModel<Dropdown>) => {
				this.Currency = response.data.currency;
				this.Ammounts = response.data.budget;
				this.copyAmmounts = response.data.budget;
				this.Industries = response.data.industries;
				this.Stages = response.data.stages;
				this.Sales = response.data.sales;
				this.copySales = response.data.sales;
				this.Country = response.data.country;
				this.Role = response.data.roles;
				this.ExpectedJoining = response.data.expectedJoining;
			});
	}

	getInvestorFormData(): void {
		const data: IFilterSearch = {
			userProfileId: 0,
			commingFrom: this.UserInfo.profileId,
			lookingFor: 1,
			budgetId:
				this.InvestorForm.value.Amount !== ''
					? +this.InvestorForm.value.Amount
					: 0,
			industryId:
				this.InvestorForm.value.Industry !== ''
					? +this.InvestorForm.value.Industry
					: 0,
			saleId:
				this.InvestorForm.value.Sales !== ''
					? +this.InvestorForm.value.Sales
					: 0,
			stageId:
				this.InvestorForm.value.Stage !== ''
					? +this.InvestorForm.value.Stage
					: 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: 0,
			cityId: 0,
			currencyId:
				this.InvestorForm.value.Currency !== ''
					? +this.InvestorForm.value.Currency
					: 0,
			expectedJoiningId: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
		};
		this.behaviourSubjectService.currentFilterData(data);
		this.behaviourSubjectService.currentFilterModalStatus(true);
	}

	getBuyerFormData(): void {
		const data: IFilterSearch = {
			userProfileId: 0,
			commingFrom: this.UserInfo.profileId,
			lookingFor: 5,
			budgetId:
				this.BuyerForm.value.Amount !== '' ? +this.BuyerForm.value.Amount : 0,
			industryId:
				this.BuyerForm.value.Industry !== ''
					? +this.BuyerForm.value.Industry
					: 0,
			saleId:
				this.BuyerForm.value.Sales !== '' ? +this.BuyerForm.value.Sales : 0,
			stageId:
				this.BuyerForm.value.Stage !== '' ? +this.BuyerForm.value.Stage : 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: 0,
			cityId: 0,
			currencyId:
				this.BuyerForm.value.Currency !== ''
					? +this.BuyerForm.value.Currency
					: 0,
			expectedJoiningId: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
		};
		this.behaviourSubjectService.currentFilterData(data);
		this.behaviourSubjectService.currentFilterModalStatus(true);
	}

	ResetInvestor() {
		this.InvestorForm.reset();
	}

	ResetBuyer() {
		this.BuyerForm.reset();
	}

	getLookingFor() {
		this.RoleId = this.getSetService.getRoleData().LookingForId;
		const url = `${environment.ApiUrl}/api/Auth/GetLookingFor?LookingFor=${this.RoleId}`;
		this.commonService.get(url).subscribe((response) => {
			if (response.status === 200) {
				this.lookingFor = response.data;
				if (this.RoleId === 1) {
					this.InvestorForm.patchValue({
						Currency: this.lookingFor.currencyId,
						Industry: this.lookingFor.industryId,
						Stage: this.lookingFor.stageId,
						Sales: this.lookingFor.salesId,
						Amount: this.lookingFor.amountId,
					});
				} else if (this.RoleId === 5) {
					this.BuyerForm.patchValue({
						Currency: this.lookingFor.currencyId,
						Industry: this.lookingFor.industryId,
						Stage: this.lookingFor.stageId,
						Sales: this.lookingFor.salesId,
						Amount: this.lookingFor.amountId,

					});
				}
			}
		});
	}

	onCurrencyChange(currencyID) {
		this.Ammounts = [];
		this.Sales = [];
		this.Ammounts = this.copyAmmounts.filter(m => m.referenceId === +currencyID);
		this.Sales = this.copySales.filter(s => s.referenceId === +currencyID && s.name !== 'Any Sales');

		// const countrselect = this.Country.find((c) => +c.countryId === event);
		// this.Cities = [];
		// countrselect.cities.forEach((element) => {
		// 	this.Cities.push(element);
		// });
	}

}
