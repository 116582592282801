import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RegistrationDashboardComponent } from './registration-dashboard.component';
import { RegistartionDashboardRouteModule } from './registartion-dashboard.route.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [RegistrationDashboardComponent],
  imports: [
    CommonModule,
    SlickCarouselModule,
    RegistartionDashboardRouteModule,
    MatButtonModule,
    MatDialogModule,
    SharedModule,
    MatIconModule,
    NgxSkeletonLoaderModule
  ],
  providers: [],
  entryComponents: []
})
export class RegistartionDashboardModule { }
