import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import { ResponseModel, HttpStatusCode } from '../Responsemodel/responsemodel';
import {
	// InterceptorSkipHeader,
	// subtokenHeader,
	formDataHeader,
} from '../Interceptor/HeaderInterceptor';
import { environment } from 'src/environments/environment';
import { EncrDecrService } from './encr-decr.service';

@Injectable({
	providedIn: 'root',
})
export class CommonService {
	token: string | string[];
	isDraft = false;
	isPostedId = '0';

	roleData = {
		RoleId: 0,
		LookingForId: 0,
	};

	LookingForDocuments = [];

	// Roles: any[] = [
	//   { value: 1, viewValue: 'Startup', lookingFor: [2, 3, 5] },
	//   { value: 2, viewValue: 'Investor', lookingFor: [1, 5] },
	//   { value: 3, viewValue: 'Co-Founder', lookingFor: [1] },
	//   { value: 4, viewValue: 'Manufacturing-Business', lookingFor: [6, 7] }
	// ];

	LookingForList: any[] = [
		{ value: 1, viewValue: 'Startup' },
		{ value: 2, viewValue: 'Investor' },
		{ value: 3, viewValue: 'Co-Founder' },
		{ value: 4, viewValue: 'Mfg. Business' },
		{ value: 5, viewValue: 'Buyer' },
		{ value: 6, viewValue: 'Having Capacity' },
		{ value: 7, viewValue: 'Looking Capacity' },
	];

	constructor(private http: HttpClient, private titleService: Title, private encrDecrService: EncrDecrService) { }

	get(url: string): Observable<any> {
		// if (`${environment.ApiUrl}/api/User/Myprofie` == url) {
		// 	switch (localStorage.getItem('profileId')) {
		// 		case '1':
		// 			if (localStorage.getItem('1') == null) {
		// 				return this.http.get(url).pipe(
		// 					map((response: ResponseModel<any>) => {
		// 						if (
		// 							response.status === HttpStatusCode.OK ||
		// 							HttpStatusCode.NO_CONTENT
		// 						) {
		// 							return response;
		// 						} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
		// 							// this.route.navigate(['/not-found']);
		// 						}
		// 					}), distinctUntilChanged(),
		// 					shareReplay(1)
		// 				);
		// 			} else {
		// 				const data = {
		// 					data: this.encrDecrService.decrypt(localStorage.getItem('1')),
		// 					status: 200,
		// 					statusMessage: 'MyProfile'

		// 				}
		// 				return Observable.of(data).map(response => response);
		// 			}
		// 			break;

		// 		case '2':
		// 			if (localStorage.getItem('2') == null) {
		// 				return this.http.get(url).pipe(
		// 					map((response: ResponseModel<any>) => {
		// 						if (
		// 							response.status === HttpStatusCode.OK ||
		// 							HttpStatusCode.NO_CONTENT
		// 						) {
		// 							return response;
		// 						} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
		// 							// this.route.navigate(['/not-found']);
		// 						}
		// 					}), distinctUntilChanged(),
		// 					shareReplay(1)
		// 				);
		// 			} else {
		// 				const data = {
		// 					data: this.encrDecrService.decrypt(localStorage.getItem('2')),
		// 					status: 200,
		// 					statusMessage: 'MyProfile'

		// 				}
		// 				return Observable.of(data).map(response => response);
		// 			}

		// 			break;

		// 		case '3':
		// 			if (localStorage.getItem('3') == null) {
		// 				return this.http.get(url).pipe(
		// 					map((response: ResponseModel<any>) => {
		// 						if (
		// 							response.status === HttpStatusCode.OK ||
		// 							HttpStatusCode.NO_CONTENT
		// 						) {
		// 							return response;
		// 						} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
		// 							// this.route.navigate(['/not-found']);
		// 						}
		// 					}), distinctUntilChanged(),
		// 					shareReplay(1)
		// 				);
		// 			} else {
		// 				const data = {
		// 					data: this.encrDecrService.decrypt(localStorage.getItem('3')),
		// 					status: 200,
		// 					statusMessage: 'MyProfile'

		// 				}
		// 				return Observable.of(data).map(response => response);
		// 			}

		// 			break;

		// 		case '4':
		// 			if (localStorage.getItem('4') == null) {
		// 				return this.http.get(url).pipe(
		// 					map((response: ResponseModel<any>) => {
		// 						if (
		// 							response.status === HttpStatusCode.OK ||
		// 							HttpStatusCode.NO_CONTENT
		// 						) {
		// 							return response;
		// 						} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
		// 							// this.route.navigate(['/not-found']);
		// 						}
		// 					}), distinctUntilChanged(),
		// 					shareReplay(1)
		// 				);
		// 			} else {
		// 				const data = {
		// 					data: this.encrDecrService.decrypt(localStorage.getItem('4')),
		// 					status: 200,
		// 					statusMessage: 'MyProfile'

		// 				}
		// 				return Observable.of(data).map(response => response);
		// 			}
		// 			break;

		// 		default:
		// 			break;
		// 	}

		// }
		return this.http.get(url).pipe(
			map((response: ResponseModel<any>) => {
				if (
					response.status === HttpStatusCode.OK ||
					HttpStatusCode.NO_CONTENT
				) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.route.navigate(['/not-found']);
				}
			}), distinctUntilChanged(),
			shareReplay(1)
		);
	}

	post(url: string, model: any): Observable<any> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<any>) => {
				if (
					response.status === HttpStatusCode.OK ||
					HttpStatusCode.UNAUTHORIZED
				) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.route.navigate(['/not-found']);
				}
			})
		);
	}

	// postDraft(url: string, model: any): Observable<any> {
	//   const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
	//   return this.http.post(url, model, { headers })
	//     .pipe(map((response: Response) => response as any));
	// }

	// For Post upload
	upload(url: string, formData: any): Observable<any> {
		// const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
		const headers = new HttpHeaders().set(formDataHeader, '');
		return this.http.post(url, formData, {
			headers,
		});
	}

	// For set title to all Pages
	public setTitle(newTitle: string) {
		this.titleService.setTitle(newTitle);
	}

	getUserProfileInfo(url: string): Observable<any> {
		return this.http.get(url).pipe(
			map((response: ResponseModel<any>) => {
				if (
					response.status === HttpStatusCode.OK ||
					HttpStatusCode.NO_CONTENT
				) {
					return response;
				} else {
					return response;
				}
			})
		);
	}

// Conver file to Base64 Format;
	public async toBase64(file: File): Promise<any> {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.readAsDataURL(file);
		  reader.onload = () => resolve(reader.result);
		  reader.onerror = error => reject(error);
		});
	  }
}
