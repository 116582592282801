import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	ResponseModel,
	HttpStatusCode
} from '../Helper/Responsemodel/responsemodel';
import { map } from 'rxjs/operators';

import { IUserCompleteLookingFor } from './sub-header.model';

@Injectable({
	providedIn: 'root'
})
export class SubHeaderService {
	constructor(private http: HttpClient) { }

	getCuratesResults(
		url: string
	): Observable<ResponseModel<IUserCompleteLookingFor[]>> {
		return this.http.get(url).pipe(
			map((response: ResponseModel<IUserCompleteLookingFor[]>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}
}
