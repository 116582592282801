import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApproveRequestPopupComponent } from '../approve-request-popup/approve-request-popup.component';
import { environment } from 'src/environments/environment';
import { ResponseModel, HttpStatusCode } from 'src/app/shared/Helper/Responsemodel/responsemodel';

@Component({
  selector: 'app-reject-request-popup',
  templateUrl: './reject-request-popup.component.html',
  styleUrls: ['./reject-request-popup.component.css']
})
export class RejectRequestPopupComponent implements OnInit {
  requestData: any;

  constructor(private dataService: CommonService, public dialogRef: MatDialogRef<ApproveRequestPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.requestData = data;
    // console.log(this.requestData);
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  rejectRequest() {
    const data = {
      operation: 2,
      requestUserProfileId: this.requestData.userProfileId,
      status: 2
    };
    const url = `${environment.ApiUrl}/api/ContactRequest/ContactRequest`;
    this.dataService.post(url, data).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.onNoClick();
        }
      });
  }
}
