import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { CommentComponent } from './shared/comment/comment.component';
import { SerachComponent } from './shared/serach/serach.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
//import { NgxLinkedinModule } from 'ngx-linkedin';
import {MatTooltipModule} from '@angular/material/tooltip';

import {
	MatOptionModule,
	MatDialogModule,
	MatDialogRef,
	MatRadioModule,
	MatCheckboxModule,
	MatBadgeModule,
} from '@angular/material';
import { MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	HTTP_INTERCEPTORS,
	HttpClientModule,
	HttpClient,
} from '@angular/common/http';
import { CommonService } from './shared/Helper/services/common.service';
import { AuthService } from './shared/Helper/services/auth.service';
import { EncrDecrService } from './shared/Helper/services/encr-decr.service';
import { BehavioursubService } from './shared/Helper/services/behavioursub.service';
import { GetSetService } from './shared/Helper/services/get-set.service';
import { HeaderInterceptor } from './shared/Helper/Interceptor/HeaderInterceptor';
import { AuthGuard } from './shared/Helper/Guard/auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

import { HelpSupportComponent } from './shared/static-pages/help-support/help-support.component';

// import ngx-translate and the http loader
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { ExternalAuthService } from './shared/Helper/services/external-auth.service';
import { LogoutPopupComponent } from './shared/header/logout-popup/logout-popup.component';
import { UploadpostModule } from './home/uploadpost/uploadpost.module';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './home/dashboard/dashboard.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PushNotificationService } from './shared/Helper/services/push-notification.service';
import { PushNotificationsModule } from 'ng-push';
import { KnowledgecenterComponent } from './home/knowledgecenter/knowledgecenter/knowledgecenter.component';
import { MypostComponent } from './home/postdetail/mypost/mypost.component';
import { ImageCropperModule } from 'ngx-image-cropper';

import { RegistartionDashboardModule } from './auth/registration-dashboard/registartion-dashboard.module';

import { ChatFormComponent } from './chatting/chat-form/chat-form.component';
import { SignUpComponent } from './chatting/sign-up/sign-up.component';
import { AngularFireModule } from 'angularfire2';
// for AngularFireDatabase
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireDatabase} from 'angularfire2/database';
// for AngularFireAuth
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireAuth } from 'angularfire2/auth';



import { environment } from 'src/environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { DatePipe } from '@angular/common';
import { KnowledgecenterModule } from './home/knowledgecenter/knowledgecenter.module';
import { CacheInterceptor } from './shared/Helper/Interceptor/CacheInterceptor';
import { NgxLinkedinModule } from 'ngx-linkedin';
import { MobileModule } from './Mobile/mobile.module';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
// 324010571123-h1veh898bhe3d235889oqlut7spnsfdc.apps.googleusercontent.com
const config = new AuthServiceConfig([
	{
		id: GoogleLoginProvider.PROVIDER_ID,
		provider: new GoogleLoginProvider(
			'136268916031-kmc4gcc3atvb24othsn5v8oo6e4mvhep.apps.googleusercontent.com'
		),
	},
]);

export function provideConfig() {
	return config;
}

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		CommentComponent,
		SerachComponent,
		HelpSupportComponent,
		LogoutPopupComponent,
		MypostComponent,
		// KnowledgecenterComponent,
		ChatFormComponent,
		SignUpComponent,
		
	],
	imports: [
		BrowserModule,
		NgxLinkedinModule.forRoot({
            clientId: '86htiuk1ktqhzq'
        }),
		MatBadgeModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MatButtonModule,
		MatSelectModule,
		MatTooltipModule,
		UploadpostModule,
		KnowledgecenterModule,
		MatDialogModule,
		MatOptionModule,
		AngularFireMessagingModule,
		AngularFireDatabaseModule,             
		AngularFireAuthModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
		ToastrModule.forRoot({
			preventDuplicates: true,
			closeButton: true,
		}),
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		MatCheckboxModule,
		MatRadioModule,
		MatMenuModule,
		MatIconModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		SocialLoginModule,
		SharedModule,
		DashboardModule,
		NgxSkeletonLoaderModule,
		ImageCropperModule,
		PushNotificationsModule,
		RegistartionDashboardModule,
		MobileModule
		//  NgxLinkedinModule
	

	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HeaderInterceptor,
			multi: true,
		},
		CommonService,
		AuthService,
		EncrDecrService,
		BehavioursubService,
		GetSetService,
		ExternalAuthService,
		PushNotificationService,
		DatePipe,
		AuthGuard,
		{
			provide: AuthServiceConfig,
			useFactory: provideConfig,
		},
		{
			provide: MatDialogRef,
		},
	],
	bootstrap: [AppComponent],
	entryComponents: [LogoutPopupComponent],
	exports: [ChatFormComponent]
})
export class AppModule {}
