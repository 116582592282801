import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';

import { ActivatedRoute } from '@angular/router';

import { INewsFeedResults } from '../newsfeed.model';
import {
	ICuratedResult,
	IRequestContact,
} from '../../left-profile-curated/left-profile-curated.model';
// import { IFilterSearch } from '../../../dashboard-search/startup-search/startup-search.model';
// declare var $: any;

import { AuthService } from 'src/app/shared/Helper/services/auth.service';
import {
	ResponseModel,
	HttpStatusCode,
	Dropdown,
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { LeftProfileCuratedService } from '../../left-profile-curated/left-profile-curated.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EncrDecrService } from 'src/app/shared/Helper/services/encr-decr.service';

@Component({
	selector: 'app-view-all',
	templateUrl: './view-all.component.html',
	styleUrls: ['./view-all.component.css'],
})
export class ViewAllComponent implements OnInit, OnDestroy {
	cites: any;
	profileInfo: any;
	Industries = [];
	Country = [];
	Cities = [];
	isPremium: boolean = false;
	isVerified: boolean = false;
	activeId = 1;
	priceRange: any;
	newsFeedResultsList: INewsFeedResults[] = [];
	loadArr = [{}, {}, {}, {}, {}, {}, {}, {}];
	isCuratedLoading = false;
	loadRecArr = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
	isRecLoading = false;
	isNewOnLoading = false;
	ListOfRecommdation: any;
	ListOfNewOnEntie: any;
	List: any;
	Cites = [];
	Subtoken: any;
	lookingForId: number = 2;
	UserInfo: any;
	pageSize: number = 50;
	pageNumber: number = 1;
	roleData = {
		RoleId: 0,
		LookingForId: 0,
	};
	recomendedResults: ICuratedResult[];
	userFilter: any;
	selectedIndustry: any;
	selectedCountryId: any;
	selectedCity: number;
	selectedBuget: any;
	Buget = [];
	Experiencecount = new Array<number>(5);
	selectedCountry: any;
	filterForm: FormGroup;
	IsProfile = false;
	DataFilter: any;
	public subscriptions$: Subscription[] = [];
	IsInIt = false;
	constructor(
		private leftProfileCuratedService: LeftProfileCuratedService,
		private commonService: CommonService,
		private authservice: AuthService,
		private dataService: CommonService,
		private getsetService: GetSetService,
		private behaviourSubjectService: BehavioursubService,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private encrDecrService: EncrDecrService
	) {
		this.Subtoken = this.getsetService.getRoleData();
		this.route.params.subscribe((id) => {
			this.activeId = +id.id;
		});

		this.filterForm = this.fb.group({
			isPremium: [false],
			isVerified: [false],
			industryId: [0],
			countryId: [0],
			cityId: [0],
			budgetId: [0],
			year: [],
		});
	}
	ngOnInit() {
		// debugger;
		window.scrollTo(0, 0);
		this.subscriptions$.push(
			this.behaviourSubjectService.getIsRoleData$.subscribe((flag) => {
				this.userFilter = this.getsetService.getCurrentUserInfo();
				this.roleData = this.getsetService.getRoleData();
				if (flag && this.roleData !== null) {
					this.lookingForId = this.roleData.LookingForId;
					// const data: IFilterSearch = {
					// 	userProfileId: 0,
					// 	commingFrom: this.roleData.RoleId,
					// 	lookingFor: this.roleData.LookingForId,
					// 	budgetId: 0,
					// 	industryId: 0,
					// 	saleId: 0,
					// 	stageId: 0,
					// 	roleId: 0,
					// 	exp_Year: 0,
					// 	timeCommitment: 0,
					// 	countryId: 0,
					// 	cityId: 0,
					// 	currencyId: 0,
					// 	expectedJoiningId: 0,
					// 	pageSize: 50,
					// 	pageNumber: 1,
					// };

					// this.NewsFilterResult(data);
					// this.getRecomendedResults(this.roleData.LookingForId, this.pageSize, this.pageNumber);
					// this.getNewOnResults(this.roleData.LookingForId, this.pageSize, this.pageNumber);
				}
			}));
		this.UserInfo = this.getsetService.getCurrentUserInfo();
		this.subscriptions$.push(
			this.behaviourSubjectService.getLookingFor$.subscribe((data: number) => {
				// this.lookingForId = data;
				this.roleData = this.getsetService.getRoleData();
				this.lookingForId = this.roleData.LookingForId;
				this.getCuratedMatches(this.lookingForId);
				this.getListOfRecommdation(this.lookingForId);
			console.log('on loking change data'+data)
				this.getListOfNewOnEntie(this.lookingForId);
				// if (data !== 0 && localStorage.getItem('viewFilter') === null) {
				// 	this.getCuratedMatches(data, this.pageSize, this.pageNumber);
				// 	this.getListOfRecommdation(data, this.pageSize, this.pageNumber);
				// 	this.getListOfNewOnEntie(data);
				// } else {
				// 	if (
				// 		this.getsetService.getRoleData() !== undefined &&
				// 		localStorage.getItem('viewFilter') === null
				// 	) {
				// 		const roleData = this.getsetService.getRoleData();
				// 		switch (this.UserInfo.profileId) {
				// 			case 1:
				// 				this.lookingForId = roleData.LookingForId;
				// 				this.getCuratedMatches(
				// 					roleData.LookingForId,
				// 					this.pageSize,
				// 					this.pageNumber
				// 				);
				// 				this.getListOfRecommdation(
				// 					roleData.LookingForId,
				// 					this.pageSize,
				// 					this.pageNumber
				// 				);
				// 				this.getListOfNewOnEntie(roleData.LookingForId);
				// 				break;
				// 			case 2:
				// 				this.lookingForId = roleData.LookingForId;
				// 				this.getCuratedMatches(
				// 					roleData.LookingForId,
				// 					this.pageSize,
				// 					this.pageNumber
				// 				);
				// 				this.getListOfRecommdation(
				// 					roleData.LookingForId,
				// 					this.pageSize,
				// 					this.pageNumber
				// 				);
				// 				this.getListOfNewOnEntie(roleData.LookingForId);
				// 				break;
				// 			case 3:
				// 				this.lookingForId = roleData.LookingForId;
				// 				this.getCuratedMatches(
				// 					roleData.LookingForId,
				// 					this.pageSize,
				// 					this.pageNumber
				// 				);
				// 				this.getListOfRecommdation(
				// 					roleData.LookingForId,
				// 					this.pageSize,
				// 					this.pageNumber
				// 				);
				// 				this.getListOfNewOnEntie(roleData.LookingForId);
				// 				break;
				// 			case 4:
				// 				this.lookingForId = roleData.LookingForId;
				// 				this.getCuratedMatches(
				// 					roleData.LookingForId,
				// 					this.pageSize,
				// 					this.pageNumber
				// 				);
				// 				this.getListOfRecommdation(
				// 					roleData.LookingForId,
				// 					this.pageSize,
				// 					this.pageNumber
				// 				);
				// 				this.getListOfNewOnEntie(roleData.LookingForId);
				// 				break;
				// 		}
				// 	}
				// 	// this.CuratedResult();
				// }
			}));

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsClearFilter$.subscribe((flag) => {
				if (flag) {
					localStorage.removeItem('viewFilter');
					this.filterForm.reset();
				}
			}));

		if (localStorage.getItem('dashfilter') !== null) {
			localStorage.removeItem('dashfilter');
		}

		if (localStorage.getItem('viewFilter') !== null) {
			this.DataFilter = JSON.parse(localStorage.getItem('viewFilter'));
			this.filterForm.controls.isPremium.setValue(this.DataFilter.premium);
			this.filterForm.controls.isVerified.setValue(this.DataFilter.verified);
			this.filterForm.controls.industryId.setValue(this.DataFilter.industryId);
			this.filterForm.controls.countryId.setValue(this.DataFilter.countryId);
			this.filterForm.controls.cityId.setValue(this.DataFilter.cityId);
			this.filterForm.controls.budgetId.setValue(this.DataFilter.budgetId);
			this.filterForm.controls.year.setValue(this.DataFilter.exp_Year);

			const data = {
				userProfileId: 0,
				commingFrom: this.roleData.RoleId,
				lookingFor: this.roleData.LookingForId,
				budgetId: this.DataFilter.budgetId,
				industryId: this.DataFilter.industryId,
				saleId: 0,
				stageId: 0,
				roleId: 0,
				exp_Year: this.DataFilter.exp_Year,
				timeCommitment: 0,
				countryId: this.DataFilter.countryId,
				cityId: this.DataFilter.cityId,
				currencyId: 0,
				expectedJoiningId: 0,
				premium: this.DataFilter.premium,
				verified: this.DataFilter.verified,
				pageSize: 50,
				pageNumber: 1,
				rating: 0,
				searchStr: '',
			};

			this.filterData(data);
		}

		// if (localStorage.getItem('viewFilter') !== null) {
		// 	this.behaviourSubjectService.getIsRoleData.subscribe((flag) => {
		// 		this.userFilter = this.getsetService.getCurrentUserInfo();
		// 		this.roleData = this.getsetService.getRoleData();
		// 		if (flag && this.roleData !== null) {
		// 			this.lookingForId = this.roleData.LookingForId;
		// 			const data: IFilterSearch = {
		// 				userProfileId: 0,
		// 				commingFrom: this.roleData.RoleId,
		// 				lookingFor: this.roleData.LookingForId,
		// 				budgetId: this.DataFilter.budgetId,
		// 				industryId: this.DataFilter.industryId,
		// 				saleId: 0,
		// 				stageId: 0,
		// 				roleId: 0,
		// 				exp_Year: this.DataFilter.exp_Year,
		// 				timeCommitment: 0,
		// 				countryId: this.DataFilter.countryId,
		// 				cityId: this.DataFilter.cityId,
		// 				currencyId: 0,
		// 				expectedJoiningId: 0,
		// 				pageSize: 50,
		// 				pageNumber: 1,
		// 			};

		// 			this.filterData(data);
		// 		}
		// 	});
		// }
		// this.getListOfNewOnEntie();
		this.GetDropdowns();
		this.getUserProfileInfo();
		this.behaviourSubjectService.currentOnViewPageStatus(true);
	}

	ngOnDestroy() {
		this.behaviourSubjectService.currentOnViewPageStatus(false);
	}
	getListOfnew() {
		// if (this.getsetService.getRoleData() !== undefined) {
		// 	const roleData = this.getsetService.getRoleData();
		// 	console.log(' call list of new '+roleData.LookingForId);
		// 	this.getListOfNewOnEntie(roleData.LookingForId);
		// }
		this.getListOfNewOnEntie(this.lookingForId);
	}

	/* for new on entie */
	getListOfNewOnEntie(lookingId?: number) {
		if (lookingId === undefined) {
			lookingId = this.lookingForId;
		}
		this.isNewOnLoading = true;
		// tslint:disable-next-line: max-line-length
		const url = `${environment.ApiUrl}api/Dashboard/NewonEntie?LookingFor=${lookingId}&PageSize=${this.pageSize}&PageNumber=${this.pageNumber}`;
		this.dataService.get(url).subscribe((response: ResponseModel<any>) => {
			// console.log(response);
			if (response.status === HttpStatusCode.OK) {
				this.ListOfNewOnEntie = response.data;
				this.isNewOnLoading = false;
			} else {
				this.isNewOnLoading = false;
			}
		});
	}

	getListOfrec() {
		// if (this.getsetService.getRoleData() !== undefined) {
		// 	const roleData = this.getsetService.getRoleData();
		// 	this.getListOfRecommdation(
		// 		roleData.LookingForId,
		// 		this.pageSize,
		// 		this.pageNumber
		// 	);
		// }
		this.getListOfRecommdation(this.lookingForId);
	}

	/*for recommdation */
	getListOfRecommdation(lookingFor, pageSize = 10, pageNumber = 1) {
		this.isRecLoading = true;
		if (localStorage.getItem('viewFilter') !== null) {
			const data = {
				userProfileId: 0,
				commingFrom: this.roleData.RoleId,
				lookingFor: this.roleData.LookingForId,
				budgetId: this.DataFilter.budgetId,
				industryId: this.DataFilter.industryId,
				saleId: 0,
				stageId: 0,
				roleId: 0,
				exp_Year: this.DataFilter.exp_Year,
				timeCommitment: 0,
				countryId: this.DataFilter.countryId,
				cityId: this.DataFilter.cityId,
				currencyId: 0,
				expectedJoiningId: 0,
				premium: this.DataFilter.premium,
				verified: this.DataFilter.verified,
				pageSize: 50,
				pageNumber: 1,
				rating: 0,
				searchStr: '',
			};

			this.filterData(data);
		} else {
			// tslint:disable-next-line: max-line-length
			const url = `${environment.ApiUrl}/api/Dashboard/Recomended?LookingFor=${lookingFor}&PageSize=${this.pageSize}&PageNumber=${this.pageNumber}`;
			this.dataService.get(url).subscribe((response: ResponseModel<any>) => {
				if (response.status === 200) {
					this.isRecLoading = false;
					this.ListOfRecommdation = response.data;
				} else {
					this.isRecLoading = true;
				}
			});
		}
	}

	/*for curated maches */

	getListOfcurated() {
		// if (this.getsetService.getRoleData() !== undefined) {
		// 	const roleData = this.getsetService.getRoleData();
		// 	this.getCuratedMatches(
		// 		roleData.LookingForId,
		// 		this.pageSize,
		// 		this.pageNumber
		// 	);
		// }
		this.getCuratedMatches(this.lookingForId);
	}

	getCuratedMatches(lookingFor, pageSize = 10, pageNumber = 1) {
		this.isCuratedLoading = true;
		if (localStorage.getItem('viewFilter') !== null) {
			const data = {
				userProfileId: 0,
				commingFrom: this.roleData.RoleId,
				lookingFor: this.roleData.LookingForId,
				budgetId: this.DataFilter.budgetId,
				industryId: this.DataFilter.industryId,
				saleId: 0,
				stageId: 0,
				roleId: 0,
				exp_Year: this.DataFilter.exp_Year,
				timeCommitment: 0,
				countryId: this.DataFilter.countryId,
				cityId: this.DataFilter.cityId,
				currencyId: 0,
				expectedJoiningId: 0,
				premium: this.DataFilter.premium,
				verified: this.DataFilter.verified,
				pageSize: 50,
				pageNumber: 1,
				rating: 0,
				searchStr: '',
			};

			this.filterData(data);
		} else {
			// tslint:disable-next-line: max-line-length
			const url = `${environment.ApiUrl}/api/Dashboard/CuratedSearch?LookingFor=${lookingFor}&PageSize=${this.pageSize}&PageNumber=${this.pageNumber}`;
			this.dataService.get(url).subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.List = response.data;
					this.isCuratedLoading = false;
				} else {
					this.List = response.data;
					this.isCuratedLoading = false;
				}
			});
		}
	}

	// @HostListener('window:scroll', ['$event'])
	// scrollHandler(event) {
	//   if((window.innerHeight + window.scrollY) >= (document.body.offsetHeight-100)){
	//     console.debug("Scroll Event");
	//   }
	// }

	GetDropdowns() {
		const data = {
			list: [1, 2, 3, 4, 5, 9, 11],
		};

		const url = environment.ApiUrl + '/api/MasterHandler/getAllDropdown';
		this.authservice
			.GetRole(url, data)
			.subscribe((response: ResponseModel<Dropdown>) => {
				this.Buget = response.data.budget;
				this.Industries = response.data.industries;
				this.Country = response.data.country;
				this.selectedCountry = response.data.country[0];
				this.Cites = response.data.country[0].cities;
			});
	}

	// For getting profile info
	getUserProfileInfo() {
		this.IsProfile = true;
		// const url = `${environment.ApiUrl}/api/User/Myprofie`;
		// this.commonService.get(url).subscribe((response: ResponseModel<any>) => {
		// 	if (response.status === HttpStatusCode.OK) {
		// 		this.IsProfile = false;
		// 		this.profileInfo = response.data;
		// 	}
		// });

		var delay;
		delay = setInterval(() => {
			if (this.profileInfo == null) {
				// switch (localStorage.getItem('profileId')) {
				switch (localStorage.getItem('mainProfileId')) {
					case '1':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('1'));
						break;

					case '2':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('2'));
						break;

					case '3':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('3'));
						break;

					case '4':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('4'));
						break;

					default:
						break;
				}
			} else {
				clearInterval(delay);
				this.IsProfile = false;
			}
		}, 1000);
		console.log('PRrofile detail ::' + this.profileInfo)

	}

	// For sending contact request
	sendRequestContact(
		userInfo: ICuratedResult,
		operation: number,
		status: number
	) {
		const url = `${environment.ApiUrl}/api/ContactRequest/ContactRequest`;
		const data: IRequestContact = {
			operation: operation,
			requestUserProfileId: userInfo.userProfileId,
			status: status,
		};
		this.leftProfileCuratedService
			.requestContact(url, data)
			.subscribe((response: ResponseModel<IRequestContact>) => {
				if (response.status === HttpStatusCode.OK) {
					// this.toasterService.success(response.statusMessage);
					userInfo.isRequested = response.data.status;
				}
			});
	}

	bindCity(event) {
		this.selectedCountryId = event.target.value;
		const allcountries = this.Country;
		allcountries.forEach((desh) => {
			if (String(desh.countryId) === String(this.selectedCountryId)) {
				this.Cites = desh.cities;
			}
		});
	}

	getIndustry(event) {
		this.selectedIndustry = +event.target.value;
	}

	getSelectedCity(event) {
		this.selectedCity = +event.target.value;
	}
	getbuget(event) {
		this.selectedBuget = +event.target.value;
	}

	clearFilters() {
		this.filterForm.reset();
		this.priceRange = '0';
		localStorage.removeItem('viewFilter');
		switch (this.activeId) {
			case 1:
				this.getListOfcurated()
				break;
			case 2:
				this.getListOfrec();
				break;
			case 3:
				// this.getListOfNewOnEntie();
				this.getListOfnew();
				break;
		}
	}

	filterData(filterInput = null) {
		this.isCuratedLoading = true;
		this.isRecLoading = true;
		this.isNewOnLoading = true;
		let data;
		if (filterInput !== null) {
			data = filterInput;
		} else {
			data = {
				userProfileId: 0,
				commingFrom: this.UserInfo.profileId,
				lookingFor: this.lookingForId,
				budgetId: this.filterForm.value.budgetId
					? Number(this.filterForm.value.budgetId)
					: 0,
				industryId: this.filterForm.value.industryId
					? Number(this.filterForm.value.industryId)
					: 0,
				saleId: 0,
				stageId: 0,
				roleId: 0,
				exp_Year: this.filterForm.value.year
					? Number(this.filterForm.value.year)
					: 0,
				timeCommitment: 0,
				countryId: this.filterForm.value.countryId
					? Number(this.filterForm.value.countryId)
					: 0,
				cityId: this.filterForm.value.cityId
					? Number(this.filterForm.value.cityId)
					: 0,
				currencyId: 0,
				expectedJoiningId: 0,
				pageSize: 10,
				pageNumber: 1,
				premium: this.filterForm.value.isPremium ? 1 : 0,
				verified: this.filterForm.value.isVerified ? 1 : 0,
				rating: 0,
				searchStr: '',
			};
		}

		// console.log(data);
		const url = `${environment.ApiUrl}/api/Dashboard/NewsFeedFilter`;
		this.commonService
			.post(url, data)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					localStorage.setItem('viewFilter', JSON.stringify(data));
					this.isCuratedLoading = false;
					this.isRecLoading = false;
					this.isNewOnLoading = false;
					this.List = response.data;
					this.ListOfRecommdation = response.data;
					this.ListOfNewOnEntie = response.data;
				} else {
				}
				if (response.status === 204) {
					this.filterForm.reset();
					this.isCuratedLoading = false;
					this.isRecLoading = false;
					this.isNewOnLoading = false;
					// this.clearFilters();
					document.getElementById('errorMessage').click();

					setTimeout(() => {
						// $('errorMessage').modal('hide');
						document.getElementById('Popupclose').click();
					}, 3000);
				} else {
					this.isCuratedLoading = false;
					// this.clearFilters();
				}
			});
	}
}
