import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';
import { HttpStatusCode, ResponseModel } from '../../Helper/Responsemodel/responsemodel';
import { CommonService } from '../../Helper/services/common.service';

@Component({
  selector: 'app-approvenotification',
  templateUrl: './approvenotification.component.html',
  styleUrls: ['./approvenotification.component.css']
})
export class ApprovenotificationComponent implements OnInit {
  requestData: any;

  constructor(private dataService: CommonService, public dialogRef: MatDialogRef<ApprovenotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.requestData = data;
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  approveRequest() {
    const data = {
      operation: 2,
      requestUserProfileId: this.requestData.userProfileId,
      status: 1
    };
    const url = `${environment.ApiUrl}/api/ContactRequest/ContactRequest`;
    this.dataService.post(url, data).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.onNoClick();
        }
      });
  }

}
