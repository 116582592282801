import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';
import 'rxjs/Rx';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';
// import { FirebaseApp } from 'angularfire2';
// import { Global } from '../../shared/global';
import { take } from 'rxjs/operators';
import { isEqual, differenceWith } from 'lodash';
import { Observable } from 'rxjs';
import { ChatFormComponent } from '../chat-form/chat-form.component';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { AuthService } from 'src/app/shared/Helper/services/auth.service';
import { environment } from 'src/environments/environment';
import { AngularFireObject, AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],

})
export class SignUpComponent implements OnInit {
  items: Observable<any>;
  userlist = [];
  user: any;
  selectedName: any;
  filtersAnimals: any;
  family: string;
  weight: number;
  filters = {};
  animals: any;
  status: any;
  connectedUsersList: any;
  p: AngularFireObject<any>;
  arrData: any[] = [];
  constructor(
    private authService: CommonService,
    private userService: UserService,
    public af: AngularFireAuth,
    private firebase: AngularFireDatabase,
    private auth: AuthService,
    private getSetService: GetSetService
  ) { }
  repeat = true;
  ngOnInit() {
  }
  signUpp(userdetails, email, status: any) {
    

		const profileInfo = this.getSetService.getCurrentUserInfo();
    
    // const url = `${environment.ApiUrl}/api/v1/ConnectRequest/connetions/` + 5 + '/20/1';
    // this.authService.get(url).subscribe(
      this.userService.getAllUsers(profileInfo.userId.toString()).subscribe(
      data => {
        
        this.connectedUsersList = data;
        if (localStorage.getItem('UserId') !== JSON.stringify(0)) {
          this.status = status;
          const email = localStorage.getItem('UserId');
          this.firebase.list(`/ConnectedUsers/` + email).valueChanges().take(1).subscribe(userList => {
            
            this.connectedUsersList = this.connectedUsersList.sort((a: any, b: any) => a.Id - b.Id);
            const arr: any[] = [];
            const result = userList.filter(o1 => this.connectedUsersList.results.some(o2 => o1['userId'] === o2.Id));
            result.forEach(element => {
              
              const k = this.connectedUsersList.results.filter(img => img.userId === element['userId']);
              const users = {
                username: k[0].usernamee,
                imagePath: k[0].ImagePath.FilePath,
                userId: element['userId'],
                status: element['status'],
                time: element['time'],
                count: element['count'],
                lastMessage: element['lastMessage'],
                typing: false
              };
              arr[element['userId']] = users;
            });

            const l = (differenceWith(this.connectedUsersList.results, userList, (o1: any, o2: any) => {
              return o1.Id === o2.userId;
            }));

            l.forEach(element => {
              const users = {
                username: element.FirstName +' '+ element.LastName,
                imagePath: element.ImagePath.FilePath,
                userId: element.Id,
                status: 'offline',
                time: '',
                count: '0',
                lastMessage: '',
                typing: false
              };
              arr[element.Id] = users;
            });
            this.arrData = arr;
            // this.userService.insertConnectedUsers(arr, email); // insert all the connected users
            arr.forEach(element => {
              this.userService.updateStatus(element.userId, 'active');
            });
          });
        }
      });
  }

  signOut(id: any) {
    this.arrData.forEach(element => {
      this.userService.updateStatus(element.userId, 'offline');
    });
  }

  removeConnected(loginuserId: any) {
    const user = this.firebase.list('/connected', ref => ref
      .orderByChild('sender')
      .equalTo(loginuserId)
    )
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
      })
      .first()
      .subscribe(snapshots => {
        snapshots.forEach(snapshot => {
          this.userService.removeUser(snapshot.key);
        });
      });

  }
}
