import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-filters-modal',
	templateUrl: './filters-modal.component.html',
	styleUrls: ['./filters-modal.component.css']
})
export class FiltersModalComponent implements OnInit, AfterViewInit, OnDestroy {
	userFilter: any;
	public subscriptions$: Subscription[] = [];
	constructor(
		private getsetService: GetSetService,
		private behaviourSubjectService: BehavioursubService,
		public dialogRef: MatDialogRef<FiltersModalComponent>,
		private cdRef: ChangeDetectorRef
	) { }

	ngOnInit() {

		this.userFilter = this.getsetService.getRoleData();
		this.behaviourSubjectService.currentFilterModalStatus(false);

		this.dialogRef.afterClosed().subscribe(result => {
			if (!this.cdRef['destroyed']) {
				this.cdRef.detectChanges();
			}
		});

		this.subscriptions$.push(
			this.behaviourSubjectService.FilterModal$.subscribe(flag => {
				if (flag) {
					this.dialogRef.close();
				}
			}));
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}

	ngAfterViewInit() {
		this.cdRef.detectChanges();
	}
}
