import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SerachComponent } from './shared/serach/serach.component';
import { MypostComponent } from './home/postdetail/mypost/mypost.component';
import { KnowledgecenterComponent } from './home/knowledgecenter/knowledgecenter/knowledgecenter.component';

const routes: Routes = [
	// {
	// 	path: '',
	// 	redirectTo: 'auth',
	// 	pathMatch: 'full'
	// },
	{
		path: 'home',
		// loadChildren: () => {
		// 	if(window.innerWidth > 768 ) {
		// 	  return import('./home/home.module').then(mod => mod.HomeModule)
		// 	} else {
		// 	  return import('./Mobile/mobile.module').then(m => m.MobileModule)
		// 	}
		//   }
		loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
	},
	// {
	// 	path: 'chatting',
	// 	loadChildren: () =>
	// 		import('./chatwithsignalr/chatwithsignalr.module').then(
	// 			mod => mod.ChatwithsignalrModule
	// 		)
	// },
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule)
	},
	{
		path: '',
		loadChildren:() => {
			if(window.innerWidth > 768 ) {
			  return import('./auth/registration-dashboard/registartion-dashboard.module')
			  	.then(mod => mod.RegistartionDashboardModule)
			} else {
			  return import('./Mobile/mobile.module').then(m => m.MobileModule)
			}
		  }
	// 	loadChildren: () => import('./auth/registration-dashboard/registartion-dashboard.module')
	// 	.then(mod => mod.RegistartionDashboardModule)
	},
	{
		path: 'blog/:categoery',
		loadChildren: () => import('./blogs/blog.module')
			.then(mod => mod.BlogModule)
	},
	{
		path: 'blog/:id/:slug',
		loadChildren: () => import('./blog-detail/blog-detail.module')
			.then(mod => mod.BlogDetailModule)
	},
	{
		path: 'subscribe/:id',
		loadChildren: () =>
			import('./subscription/subscription.module').then(
				mod => mod.SubscriptionModule
			)
	},
	{
		path: 'Terms&conditions',
		loadChildren: () =>
			import('./footer/footer.module').then(mod => mod.FooterModule)
	},
	{
		path: 'Help&Support',
		loadChildren: () =>
			import('./footer/footer.module').then(mod => mod.FooterModule)
	},
	{
		path: 'payment',
		loadChildren: () =>
			import('./shared/payment/payment.module').then(mod => mod.PaymentModule)
	},
	{
		path: 'search/:query',
		component: SerachComponent
	},
	{
		path: 'mypost',
		component: MypostComponent
	},
	{
		path: 'Knowledge',
		component: KnowledgecenterComponent
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// useHash: true,
			onSameUrlNavigation: 'reload'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
