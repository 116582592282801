import { Injectable, OnInit, DebugElement } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { DatePipe } from '@angular/common';
import { UserService } from './user.service';
import * as _ from 'lodash';
import 'rxjs/add/operator/take';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';


@Injectable({
  providedIn: 'root'
})


export class ChatService implements OnInit {


  // insert Message
  public userStatus: any;
  filtersAnimals: any;
  filters = {};
  animals: any;
  public messageList: AngularFireList<any>;


  constructor(
    // tslint:disable-next-line:no-shadowed-variable
    private firebase: AngularFireDatabase,
    private datePipe: DatePipe,
    private userService: UserService,
    private getSetService: GetSetService) {
  }


  // tslint:disable-next-line:contextual-lifecycle
  ngOnInit() {
    // this is to check user current status
    this.firebase.list('/users').valueChanges().subscribe(animals => {
      this.animals = animals;
    });
  }

  getAllMessages(sr) {
    
    this.messageList = this.firebase.list('Messages/' + sr);
    return (this.messageList.snapshotChanges());
  }

  sendconstatus(sender, receiver, status) {
    

    this.firebase.list(`/ConnectedUsers/` + receiver + `/` + sender).valueChanges().take(1).subscribe(use => {
      
    this.userService.updateConnectReq(sender, receiver, status);
    });
  }

  sendMessage(msg,status,flg) {
    
    const profileInfo = this.getSetService.getCurrentUserInfo();

    const sender =  profileInfo.userProfileId.toString();
    const receiver = +localStorage.getItem('res');

    //this.firebase.list(`/ConnectedUsers/` + sender + `/` + receiver).valueChanges().take(1).subscribe(userList => {

    this.firebase.object(`/ConnectedUsers/` + sender + `/` + receiver).valueChanges().take(1).subscribe(userList => {

      
      
      let item:any=userList;
      let i: any;

      let strarray =[ 'chat_id_' + receiver + '_' + sender, 'chat_id_' + sender + '_' + receiver]
      
      let Basekey:string;

      for(i=0;i<2;i++)
      {
        
      this.getAllMessages(strarray[i]);
      var newRef = this.messageList.push({});
      var newKey= newRef.key;
      if(i===0){Basekey=newKey;
      
        if(status == 'New' && flg===1)
        {
          msg=profileInfo.fullName + " " + "reject your message request";
        }

        if(status == 'Connected' && flg===1)
        {
          msg=profileInfo.fullName + " " + "accepted your message request, now you can start the conversation";
        }

      }else
      {
        if(status == 'New' && flg===1)
        {
          msg= "You  reject "+ item.username+ "  message request";;
        }

        if(status == 'Connected' && flg===1)
        {
          msg= "You accepted message request for "+ item.username+ ", now you can start the conversation";;
        }        
      }

      const myDate = new Date();
      const time = this.datePipe.transform(myDate, 'yyyy-MM-dd HH:mm:ss');
      const s =  profileInfo.userProfileId.toString();
      const r = localStorage.getItem('res');

      var DBref = this.firebase.database.ref('Messages/' + strarray[i]);

      const message = ({
        message: msg,
        receiverId: r,
        senderId: s,
        time:time,
        isseen: false,
        type: 'text',
        key: Basekey
      });
      
      DBref.child(Basekey).update(message);

      this.userService.updateLstMessage(r, s, msg, time);

     // userList.forEach((element) => {
        
        // if(item.status === 'UnActive' ) 
        // {
        //   this.firebase.list(`/ConnectedUsers/` + receiver + `/` + sender).valueChanges().take(1).subscribe(use => {
            
        //     let count = 0;
        //     if ( use[4] === 'UnActive') {
        //       count = +use[7] + 1;
        //     } else {
        //       count = +use[7] + 1;
        //     }
        //     this.userService.updateCount(r, s, count);
        //   });
        // }
     //  })
    }

    });

  } 
  


  updateMsgAsSeen(key: any, locate: any, isseen: any): any {
    
    const m = this.firebase.list('Messages/' + locate);
    m.update(key, {
      isseen
    });
  }


} // end of class




