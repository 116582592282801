import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	ResponseModel,
	HttpStatusCode
} from '../Helper/Responsemodel/responsemodel';
import { map } from 'rxjs/operators';
import {
	IUserCompleteLookingFor,
	ItokenForSwitchProfile,
	INotification
} from './header.model';
import { LoginResponseModel } from 'src/app/auth/login/login.model';

@Injectable({
	providedIn: 'root'
})
export class HeaderService {
	constructor(
		private http: HttpClient
	) { }

	getProfileComplete(
		url: string
	): Observable<ResponseModel<IUserCompleteLookingFor[]>> {
		return this.http.get(url).pipe(
			map((response: ResponseModel<IUserCompleteLookingFor[]>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}

	getNotificationList(url: string): Observable<ResponseModel<INotification[]>> {
		return this.http.get(url).pipe(
			map((response: ResponseModel<INotification[]>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}

	switchAddProfile(
		url: string,
		model: ItokenForSwitchProfile
	): Observable<ResponseModel<LoginResponseModel>> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<LoginResponseModel>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}
}
