import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../Helper/services/auth.service';
import { Router } from '@angular/router';
import { BehavioursubService } from '../../Helper/services/behavioursub.service';
import { GetSetService } from '../../Helper/services/get-set.service';
import { UserService } from 'src/app/chatting/shared/user.service';

@Component({
	selector: 'app-logout-popup',
	templateUrl: './logout-popup.component.html',
	styleUrls: ['./logout-popup.component.css']
})
export class LogoutPopupComponent implements OnInit {
	SenderId: any;
	IsSwitchProfile = false;
	constructor(
		public dialogRef: MatDialogRef<LogoutPopupComponent>,
		private authservice: AuthService,
		private route: Router,
		private behavioursubservice: BehavioursubService,
		private getSetService: GetSetService,
		public userService: UserService

	) { }

	ngOnInit() {
		const profileInfo = this.getSetService.getCurrentUserInfo();

		this.SenderId = profileInfo.userProfileId;
			this.behavioursubservice.switchstatus$.subscribe((data) => {
				this.IsSwitchProfile = data
			})
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	logout() {
		this.behavioursubservice.updateSwitch(false);
		this.userService.updateuserStatus(this.SenderId.toString(), 'offline');
		this.authservice.logout();
		this.behavioursubservice.Updateloginuser(false);
		this.behavioursubservice.isOnDashboard(false);
		this.onNoClick();
		this.route.navigate(['auth/login']);

		// chatting
		// this.signalRService.LeftJoinUser(this.SenderId);

	}
	login(){

	}

}
