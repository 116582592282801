import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { ResponseModel, HttpStatusCode } from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { environment } from 'src/environments/environment';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';

@Component({
	selector: 'app-knowledgecenterdetail',
	templateUrl: './knowledgecenterdetail.component.html',
	styleUrls: ['./knowledgecenterdetail.component.css']
})
export class KnowledgecenterdetailComponent implements OnInit, AfterViewInit {
	ArticleDetails: any;
	constructor(
		private commonservice: CommonService,
		private behaviourSubject: BehavioursubService
	) {}

	ngAfterViewInit() {}

	ngOnInit() {
		this.ArticleDetails = JSON.parse(localStorage.getItem('articleinfo'));
		// console.log(this.ArticleDetails);

		this.behaviourSubject.isbgcolor(true);

		const element = document.getElementById('articledetail');
		// console.log(element);
		if (element !== null) {
			element.innerHTML = this.ArticleDetails.article;
		}

		if (localStorage.getItem('dashfilter') !== null) {
			localStorage.removeItem('dashfilter');
		}
	}

	ArticleLikeDislike(post: any) {
		post.isLiked = !post.isLiked;
		if (post.isLiked === true) {
			post.likeCount += 1;
		} else {
			post.likeCount -= 1;
		}
		const obj = {
			articleId: post.id,
		};
		const url = `${environment.ApiUrl}/api/Article/LikeDislikeArticle`;
		this.commonservice.post(url, obj).subscribe(
			(response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
				}
			});
	}
}
