import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { KnowledgecenterdetailComponent } from '../knowledgecenterdetail/knowledgecenterdetail.component';

import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import {
	ResponseModel,
	HttpStatusCode,
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { Router } from '@angular/router';
import { EncrDecrService } from 'src/app/shared/Helper/services/encr-decr.service';

@Component({
	selector: 'app-knowledgecenter',
	templateUrl: './knowledgecenter.component.html',
	styleUrls: ['./knowledgecenter.component.css'],
	providers: [KnowledgecenterdetailComponent],
})
export class KnowledgecenterComponent implements OnInit, OnDestroy {
	listsblog: any[];
	showdetails = false;
	profileInfo = null;
	isLoading = false;
	IsProfile = false;

	constructor(
		private commonservice: CommonService,
		private behaviourSubject: BehavioursubService,
		private router: Router,
		private encrDecrService: EncrDecrService
	) { }

	ngOnInit() {
		if (document.getElementById('searchinput') !== null) {
			(<HTMLInputElement>document.getElementById('searchinput')).value = '';
		}
		this.showdetails = false;
		this.GetPostList();
		this.behaviourSubject.isbgcolor(true);
		this.getUserProfileInfo();
		// this.reload();

		if (localStorage.getItem('dashfilter') !== null) {
			localStorage.removeItem('dashfilter');
		}
		this.behaviourSubject.currentArticlePost(true);
	}

	ngOnDestroy() {
		this.behaviourSubject.isbgcolor(false);
		this.behaviourSubject.currentArticlePost(false);
	}

	GetPostList() {
		this.isLoading = true;
		const url = environment.ApiUrl + '/api/Article/listArticle';
		this.commonservice.post(url, '').subscribe((response) => {
			if (response.status === 200) {
				this.listsblog = response.data;
				this.isLoading = false;
			} else {
				this.listsblog = [];
				this.isLoading = false;
			}
		});
	}

	getUserProfileInfo() {
		this.IsProfile = true;
		// const url = `${environment.ApiUrl}/api/User/Myprofie`;
		// this.commonservice.get(url).subscribe((response: ResponseModel<any>) => {
		// 	if (response.status === HttpStatusCode.OK) {
		// 		this.IsProfile = false;
		// 		this.profileInfo = response.data;
		// 	}
		// });
		var delay;
		delay = setInterval(() => {
			if (this.profileInfo == null) {
				switch (localStorage.getItem('profileId')) {
					case '1':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('1'));
						break;

					case '2':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('2'));
						break;

					case '3':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('3'));
						break;

					case '4':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('4'));
						break;

					default:
						break;
				}
			} else {
				clearInterval(delay);
				this.IsProfile = false;
			}
		}, 1000);
	}

	saveArticleDetail(post) {
		localStorage.setItem('articleinfo', JSON.stringify(post));
		this.router.navigateByUrl('/home/knowledgecenter/details');
	}

	ArticleLikeDislike(post: any) {
		post.isLiked = !post.isLiked;
		if (post.isLiked === true) {
			post.likeCount += 1;
		} else {
			post.likeCount -= 1;
		}
		const obj = {
			articleId: post.id,
		};
		const url = `${environment.ApiUrl}/api/Article/LikeDislikeArticle`;
		this.commonservice
			.post(url, obj)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
				}
			});
	}
	// getdetails(details: any) {
	//     this.showdetails = true;
	//     document.getElementById('maindivhtml').innerHTML = details;
	//     document.getElementById('titlediv').setAttribute('contenteditable', 'false');
	//     document.getElementById('txtarea').setAttribute('contenteditable', 'false');
	// }
}
