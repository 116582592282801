import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/shared/Helper/services/auth.service';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { environment } from 'src/environments/environment';
import {
	ResponseModel,
	Dropdown,
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { IFilterSearch } from '../startup-search/startup-search.model';
import { CommonService } from 'src/app/shared/Helper/services/common.service';

@Component({
	selector: 'app-cofounder-search',
	templateUrl: './cofounder-search.component.html',
	styleUrls: ['./cofounder-search.component.css'],
})
export class CofounderSearchComponent implements OnInit {
	Currency = [];
	Ammounts = [];
	Industries = [];
	stages = [];
	Stages = [];
	Sales = [];
	Country = [];
	Cities = [];
	Role = [];
	ExpectedJoining = [];
	pageSize: number = 10;
	pageNumber: number = 1;

	InvestorForm: FormGroup;
	BuyerForm: FormGroup;
	UserInfo: any;
	submitted: boolean = true;
	CoFounderForm: FormGroup;
	// Experiencecount = new Array<number>(50);
	selectedCountry: any;
	lookingForData: any;
	Experiencecount = new Array<number>(5);
	Monthcount = new Array<number>(13);

	constructor(
		private authservice: AuthService,
		private fb: FormBuilder,
		private behaviourSubjectService: BehavioursubService,
		private getSetService: GetSetService,
		private commonService: CommonService
	) {}

	ngOnInit() {
		this.CoFounderForm = this.fb.group({
			Role: [''],
			Industry: [''],
			Years: ['0'],
			Months: ['0'],
			Country: [''],
			City: [''],
			ExpectedJoining: [''],
			timeCommitment: ['0'],
		});
		this.UserInfo = this.getSetService.getCurrentUserInfo();
		this.GetDropdowns();
		this.getLookingFor();
	}

	GetDropdowns() {
		const data = {
			list: [1, 2, 3, 4, 5, 9, 11, 14],
		};
		const url = environment.ApiUrl + '/api/MasterHandler/getAllDropdown';
		this.authservice
			.GetRole(url, data)
			.subscribe((response: ResponseModel<Dropdown>) => {
				this.Currency = response.data.currency;
				this.Ammounts = response.data.budget;
				this.Industries = response.data.industries;
				this.Stages = response.data.stages;
				this.Sales = response.data.sales;
				this.Country = response.data.country;
				this.selectedCountry = response.data.country[0];
				this.Cities = this.selectedCountry.cities;
				this.Role = response.data.roles;
				this.ExpectedJoining = response.data.expectedJoining;
			});
	}

	getInvestorFormData(): void {
		const data: IFilterSearch = {
			userProfileId: 0,
			commingFrom: this.UserInfo.profileId,
			lookingFor: 1,
			budgetId:
				this.InvestorForm.value.Amount !== ''
					? +this.InvestorForm.value.Amount
					: 0,
			industryId:
				this.InvestorForm.value.Industry !== ''
					? +this.InvestorForm.value.Industry
					: 0,
			saleId:
				this.InvestorForm.value.Sales !== ''
					? +this.InvestorForm.value.Sales
					: 0,
			stageId:
				this.InvestorForm.value.Stage !== ''
					? +this.InvestorForm.value.Stage
					: 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: 0,
			cityId: 0,
			currencyId:
				this.InvestorForm.value.Currency !== ''
					? +this.InvestorForm.value.Currency
					: 0,
			expectedJoiningId: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
		};
		this.behaviourSubjectService.currentFilterData(data);
		this.behaviourSubjectService.currentFilterModalStatus(true);
	}

	onChangecountry(event) {
		this.Cities = [];
		const countrselect = this.Country.find((c) => +c.countryId === event);
		setTimeout(() => {
			countrselect.cities.forEach((element) => {
				this.Cities.push(element);
			});
		}, 2000);
	}

	getBuyerFormData(): void {
		const data: IFilterSearch = {
			userProfileId: 0,
			commingFrom: this.UserInfo.profileId,
			lookingFor: 5,
			budgetId:
				this.BuyerForm.value.Amount !== '' ? +this.BuyerForm.value.Amount : 0,
			industryId:
				this.BuyerForm.value.Industry !== ''
					? +this.BuyerForm.value.Industry
					: 0,
			saleId:
				this.BuyerForm.value.Sales !== '' ? +this.BuyerForm.value.Sales : 0,
			stageId:
				this.BuyerForm.value.Stage !== '' ? +this.BuyerForm.value.Stage : 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: 0,
			cityId: 0,
			currencyId:
				this.BuyerForm.value.Currency !== ''
					? +this.BuyerForm.value.Currency
					: 0,
			expectedJoiningId: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
		};
		this.behaviourSubjectService.currentFilterData(data);
	}

	getCoFunderData(): void {
		if (this.CoFounderForm.valid) {
			const data: IFilterSearch = {
				userProfileId: 0,
				commingFrom: this.UserInfo.profileId,
				lookingFor: 3,
				budgetId: 0,
				industryId:
					this.CoFounderForm.value.Industry !== ''
						? +this.CoFounderForm.value.Industry
						: 0,
				saleId: 0,
				stageId: 0,
				roleId:
					this.CoFounderForm.value.Role !== ''
						? +this.CoFounderForm.value.Role
						: 0,
				exp_Year:
					this.CoFounderForm.value.Years !== ''
						? +this.CoFounderForm.value.Years
						: 0,
				timeCommitment: 0,
				countryId:
					this.CoFounderForm.value.Country !== ''
						? +this.CoFounderForm.value.Country
						: 0,
				cityId:
					this.CoFounderForm.value.City !== ''
						? +this.CoFounderForm.value.City
						: 0,
				currencyId: 0,
				expectedJoiningId:
					this.CoFounderForm.value.ExpectedJoining !== ''
						? +this.CoFounderForm.value.ExpectedJoining
						: 0,
				pageSize: this.pageSize,
				pageNumber: this.pageNumber,
			};
			this.behaviourSubjectService.currentFilterData(data);
			this.behaviourSubjectService.currentFilterModalStatus(true);
		}
	}

	ResetForm() {
		this.CoFounderForm.reset();
	}

	getLookingFor() {
		// debugger;
		const RoleId = 1;
		const url = `${environment.ApiUrl}/api/Auth/GetLookingFor?LookingFor=${RoleId}`;
		this.commonService.get(url).subscribe((response) => {
			if (response.status === 200) {
				this.lookingForData = response.data;
				this.CoFounderForm.patchValue({
					Role: this.lookingForData.roleId,
					Industry: this.lookingForData.industryId,
					Experience: this.lookingForData.experience.split('.')[0],
					Years: String(this.lookingForData.experience).split('.')[0],
					// Months: String(this.lookingForData.experience).split('.')[1],
					Country: this.lookingForData.countryId,
					City: this.lookingForData.cityId,
					ExpectedJoining: this.lookingForData.expectedJoiningId,
					timeCommitment: String(this.lookingForData.timeCommitmentId),
				});
			}
		});
	}
}
