import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-help-support',
  templateUrl: './help-support.component.html',
  styleUrls: ['./help-support.component.css']
})
export class HelpSupportComponent implements OnInit {
  HelpForm: FormGroup;

  constructor() { }

  ngOnInit() {
    this.HelpForm = new FormGroup({
      Fullname: new FormControl(''),
      Email: new FormControl(''),
      Mobile: new FormControl(''),
      Message: new FormControl(''),
    });
  }

}
