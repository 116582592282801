import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';

import { environment } from 'src/environments/environment';
import { LeftProfileCuratedService } from './left-profile-curated.service';
import {
	ResponseModel,
	HttpStatusCode
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { ICuratedResult, IRequestContact } from './left-profile-curated.model';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';


import { delay } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
declare var $: any;
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
	selector: 'app-left-profile-curated',
	templateUrl: './left-profile-curated.component.html',
	styleUrls: ['./left-profile-curated.component.css']
})
export class LeftProfileCuratedComponent implements OnInit, OnDestroy {
	CountInfo=100;
	UserInfo: any;
	lookingForId: number = 2;
	isLoading = false;
	loadArr = [{}, {}];
	slideConfig = {
		slidesToShow: 2,
		slidesToScroll: 1,
		nextArrow: '<div class="nav-btn next-slide"></div>',
		prevArrow: '<div class="nav-btn prev-slide"></div>',
		dots: false,
		infinite: false
	};

	@ViewChild('slickModal', { static: false })
	slickModal: SlickCarouselComponent;

	public subscriptions$: Subscription[] = [];

	public curatedResultList: ICuratedResult[] = [];
	pageSize: number = 10;
	pageNumber: number = 1;
	samelookingId: number = 0;
	prevPageNumber: number = 0;
	event: any;
	constructor(
		private getsetService: GetSetService,
		private leftProfileCuratedService: LeftProfileCuratedService,
		private behaviourSubjectService: BehavioursubService // private toasterService: ToastrService
	) { }

	ngOnInit() {

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsRoleData$.subscribe(flag => {
				if (flag) {
					of('')
						.pipe(delay(2000)).shareReplay(1)
						.subscribe(() => {
							console.count('roledata hit');
							const roleData = this.getsetService.getRoleData();
							this.UserInfo = this.getsetService.getCurrentUserInfo();
							if (roleData !== undefined) {
								this.prevPageNumber = 0;
								this.lookingForId = roleData.LookingForId;
								this.CuratedResult(
									roleData.LookingForId,
									this.pageSize,
									this.pageNumber
								);  
							}
						});
				}
			}));

		// when user comes on dashboard

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsDashboard$.subscribe(flag => {
				if (flag) {
					of('')
						.pipe(delay(2000))
						.subscribe(() => {
							console.count('dashboard hit');
							const roleData = this.getsetService.getRoleData();
							this.UserInfo = this.getsetService.getCurrentUserInfo();
							if (roleData !== undefined) {
								this.lookingForId = roleData.LookingForId;
								this.CuratedResult(
									roleData.LookingForId,
									this.pageSize,
									this.pageNumber
								);
							}
						});
				}
			}));

		// When looking for (sub profile) changed
		this.UserInfo = this.getsetService.getCurrentUserInfo();

		this.subscriptions$.push(
			this.behaviourSubjectService.getLookingFor$.subscribe((data: number) => {
				// console.log('this.behaviourSubjectService.getLookingFor.subscribe((data: number)');
				this.lookingForId = data;
				if (data !== 0) {
					this.CuratedResult(data, this.pageSize, this.pageNumber);
				} else {
					of('')
						.pipe(delay(2000))
						.subscribe(() => {
							if (this.getsetService.getRoleData() !== undefined) {
								console.count('sub hit');
								const roleData = this.getsetService.getRoleData();
								switch (this.UserInfo.profileId) {
									case 1:
										this.lookingForId = roleData.LookingForId;
										this.CuratedResult(
											roleData.LookingForId,
											this.pageSize,
											this.pageNumber
										);
										break;
									case 2:
										this.lookingForId = roleData.LookingForId;
										this.CuratedResult(
											roleData.LookingForId,
											this.pageSize,
											this.pageNumber
										);
										break;
									case 3:
										this.lookingForId = roleData.LookingForId;
										this.CuratedResult(
											roleData.LookingForId,
											this.pageSize,
											this.pageNumber
										);
										break;
									case 4:
										this.lookingForId = roleData.LookingForId;
										this.CuratedResult(
											roleData.LookingForId,
											this.pageSize,
											this.pageNumber
										);
										break;
								}
							}
						});
					// this.CuratedResult();
				}
			}));
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}


	slickInit(e) {
		this.event = e;
		// console.log('slick initialized');
	}

	breakpoint(e) {
		// console.log('breakpoint');
	}

	afterChange(e) {
		// console.log('afterChange');
	}

	beforeChange(e) {
		// console.log('beforeChange');
	}

	getScreenSize(event?) {
		if (this.slickModal !== undefined) {
			if (window.innerWidth > 575) {
				if (!this.slickModal.initialized) {
					this.slickModal.initSlick();
				}
			} else if (this.slickModal.initialized) {
				this.slickModal.unslick();
			}
		}
	}

	/**
	 * Get Curated Search Result.
	 * @param lookingFor
	 * @param pageSize
	 * @param pageNumber
	 * this.prevPageNumber !== pageNumber  &&
	 */
	CuratedResult(lookingFor, pageSize = 0, pageNumber = 0) {
		// console.log('CuratedResult(lookingFor, pageSize = 10, pageNumber = 1)');
		if (this.prevPageNumber !== pageNumber || 1) {
			this.prevPageNumber = pageNumber;
			this.isLoading = true;
			// tslint:disable-next-line: max-line-length
			const url = `${environment.ApiUrl}/api/Dashboard/CuratedSearch?LookingFor=${lookingFor}&PageSize=${50}&PageNumber=${1}`;

			// console.log(url);
			this.leftProfileCuratedService.getCuratesResults(url, pageNumber).subscribe(
				(response: ResponseModel<ICuratedResult[]>) => {
					// console.log('response');
					// console.log(response);
					if (response.status === HttpStatusCode.OK) {
						this.curatedResultList = [];
						// this.curatedResultList = response.data;
						(response.data as Array<any>).forEach(element => {
							this.curatedResultList.push(element);
						});
						this.samelookingId = lookingFor;
						this.isLoading = false;
						this.prevPageNumber = 0;
						// tslint:disable-next-line: ban
						$('.slider')
							.not('.slick-initialized')
							.slick();
					}
					if (response.status === HttpStatusCode.NO_CONTENT) {
						this.curatedResultList = [];
						this.prevPageNumber = 0;
						this.isLoading = false;
					} else {
						this.isLoading = false;
					}
					this.isLoading = false;
				});
		}
	}

	sendRequestContact(
		
		userInfo: ICuratedResult,
		operation: number,
		status: number
	) {
		// this.isLoading = true;
		const url = `${environment.ApiUrl}/api/ContactRequest/ContactRequest`;
		const data: IRequestContact = {
			operation: operation,
			requestUserProfileId: userInfo.userProfileId,
			status: status
		};

		this.leftProfileCuratedService

			.requestContact(url, data)
			.subscribe((response: ResponseModel<IRequestContact>) => {
				if (response.status === HttpStatusCode.OK) {
					// this.toasterService.success(response.statusMessage);
					userInfo.isRequested = response.data.status;
				
				}
				// this.isLoading = false;
			});
	}
}
