import { LocationStrategy } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
	private unsubscriber : Subject<void> = new Subject<void>();

	constructor(private behaviourSubject: BehavioursubService,
		private locationStrategy: LocationStrategy,
		) {
		
	}

	ngOnInit() {
		if(localStorage.getItem('IsProfileDone')){
			this.preventBackButton();
			localStorage.removeItem('IsProfileDone');
		}
		// history.pushState(null, '', location.href);
		// window.onpopstate = function () {
   		// history.go(1);
		// };
		// history.pushState(null, '');
		// fromEvent(window, 'popstate').pipe(
		// 	takeUntil(this.unsubscriber)
		//   ).subscribe((_) => {
		// 	history.pushState(null, '');
		// 	alert(`You can't make changes or go back at this time.`);
		//   });
		// document.getElementById('searchCard').style.display = 'none';
		// localStorage.removeItem('roleId');
		// localStorage.removeItem('lookingId');

		if (document.getElementById('searchinput') !== null) {
			(<HTMLInputElement>document.getElementById('searchinput')).value = '';
		}
		window.scrollTo(0, 0);
		localStorage.setItem('isDashboard', 'true');
		this.behaviourSubject.isOnDashboard(true);
		this.behaviourSubject.isOnmessagebox(true);
		this.behaviourSubject.isbgcolor(true);

		if (localStorage.getItem('viewFilter') !== null) {
			localStorage.removeItem('viewFilter');
		}
		// this.reload();
		if (localStorage.getItem('isNewRegister') !== null) {

		
			document.getElementById('btnOpen').click();
			localStorage.removeItem('isNewRegister');
		}

		//  document.getElementById('btnOpen').click();
		

	}

	ngOnDestroy() {
		this.behaviourSubject.isbgcolor(false);
		// this.unsubscriber.next();
		// this.unsubscriber.complete();
	}
	reload() {
		if (
			localStorage.getItem('cnt') !== null &&
			localStorage.getItem('cnt') === '0'
		) {
			localStorage.removeItem('cnt');
			window.location.reload();
		}
	}

	openpostmodl() {
		document.getElementById('uploadpostmodal').click();
	}

	// Define a function to handle back button and use anywhere
    preventBackButton() {
        history.pushState(null, null, location.href);
        this.locationStrategy.onPopState(() => {
          history.pushState(null, null, location.href);
        })
      }
	
}
