import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private auth: AuthService,
		// private route: Router,
		private router: Router
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.auth.isLoggedIn()) {
			this.router.navigate(['/home']);
			return true;
		}
		this.router.navigate(['']);
		// this.auth.logout();
		return false;
	}
}
