import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
	ResponseModel,
	Dropdown,
	HttpStatusCode
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { AuthService } from 'src/app/shared/Helper/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { IFilterSearch } from '../startup-search/startup-search.model';
import { Profiles } from 'src/app/shared/Helper/Enum/Profiles';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-manufacturing-search',
	templateUrl: './manufacturing-search.component.html',
	styleUrls: ['./manufacturing-search.component.css']
})
export class ManufacturingSearchComponent implements OnInit, OnDestroy {
	public subscriptions$: Subscription[] = [];
	LookCapacityForm: FormGroup;
	HaveCapacityForm: FormGroup;
	submitted = false;
	Industries = [];
	Units = [];
	Country = [];
	Cities = [];
	products: any;
	product = [];
	pageSize: any = 10;
	pageNumber: any = 1;
	RoleId: number = 7;
	selectedCountry: any;
	ForData: any = [];

	constructor(
		private authservice: AuthService,
		private formBuilder: FormBuilder,
		private getSetService: GetSetService,
		private behaviourSubjectService: BehavioursubService,
		private commonService: CommonService
	) { }

	ngOnInit() {
		this.subscriptions$.push(
			this.behaviourSubjectService.getLookingFor$.subscribe((data: number) => {
				if (data !== 0) {
					this.RoleId = data;
				}
			}));

		this.LookCapacityForm = this.formBuilder.group({
			Industry: ['', Validators.required],
			ProductFormGroup: this.formBuilder.group({
				Product: [''],
				Unit: [''],
				Volume: ['', Validators.pattern(/^[0-9]{1,5}$/)]
			}),
			Country: ['', Validators.required],
			City: ['', Validators.required]
		});

		this.HaveCapacityForm = this.formBuilder.group({
			Industry: ['', Validators.required],
			ProductHavCapFormGroup: this.formBuilder.group({
				Product: [''],
				Unit: [''],
				Volume: ['', Validators.pattern(/^[0-9]{1,5}$/)]
			}),
			Country: ['', Validators.required],
			City: ['', Validators.required]
		});

		this.GetDropdowns();
		this.getLookingForData();
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}

	// convenience getter for easy access to form fields
	get Lookcapacityform() {
		return this.LookCapacityForm.controls;
	}
	get Havecapacityform() {
		return this.HaveCapacityForm.controls;
	}

	GetDropdowns() {
		const data = {
			list: [1, 3, 4, 5, 9, 10]
		};
		const url = environment.ApiUrl + '/api/MasterHandler/getAllDropdown';
		this.authservice
			.GetRole(url, data)
			.subscribe((response: ResponseModel<Dropdown>) => {
				if (response.status === HttpStatusCode.OK) {
					this.Industries = response.data.industries;
					this.Units = response.data.unit;
					this.Country = response.data.country;
					this.selectedCountry = response.data.country[0];
				}
			});
	}

	selecthavcapacity() {
		this.product = [];
	}
	onChangecountry(event) {
		const countrselect = this.Country.find(c => +c.countryId === event);
		this.Cities = [];
		countrselect.cities.forEach(element => {
			this.Cities.push(element);
		});
	}

	AddProduct(producttype) {
		if (producttype === 1) {
			this.products = {
				userProfileId: this.getSetService.GetUserId(),
				product: this.Lookcapacityform.ProductFormGroup.get('Product').value,
				unitId: this.Lookcapacityform.ProductFormGroup.get('Unit').value,
				volume: +this.Lookcapacityform.ProductFormGroup.get('Volume').value
			};
			if (
				this.products.product !== null &&
				this.products.unitId !== null &&
				this.products.volume !== 0
			) {
				this.product.push(this.products);
				this.Lookcapacityform.ProductFormGroup.get('Product').reset();
				this.Lookcapacityform.ProductFormGroup.get('Unit').reset();
				this.Lookcapacityform.ProductFormGroup.get('Volume').reset();
			}

			// if (this.product.length !== 0) {
			//   this.Lookcapacityform.ProductFormGroup.get('Product').clearValidators();
			//   this.Lookcapacityform.ProductFormGroup.get('Product').updateValueAndValidity();
			//   this.Lookcapacityform.ProductFormGroup.get('Unit').clearValidators();
			//   this.Lookcapacityform.ProductFormGroup.get('Unit').updateValueAndValidity();
			//   this.Lookcapacityform.ProductFormGroup.get('Volume').clearValidators();
			//   this.Lookcapacityform.ProductFormGroup.get('Volume').updateValueAndValidity();
			// }
		}
		if (producttype === 2) {
			this.products = {
				userProfileId: this.getSetService.GetUserId(),
				product: this.Havecapacityform.ProductHavCapFormGroup.get('Product')
					.value,
				unitId: this.Havecapacityform.ProductHavCapFormGroup.get('Unit').value,
				volume: +this.Havecapacityform.ProductHavCapFormGroup.get('Volume')
					.value
			};
			if (
				this.products.product !== null &&
				this.products.unitId !== null &&
				this.products.volume !== 0
			) {
				this.product.push(this.products);
				this.Havecapacityform.ProductHavCapFormGroup.get('Product').reset();
				this.Havecapacityform.ProductHavCapFormGroup.get('Unit').reset();
				this.Havecapacityform.ProductHavCapFormGroup.get('Volume').reset();
			}
			// if (this.product.length !== 0) {
			//   this.Havecapacityform.ProductHavCapFormGroup.get('Product').clearValidators();
			//   this.Havecapacityform.ProductHavCapFormGroup.get('Product').updateValueAndValidity();
			//   this.Havecapacityform.ProductHavCapFormGroup.get('Unit').clearValidators();
			//   this.Havecapacityform.ProductHavCapFormGroup.get('Unit').updateValueAndValidity();
			//   this.Havecapacityform.ProductHavCapFormGroup.get('Volume').clearValidators();
			//   this.Havecapacityform.ProductHavCapFormGroup.get('Volume').updateValueAndValidity();
			// }
		}
	}

	RemovePRoduct(index, producttype) {
		this.product.splice(index, 1);
		// if (this.product.length === 0 && producttype === 1) {
		//   this.Lookcapacityform.ProductFormGroup.get('Product').setValidators(Validators.required);
		//   this.Lookcapacityform.ProductFormGroup.get('Product').updateValueAndValidity();
		//   this.Lookcapacityform.ProductFormGroup.get('Unit').setValidators(Validators.required);
		//   this.Lookcapacityform.ProductFormGroup.get('Unit').updateValueAndValidity();
		//   this.Lookcapacityform.ProductFormGroup.get('Volume').setValidators(Validators.required);
		//   this.Lookcapacityform.ProductFormGroup.get('Volume').updateValueAndValidity();
		// }
		// if (this.product.length === 0 && producttype === 2) {
		//   this.Havecapacityform.ProductHavCapFormGroup.get('Product').setValidators(Validators.required);
		//   this.Havecapacityform.ProductHavCapFormGroup.get('Product').updateValueAndValidity();
		//   this.Havecapacityform.ProductHavCapFormGroup.get('Unit').setValidators(Validators.required);
		//   this.Havecapacityform.ProductHavCapFormGroup.get('Unit').updateValueAndValidity();
		//   this.Havecapacityform.ProductHavCapFormGroup.get('Volume').setValidators(Validators.required);
		//   this.Havecapacityform.ProductHavCapFormGroup.get('Volume').updateValueAndValidity();
		// }
	}

	getLookingFor() {
		const data: IFilterSearch = {
			userProfileId: 2,
			commingFrom: 4,
			lookingFor: Profiles.LookingForCapacity,
			budgetId: 0,
			industryId: +this.LookCapacityForm.value.Industry,
			saleId: 0,
			stageId: 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: +this.LookCapacityForm.value.Country,
			cityId: +this.LookCapacityForm.value.City,
			currencyId: 0,
			expectedJoiningId: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber
		};
		this.behaviourSubjectService.currentFilterData(data);
		this.behaviourSubjectService.currentFilterModalStatus(true);
	}

	getHavingFor() {
		const data: IFilterSearch = {
			userProfileId: 2,
			commingFrom: 4,
			lookingFor: Profiles.HavingCapacity,
			budgetId: 0,
			industryId: +this.HaveCapacityForm.value.Industry,
			saleId: 0,
			stageId: 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: +this.HaveCapacityForm.value.Country,
			cityId: +this.HaveCapacityForm.value.City,
			currencyId: 0,
			expectedJoiningId: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber
		};
		this.behaviourSubjectService.currentFilterData(data);
		this.behaviourSubjectService.currentFilterModalStatus(true);
	}

	ResetLookingForm() {
		this.LookCapacityForm.reset();
	}

	ResetHavingForm() {
		this.HaveCapacityForm.reset();
	}

	isNumber(evt) {
		evt = (evt) ? evt : window.event;
		const charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	getLookingForData() {
		const url = `${environment.ApiUrl}/api/Auth/GetLookingFor?LookingFor=${this.RoleId}`;
		this.commonService.get(url).subscribe(
			(response) => {
				if (response.status === 200) {
					this.ForData = response.data;
				}
			}
		);
	}
}
