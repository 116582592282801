import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { environment } from 'src/environments/environment';
import { ReasonType } from 'src/app/shared/Helper/Enum/Report';
import { UserService } from 'src/app/chatting/shared/user.service';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.css']
})
export class UserReportComponent implements OnInit {
  type = 0;
  reasonId = 0
  reasonList :any;
  reportForm: FormGroup;
  submited = false;
  userStaus :any;
  multiple="false"

  constructor(
    public dialogRef: MatDialogRef<UserReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private commonService: CommonService,
    private toasterService: ToastrService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private getSetService: GetSetService,
    private route: Router,

  ) {
    this.type = +this.data.type

   }

  ngOnInit() {
    this.getReasonlist()
    this.reportForm = this.formBuilder.group({
			reason: ['', Validators.required],
		});
  }

  blockUser(){

    const data ={
      blockUserProfileId:+this.data.profileId,
      blockReason: " "
    }
    const url = `${environment.ApiUrl}api/Auth/UserBlock`;
  
    this.commonService.post(url, data).subscribe(
      (Response: ResponseModel<any>) => {
        if (Response.status === 200) {
          this.toasterService.success(Response.statusMessage);
        this.close(this.type);
        if(this.type == 2 || this.type == 4){
          const profileInfo = this.getSetService.getCurrentUserInfo();

          this.userStaus =  this.userService.getUserStatus(profileInfo.userProfileId, this.data.profileId)
          if(this.userStaus == 'Connected'|| this.userStaus == 'Pending' || this.userStaus == 'Requested'){
         this.userService.updateConnectReqBlock(profileInfo.userProfileId, this.data.profileId, 'Blocked','UnBlocked');
         this.route.navigate(['home']);

        }

        }
        if(this.type == 3){
          const profileInfo = this.getSetService.getCurrentUserInfo();
          // this.userService.updateConnectReqBlock(profileInfo.userProfileId, this.receiver, 'Connected','Connected');
          this.userStaus =  this.userService.getUserStatus(profileInfo.userProfileId, this.data.profileId)
                   if(this.userStaus == 'Blocked'){
                    this.userService.updateConnectReqBlock(profileInfo.userProfileId, this.data.profileId, 'New','New');
                  }
                  this.route.navigate(['home']);

        }


        } else {
          this.toasterService.success(Response.statusMessage);
          this.close(0);

        }
      });

  }
  get f() { return this.reportForm.controls; }

  reportUser(){
    this.submited = true;
    if (this.reasonId !== 0) {
      this.submited =  false;
    const data = {
      repotedUserProfileId:+this.data.profileId,
      reportReasonId:this.reasonId,
      // reportReasonId:+(this.reportForm.value.reason ? this.reportForm.value.reason :0).toString(),
      reportReasonType: this.type == 1 ? +ReasonType.UserReport : +ReasonType.MessageReport
    }

    const url = `${environment.ApiUrl}api/ReportReason/ReportUser`;
  
    this.commonService.post(url, data).subscribe(
      (Response: ResponseModel<any>) => {
        if (Response.status === 200) {
          this.toasterService.success(Response.statusMessage);
        this.close(this.type);
       
        } else {
          this.toasterService.success(Response.statusMessage);
          this.close(0);

        }
      });
    }
    else{

    }
  }
  
  reasonSelection(reason){
    this.reasonId = +reason;
    console.log('reason id '+reason)
  }

  getReasonlist() {
		const url =
			`${environment.ApiUrl}/api/ReportReason/GetReportReasonList`
		this.commonService.get(url).subscribe((response) => {
			this.reasonList = response.data;
		
		});
	}
  close(type){
      this.dialogRef.close(type);
  }

}
