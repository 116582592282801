import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { BehavioursubService } from './shared/Helper/services/behavioursub.service';
import { Router, NavigationEnd } from '@angular/router';
import { PushNotificationService } from './shared/Helper/services/push-notification.service';
import { Subscription } from 'rxjs';
declare var $: any;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
	title = 'Entie';
	isbgcolor = false;
	// isbgcolor = true;
	isDashboard: boolean;

	public subscriptions$: Subscription[] = [];

	ngOnInit(): void {
		this.pushNotificationService.getPermission();

		this.subscriptions$.push(
			this.behavioursubservice.getIsDashboard$.subscribe(data => {
				this.isDashboard = data;
			}));
	}
	constructor(
		// private authService: AuthService,
		private behavioursubservice: BehavioursubService,
		private cdRef: ChangeDetectorRef,
		private router: Router,
		private pushNotificationService: PushNotificationService
	) {

		this.subscriptions$.push(
			this.behavioursubservice.getIsBgcolor$.subscribe(data => {
				this.isbgcolor = data;
			}));

		this.router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				// tslint:disable-next-line: ban
				$('#searchbox').css('display', 'none');
				// tslint:disable-next-line: ban
				$('#searchinput').val('');
			}
		});
	}

	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}
}
