import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import {
	ResponseModel,
	HttpStatusCode,
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { environment } from 'src/environments/environment';
import { UploadpostComponent } from '../../uploadpost/uploadpost/uploadpost.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EncrDecrService } from 'src/app/shared/Helper/services/encr-decr.service';

@Component({
	selector: 'app-mypost',
	templateUrl: './mypost.component.html',
	styleUrls: ['./mypost.component.css'],
})
export class MypostComponent implements OnInit, OnDestroy {
	profileInfo: any;
	postlist: any[];
	pageSize = 10;
	pageNumber = 1;
	deletePostInfo: any;
	isEmptyList = false;
	successMessage = '';
	isLoading = false;
	IsProfile = false;
	public subscriptions$: Subscription[] = [];

	constructor(
		private behaviourSubject: BehavioursubService,
		private commonservice: CommonService,
		public dialog: MatDialog,
		private encrDecrService: EncrDecrService
	) { }

	ngOnInit() {
		if (document.getElementById('searchinput') !== null) {
			(<HTMLInputElement>document.getElementById('searchinput')).value = '';
		}
		this.behaviourSubject.isbgcolor(true);
		this.getUserProfileInfo();
		this.getpostlist(this.pageSize, this.pageNumber);

		if (localStorage.getItem('dashfilter') !== null) {
			localStorage.removeItem('dashfilter');
		}

		this.subscriptions$.push(
			this.behaviourSubject.getPostListStatus$.subscribe((flag) => {
				if (flag) {
					this.getpostlist(this.pageSize, this.pageNumber);
				}
			}));

		this.subscriptions$.push(
			this.behaviourSubject.getIsRoleData$.subscribe((flag) => {
				if (flag) {
					this.getpostlist(this.pageSize, this.pageNumber);
				}
			}));
		this.subscriptions$.push(
			this.behaviourSubject.getPostId$.subscribe((id) => {
				if (id === 0) {
					this.successMessage = 'Posted Successfully';
				} else {
					this.successMessage = 'Changes Saved';
				}
			}));

		this.behaviourSubject.currentOnMyPostStatus(true);
	}

	ngOnDestroy() {
		this.behaviourSubject.currentOnMyPostStatus(false);
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}

	getUserProfileInfo() {
		this.IsProfile = true;
		// const url = `${environment.ApiUrl}/api/User/Myprofie`;
		// this.commonservice.get(url).subscribe((response: ResponseModel<any>) => {
		// 	if (response.status === HttpStatusCode.OK) {
		// 		this.IsProfile = false;
		// 		this.profileInfo = response.data;
		// 	}
		// });


		var delay;
		delay = setInterval(() => {
			if (this.profileInfo == null) {
				switch (localStorage.getItem('profileId')) {
					case '1':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('1'));
						break;

					case '2':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('2'));
						break;

					case '3':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('3'));
						break;

					case '4':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('4'));
						break;

					default:
						break;
				}
			} else {
				clearInterval(delay);
				this.IsProfile = false;
			}
		}, 1000);
	}

	getpostlist(pageSize: number, pageNumber: number) {
		this.isLoading = true;
		this.isEmptyList = false;
		const url = `${environment.ApiUrl}/api/Post/listPost?PageSize=${pageSize}&PageNumber=${pageNumber}&IsMyPost=true`;
		this.commonservice.get(url).subscribe((response: ResponseModel<any[]>) => {
			if (response.status === HttpStatusCode.OK) {
				this.postlist = response.data;
				this.isEmptyList = false;
				this.isLoading = false;
			} else if (response.status === HttpStatusCode.NO_CONTENT) {
				this.isEmptyList = true;
				this.isLoading = false;
				this.postlist = [];
			} else {
				this.isEmptyList = false;
				this.isLoading = false;
			}
		});
	}

	editPost(postDetails) {
		const dialogRef = this.dialog.open(UploadpostComponent, {
			width: '565px',
			height: 'auto',
			data: { postinfo: postDetails },
			disableClose: true,
		});

		dialogRef.afterClosed().subscribe((result) => {
			// console.log('The dialog was closed');
		});
	}

	getDeleteData(post) {
		this.deletePostInfo = post;
	}

	deletePost() {
		const formData = new FormData();
		formData.append('PostId', this.deletePostInfo.id);
		const url = `${environment.ApiUrl}/api/Post/DeletePost`;
		this.commonservice
			.upload(url, formData)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					document.getElementById('deletePost').click();
					this.getpostlist(this.pageSize, this.pageNumber);
				}
			});
	}
}
