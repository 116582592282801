import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { DashboardSearchComponent } from './dashboard-search/dashboard-search.component';
import { StartupSearchComponent } from './dashboard-search/startup-search/startup-search.component';
import { CofounderSearchComponent } from './dashboard-search/cofounder-search/cofounder-search.component';
import { InvestorSearchComponent } from './dashboard-search/investor-search/investor-search.component';
import { ManufacturingSearchComponent } from './dashboard-search/manufacturing-search/manufacturing-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatOptionModule, MatSelectModule, MatCheckboxModule,
  MatFormFieldModule, MatInputModule, MatDialogModule
} from '@angular/material';
import { LeftProfileCuratedComponent } from './dashboard/left-profile-curated/left-profile-curated.component';
import { NewsFeedComponent } from './dashboard/news-feed/news-feed.component';
import { LeftProfileCuratedService } from './dashboard/left-profile-curated/left-profile-curated.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LeftProfileInfoComponent } from './dashboard/left-profile-info/left-profile-info.component';
import { ViewAllComponent } from './dashboard/news-feed/view-all/view-all.component';
import { PostslistComponent } from './dashboard/postslist/postslist.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FiltersModalComponent } from './dashboard/filters-modal/filters-modal.component';
import { AutosizeModule } from 'ngx-autosize';
import { PostDetailComponent } from './dashboard/post-detail/post-detail.component';
// import { ChattingFormComponent } from '../../chatwithsignalr/chatting-form/chatting-form.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
@NgModule({
  declarations: [
    DashboardComponent,
    DashboardSearchComponent,
    StartupSearchComponent,
    CofounderSearchComponent,
    InvestorSearchComponent,
    ManufacturingSearchComponent,
    LeftProfileCuratedComponent,
    NewsFeedComponent,
    LeftProfileInfoComponent,
    ViewAllComponent,
    PostslistComponent,
    FiltersModalComponent,
    PostDetailComponent,
    // ChattingFormComponent,
  ],
  imports: [
    CommonModule,
    MatSliderModule,
    DashboardRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    SlickCarouselModule,
    NgxSkeletonLoaderModule,
    AutosizeModule,
    MatDialogModule,
    MatProgressBarModule
  ],
  providers: [LeftProfileCuratedService],
  exports: [StartupSearchComponent,
    CofounderSearchComponent,
    InvestorSearchComponent,
    ManufacturingSearchComponent,
    LeftProfileInfoComponent],
  entryComponents: [FiltersModalComponent, PostDetailComponent]
})
export class DashboardModule { }

