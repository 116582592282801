import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewsfeedService } from './newsfeed.service';
import {
	HttpStatusCode,
	ResponseModel
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import {
	INewsFeedResults,
	IRequestContactFromNewsFeed
} from './newsfeed.model';
import { environment } from 'src/environments/environment';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { IFilterSearch } from '../../dashboard-search/startup-search/startup-search.model';
import { ICuratedResult } from '../left-profile-curated/left-profile-curated.model';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
declare var $: any;
@Component({
	selector: 'app-news-feed',
	templateUrl: './news-feed.component.html',
	styleUrls: ['./news-feed.component.css']
})
export class NewsFeedComponent implements OnInit, OnDestroy {
	newsFeedResultsList: INewsFeedResults[] = [];
	CountInfo=100;
	UserInfo: any;
	lookingForId = 3;
	pageSize = 10;
	pageNumber = 1;
	roleData = {
		RoleId: 0,
		LookingForId: 0
	};
	recomendedResults: ICuratedResult[] = [];
	sameLookingForId: number = 0;
	sameRecomendedLooking: number = 0;

	loadArr = [{}, {}, {}, {}, {}];
	isLoading = false;
	isRecLoading = false;
	isNewOnLoading = false;
	IsNewOnEntie = false;

	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: '<div class="nav-btn recnext-slide"></div>',
		prevArrow: '<div class="nav-btn recprev-slide"></div>',
		dots: false,
		infinite: true
	};
	newOnEntie: ICuratedResult[] = [];
	userFilter: any;
	displaycard = false;
	prevPageNumber: number = 0;

	public subscriptions$: Subscription[] = [];

	constructor(
		private newsFeedService: NewsfeedService,
		private getsetService: GetSetService,
		private behaviourSubjectService: BehavioursubService
	) { }

	ngOnInit() {
		window.scrollTo(0, 0);

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsRoleData$.subscribe(flag => {
				this.userFilter = this.getsetService.getRoleData();
				this.UserInfo = this.getsetService.getCurrentUserInfo();
				of('')
					.pipe(delay(2000))
					.subscribe(() => {
						const roleData = this.getsetService.getRoleData();
						if (flag && roleData !== undefined) {
							this.lookingForId = roleData.LookingForId;

							const data: IFilterSearch = {
								userProfileId: 0,
								commingFrom: roleData.RoleId,
								lookingFor: roleData.LookingForId,
								budgetId: 0,
								industryId: 0,
								saleId: 0,
								stageId: 0,
								roleId: 0,
								exp_Year: 0,
								timeCommitment: 0,
								countryId: 0,
								cityId: 0,
								currencyId: 0,
								expectedJoiningId: 0,
								pageSize: 10,
								pageNumber: 1
							};

							this.NewsFilterResult(data);
							this.getRecomendedResults(
								roleData.LookingForId,
								this.pageSize,
								this.pageNumber
							);
							this.getNewOnResults(roleData.LookingForId);
							// this.getNewOnResults(roleData.LookingForId, this.pageSize, this.pageNumber);
						}
					});
			}));

		this.UserInfo = this.getsetService.getCurrentUserInfo();

		this.subscriptions$.push(
			this.behaviourSubjectService.filterSearchData$.subscribe(
				(data: IFilterSearch) => {
					of('')
						.pipe(delay(2000))
						.subscribe(() => {
							this.roleData = this.getsetService.getRoleData();
							if (this.roleData !== undefined) {
								data.lookingFor = this.roleData.LookingForId;
								this.lookingForId = data.lookingFor;
								if (
									data.commingFrom === 0 &&
									this.roleData !== null &&
									this.roleData !== undefined
								) {
									data.commingFrom = this.UserInfo.profileId;
									switch (data.commingFrom) {
										case 1:
											data.lookingFor = this.roleData.LookingForId;
											this.lookingForId = this.roleData.LookingForId;
											break;
										case 2:
											data.lookingFor = this.roleData.LookingForId;
											this.lookingForId = this.roleData.LookingForId;
											break;
										case 3:
											data.lookingFor = this.roleData.LookingForId;
											this.lookingForId = this.roleData.LookingForId;
											break;
										case 4:
											data.lookingFor = this.roleData.LookingForId;
											this.lookingForId = this.roleData.LookingForId;
											break;
									}
									this.NewsFilterResult(data);
									this.getRecomendedResults(
										this.roleData.LookingForId,
										this.pageSize,
										this.pageNumber
									);
									this.getNewOnResults(this.roleData.LookingForId);
									// this.getNewOnResults(this.roleData.LookingForId, this.pageSize, this.pageNumber);
								} else {
									this.NewsFilterResult(data);
									this.getRecomendedResults(
										data.lookingFor,
										this.pageSize,
										this.pageNumber
									);
									this.getNewOnResults(data.lookingFor);
									// this.getNewOnResults(data.lookingFor, this.pageSize, this.pageNumber);
								}
							}
						});
				}
			));

		this.subscriptions$.push(
			this.behaviourSubjectService.getFilterData$.subscribe((data: any) => {
				if (data !== '') {
					this.NewsFilterResult(data);
				}
			}));
		// if(this.roleData !== undefined){
		// this.getRecomendedResults(this.lookingForId, this.pageSize, this.pageNumber);
		// }
		if (localStorage.getItem('dashfilter') === null) {

			this.subscriptions$.push(
				this.behaviourSubjectService.getIsRoleData$.subscribe(flag => {
					this.userFilter = this.getsetService.getRoleData();
					this.UserInfo = this.getsetService.getCurrentUserInfo();
					of('')
						.pipe(delay(2000))
						.subscribe(() => {
							const roleData = this.getsetService.getRoleData();
							if (flag && roleData !== undefined) {
								this.lookingForId = roleData.LookingForId;

								const data: IFilterSearch = {
									userProfileId: 0,
									commingFrom: roleData.RoleId,
									lookingFor: roleData.LookingForId,
									budgetId: 0,
									industryId: 0,
									saleId: 0,
									stageId: 0,
									roleId: 0,
									exp_Year: 0,
									timeCommitment: 0,
									countryId: 0,
									cityId: 0,
									currencyId: 0,
									expectedJoiningId: 0,
									pageSize: 10,
									pageNumber: 1
								};
								this.NewsFilterResult(data);
							}
						});
				}));
		}
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}

	onTabChange(flag:boolean){
		this.IsNewOnEntie = flag;
	}

	NewsFilterResult(filterData: IFilterSearch) {
		this.isLoading = true;
		if (this.prevPageNumber !== filterData.pageNumber) {
			this.prevPageNumber = filterData.pageNumber;
			const url = `${environment.ApiUrl}/api/Dashboard/NewsFeedFilter`;
			this.newsFeedService
				.getNewsFeedResults(url, filterData)
				.subscribe((response: ResponseModel<INewsFeedResults[]>) => {
					if (response.status === HttpStatusCode.OK) {
						this.getsetService.SetFilter(filterData);
						localStorage.setItem('dashfilter', JSON.stringify(filterData));
						this.isLoading = false;
						this.newsFeedResultsList = [];
						this.newsFeedResultsList = response.data;
						this.prevPageNumber = 0;
						this.behaviourSubjectService.currentFilterModalStatus(false);
						if (response.data.length === 0) {
							this.displaycard = true;
							this.newsFeedResultsList = [];
						} else {
							this.displaycard = false;
						}
					} else {
						this.isLoading = false;
						this.prevPageNumber = 0;
						this.newsFeedResultsList = [];
						if (this.newsFeedResultsList.length === 0) {
							this.newsFeedResultsList = [];
							this.displaycard = true;
						} else {
							this.newsFeedResultsList = [];
							this.displaycard = true;
						}
					}
				});
		}
	}

	// @HostListener('window:scroll', ['$event'])
	getNewsFeedFilterOnScroll(event) {
		// tslint:disable-next-line: ban
		const win = $(window);
		if (event.target.offsetTop >= 300) {
			// console.log(event);
		}

		if (win.scrollTop() === 0) {
			this.pageNumber = 1;
			// this.pageNumber = Math.floor((Math.random() * Math.floor(this.totCount)) + 1);
		}

		if (
			window.innerHeight + window.scrollY >=
			document.body.offsetHeight - 300
		) {
			// console.log('scroll');
		}
	}

	sendRequestContact(
		userInfo: INewsFeedResults,
		operation: number,
		status: number
	) {
		const url = `${environment.ApiUrl}/api/ContactRequest/ContactRequest`;
		const data: IRequestContactFromNewsFeed = {
			operation: operation,
			requestUserProfileId: userInfo.userProfileId,
			status: status
		};

		this.newsFeedService
			.requestContact(url, data)
			.subscribe((response: ResponseModel<IRequestContactFromNewsFeed>) => {
				if (response.status === HttpStatusCode.OK) {
					// this.toasterService.success(response.statusMessage);
					userInfo.isRequested = response.data.status;
				}
			});
	}

	getRecomendedResults(
		lookingFor: number,
		PageSize: number,
		PageNumber: number
	) {
		// const isCall = sessionStorage.getItem('callapi');
		if (this.sameRecomendedLooking !== lookingFor) {
			this.isRecLoading = true;
			// tslint:disable-next-line: max-line-length
			const url = `${environment.ApiUrl}/api/Dashboard/Recomended?LookingFor=${lookingFor}&PageSize=${20}&PageNumber=${1}`;
			this.newsFeedService
				.getRecomendedResults(url)
				.subscribe((response: ResponseModel<ICuratedResult[]>) => {
					if (response.status === HttpStatusCode.OK) {
						this.isRecLoading = false;
						this.recomendedResults = response.data;
						this.sameLookingForId = lookingFor;
					} else {
						this.isRecLoading = false;
					}
				});
		}
	}

	getNewOnResults(lookingFor:number) {
		// this.IsNewOnEntie = true;
		if (this.roleData !== undefined) {
			this.isNewOnLoading = true;
			// tslint:disable-next-line: max-line-length
			const url = `${environment.ApiUrl}/api/Dashboard/NewonEntie?LookingFor=${lookingFor}&PageSize=${20}&PageNumber=${1}`;
			this.newsFeedService
				.getNewOnResults(url)
				.subscribe((response: ResponseModel<ICuratedResult[]>) => {
					if (response.status === HttpStatusCode.OK) {
						this.newOnEntie = response.data;
						this.isNewOnLoading = false;
					} else {
						this.isNewOnLoading = false;
					}
				});
		}
	}
}
