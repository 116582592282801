import { Component, OnInit, OnDestroy } from '@angular/core';
import {
	ResponseModel,
	Dropdown,
	HttpStatusCode,
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/shared/Helper/services/auth.service';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';

import { IFilterSearch } from './startup-search.model';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-startup-search',
	templateUrl: './startup-search.component.html',
	styleUrls: ['./startup-search.component.css'],
})
export class StartupSearchComponent implements OnInit, OnDestroy {
	public subscriptions$: Subscription[] = [];
	InvestorForm: FormGroup;
	CoFounderForm: FormGroup;
	BuyerForm: FormGroup;
	submitted = false;
	selected = -1;
	// Experiencecount = new Array<number>(50);
	foods: any[] = [
		{ value: 'steak-0', viewValue: 'Steak' },
		{ value: 'pizza-1', viewValue: 'Pizza' },
		{ value: 'tacos-2', viewValue: 'Tacos' },
	];
	jobtime: any[] = [
		{ value: '1', viewValue: 'Full Time' },
		{ value: '2', viewValue: 'Part Time' },
	];
	RoleId: any = 2;
	Currency = [];
	Ammounts = [];
	copyAmmounts = [];
	Industries = [];
	copyStages = [];
	Stages = [];
	Sales = [];
	copySales= [];
	Country = [];
	Cities = [];
	Role = [];
	ExpectedJoining = [];
	lookingfordata: any;
	selectedid: any;
	UserInfo: any;
	pageSize: number = 10;
	pageNumber: number = 1;
	isInvestorComplete: boolean = false;
	isCofounderComplete: boolean = false;
	isBuyerComplete: boolean = false;
	roleData: any;
	selectedCountry: any;
	ForData: any = [];
	Experiencecount = new Array<number>(5);
	Monthcount = new Array<number>(13);

	constructor(
		private formBuilder: FormBuilder,
		private authservice: AuthService,
		private getSetService: GetSetService,
		private behaviourSubjectService: BehavioursubService,
		private commonService: CommonService
	) {}

	ngOnInit() {
		// console.log('startup-search');
		this.GetDropdowns();
		this.InvestorForm = this.formBuilder.group({
			Currency: [''],
			Amount: [''],
			Industry: [''],
			Stage: [''],
			Sales: [''],
			
		});

		this.InitializeCofounderForm();
		this.InitializeBuyerForm();

		this.GetCompleteLookingFor();

		this.UserInfo = this.getSetService.getCurrentUserInfo();

		// this.behaviourSubjectService.getLookingFor.subscribe((data: number) => {
		//   if (data !== 0) {
		//     this.RoleId = data;
		//   }
		// });

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsRoleData$.subscribe((data) => {
				if (
					localStorage.getItem('roleData') !== null &&
					localStorage.getItem('roleData') !== undefined
				) {
					const roles = this.getSetService.getRoleData();
					this.RoleId = roles.LookingForId;
				}
			})
		);
		// this.roleData = this.getSetService.getRoleData();
		// console.log(this.roleData);
		// this.onCurrencyChange(1);
		this.getLookingFor();

	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}

	InitializeCofounderForm() {
		this.CoFounderForm = this.formBuilder.group({
			Role: [''],
			Industry: [''],
			Years: ['0'],
			Months: ['0'],
			Country: [''],
			City: [''],
			ExpectedJoining: [''],
			timeCommitment: ['0'],
		});
	}

	InitializeBuyerForm() {
		this.BuyerForm = this.formBuilder.group({
			Currency: [''],
			Amount: [''],
			Industry: [''],
			Stage: [''],
			Sales: [''],
		});
	}
	// convenience getter for easy access to form fields
	get Investorform() {
		return this.InvestorForm.controls;
	}
	get CoFounderform() {
		return this.CoFounderForm.controls;
	}
	get Buyerform() {
		return this.BuyerForm.controls;
	}

	GetCompleteLookingFor() {
		const url = `${environment.ApiUrl}/api/Auth/GetUserProfileDD`;
		this.authservice
			.GetLookingFor(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					(response.data as Array<any>).forEach((element) => {
						if (element.profile === 1 && element.isComplete === true) {
							if (element.subProfile === 2) {
								this.isInvestorComplete = true;
							} else if (element.subProfile === 3) {
								this.isCofounderComplete = true;
							} else if (element.subProfile === 5) {
								this.isBuyerComplete = true;
							}
						}
					});
				}
			});
	}

	GetDropdowns() {
		const data = {
			list: [1, 2, 3, 4, 5, 9, 11, 14],
		};
		const url = environment.ApiUrl + '/api/MasterHandler/getAllDropdown';
		this.authservice
			.GetRole(url, data)
			.subscribe((response: ResponseModel<Dropdown>) => {
				this.Currency = response.data.currency;
				this.Ammounts = response.data.budget;
				this.copyAmmounts = response.data.budget;
				this.Industries = response.data.industries;
				this.copyStages = response.data.stages;
				this.Sales = response.data.sales;
				this.copySales = response.data.sales;
				this.Country = response.data.country;
				this.selectedCountry = response.data.country[0];
				this.Cities = this.selectedCountry.cities;
				// this.onChangecountry(1);
				this.Role = response.data.roles;
				this.ExpectedJoining = response.data.expectedJoining;
				this.Stages = this.copyStages.filter(m => m.name !== 'Any Stage');

			});
			// this.onCurrencyChange(1);

			
	}

	onChangecountry(event) {
		//debugger;
		const countrselect = this.Country.find((c) => +c.countryId === event);
		setTimeout(() => {
			countrselect.cities.forEach((element) => {
				this.Cities.push(element);
			});
		}, 2000);
	}

	ResetInvestor() {
		this.InvestorForm.reset();
	}

	ResetCofounder() {
		this.CoFounderForm.reset();
	}

	ResetBuyer() {
		this.BuyerForm.reset();
	}

	onReset() {
		this.submitted = false;
		this.InvestorForm.reset();
	}

	getInvestorFormData(): void {
		const data: IFilterSearch = {
			userProfileId: 2,
			commingFrom: this.UserInfo.profileId,
			lookingFor: 2,
			budgetId:
				this.InvestorForm.value.Amount !== ''
					? +this.InvestorForm.value.Amount
					: 0,
			industryId:
				this.InvestorForm.value.Industry !== ''
					? +this.InvestorForm.value.Industry
					: 0,
			saleId:
				this.InvestorForm.value.Sales !== ''
					? +this.InvestorForm.value.Sales
					: 0,
			stageId:
				this.InvestorForm.value.Stage !== ''
					? +this.InvestorForm.value.Stage
					: 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: 0,
			cityId: 0,
			currencyId:
				this.InvestorForm.value.Currency !== ''
					? +this.InvestorForm.value.Currency
					: 0,
			expectedJoiningId: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
		};
		this.behaviourSubjectService.currentFilterData(data);
		this.behaviourSubjectService.currentFilterModalStatus(true);
	
	}

	getCoFunderData(): void {
		const data: IFilterSearch = {
			userProfileId: 0,
			commingFrom: this.UserInfo.profileId,
			lookingFor: 3,
			budgetId: 0,
			industryId:
				this.CoFounderForm.value.Industry !== ''
					? +this.CoFounderForm.value.Industry
					: 0,
			saleId: 0,
			stageId: 0,
			roleId:
				this.CoFounderForm.value.Role !== ''
					? +this.CoFounderForm.value.Role
					: 0,
			exp_Year:
				this.CoFounderForm.value.Years !== ''
					? +this.CoFounderForm.value.Years
					: 0,
			// exp_Year:
			// 	this.CoFounderForm.value.Experience !== ''
			// 		? +this.CoFounderForm.value.Experience
			// 		: 0,
			timeCommitment: 0,
			countryId:
				this.CoFounderForm.value.Country !== ''
					? +this.CoFounderForm.value.Country
					: 0,
			cityId:
				this.CoFounderForm.value.City !== ''
					? +this.CoFounderForm.value.City
					: 0,
			currencyId: 0,
			expectedJoiningId:
				this.CoFounderForm.value.ExpectedJoining !== ''
					? +this.CoFounderForm.value.ExpectedJoining
					: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
		};
		this.behaviourSubjectService.currentFilterData(data);
		this.behaviourSubjectService.currentFilterModalStatus(true);
			}

	getBuyerData(): void {
		const data: IFilterSearch = {
			userProfileId: 0,
			commingFrom: this.UserInfo.profileId,
			lookingFor: 5,
			budgetId:
				this.BuyerForm.value.Amount !== '' ? +this.BuyerForm.value.Amount : 0,
			industryId:
				this.BuyerForm.value.Industry !== ''
					? +this.BuyerForm.value.Industry
					: 0,
			saleId:
				this.BuyerForm.value.Sales !== '' ? +this.BuyerForm.value.Sales : 0,
			stageId:
				this.BuyerForm.value.Stage !== '' ? +this.BuyerForm.value.Stage : 0,
			roleId: 0,
			exp_Year: 0,
			timeCommitment: 0,
			countryId: 0,
			cityId: 0,
			currencyId: 0,
			expectedJoiningId: 0,
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
		};
		this.behaviourSubjectService.currentFilterData(data);
		this.behaviourSubjectService.currentFilterModalStatus(true);
	}

	getLookingFor() {
		this.RoleId = this.getSetService.getRoleData().LookingForId;
		const url = `${environment.ApiUrl}/api/Auth/GetLookingFor?LookingFor=${this.RoleId}`;
		this.commonService.get(url).subscribe((response) => {
			if (response.status === 200) {
				this.ForData = response.data;
				// this.onCurrencyChange(this.ForData.currencyId);

				if (this.RoleId === 2) {
					this.InvestorForm.patchValue({
						Currency: this.ForData.currencyId,
						Amount: this.ForData.amountId,
						Industry: this.ForData.industryId,
						Stage: this.ForData.stageId,
						Sales: this.ForData.salesId,
					});

				} else if (this.RoleId === 3) {
					// this.onChangecountry(this.ForData.cityId);
					this.CoFounderForm.patchValue({
						Role: this.ForData.roleId,
						Industry: this.ForData.industryId,
						Years: String(this.ForData.experience).split('.')[0],
						// Months: String(this.ForData.experience).split('.')[1],
						Country: this.ForData.countryId,
						City: this.ForData.cityId,
						ExpectedJoining: this.ForData.expectedJoiningId,
						timeCommitment: String(this.ForData.timeCommitmentId),
					});
				} else if (this.RoleId === 5) {
					// this.onCurrencyChange(this.ForData.currencyId);
					this.BuyerForm.patchValue({
						Currency: this.ForData.currencyId,
						Amount: this.ForData.amountId,
						Industry: this.ForData.industryId,
						Stage: this.ForData.stageId,
						Sales: this.ForData.salesId,
					});


				}
			}
		});
	}

	onCurrencyChange(currencyID) {
		this.Ammounts = [];
		this.Sales = [];
		this.Ammounts = this.copyAmmounts.filter(m => m.referenceId === +currencyID);
		this.Sales = this.copySales.filter(s => s.referenceId === +currencyID && s.name !== 'Any Sales');

		
	  }
	  
}
