import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/Helper/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { environment } from 'src/environments/environment';
import { HttpStatusCode, ResponseModel } from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { Steps } from 'src/app/shared/Helper/Enum/Step';

@Component({
	selector: 'app-registration-dashboard',
	templateUrl: './registration-dashboard.component.html',
	styleUrls: ['./registration-dashboard.component.css']
})
export class RegistrationDashboardComponent implements OnInit, AfterViewInit {
	RoleId = 1;
	timerSubscription: any;
	slideConfig = {
		slidesToShow: 2,
		slidesToScroll: 1,
		nextArrow: '<div class="nav-btn next-slide"></div>',
		prevArrow: '<div class="nav-btn prev-slide"></div>',
		dots: false,
		infinite: false
	};
	@ViewChild('slickModal', { static: false })
	slickModal: SlickCarouselComponent;
	event: any;
	eventImage = '';
	eventList = [];

	constructor(
		private route: Router,
		private commonservice: CommonService,
		private authService: AuthService,
		private translate: TranslateService,
		private behavioursubservice: BehavioursubService,
		private getSetService: GetSetService,
	) {
		this.translate.setDefaultLang('en');
	}

	ngAfterViewInit() {
		 //debugger;
		document.getElementById('overlay').style.display = 'block';
		if (
			localStorage.getItem('User') !== null &&
			this.authService.isLoggedIn() 
		) {
			const stepId = this.getSetService.GetStepId();
			if (stepId === Steps.Lookingfor) {
				this.route.navigate(['auth/lookingfor']);
			} else if (stepId === Steps.Profile) {
				this.route.navigate(['auth/registerprofile']);
			} else if (
				stepId === Steps.Quiz 
			) {
				this.route.navigate(['auth/quize']);
			} else {
				setTimeout(() => {
					this.route.navigateByUrl('home');
					// document.getElementById('overlay').style.display = 'none';
				}, 5000);
			}
			document.getElementById('overlay').style.display = 'block';

			// if(localStorage.getItem('isDashboard')){
				// document.getElementById('overlay').style.display = 'block';
				// setTimeout(() => {
				// 	this.route.navigateByUrl('home');
				// 	// document.getElementById('overlay').style.display = 'none';
				// }, 5000);
			//}
			// else{
			// 	document.getElementById('overlay').style.display = 'none';

			// }
			
		} else {
			document.getElementById('overlay').style.display = 'none';
		}
	}


	downloadApp() {
		window.open(
			'https://play.google.com/store/apps/details?id=com.entie.entieproject&hl=en',
			'_blank'
		);
		// let newVariable: any;
		// newVariable = window.navigator;
		// newVariable.getInstalledRelatedApps().then(relatedApps => {
		// 	for (let app of relatedApps) {
		// 		console.log(app.platform);
		// 		console.log(app.url);
		// 		console.log(app.id);
		// 	}
		// });
	}

	ngOnInit() {
		window.scrollTo(0, 0);
		// this.SelectSubTypeRol(1);
		// this.authService.logout();
		// localStorage.removeItem('User');
		// this.behavioursubservice.Updateloginuser(true);
		this.RoleId = 1;
		if (localStorage.getItem('onLoginPage') !== null) {
			localStorage.removeItem('onLoginPage');
		}
		this.behavioursubservice.isOnLoginPage(false);
		localStorage.removeItem('isDashboard');
		this.getEventList();
	}

	getEventList() {
			// tslint:disable-next-line: max-line-length
			const url = `${environment.ApiUrl}/api/Event/GetAllActiveEventList`;
			this.commonservice
				.get(url)
				.subscribe((response: ResponseModel<any[]>) => {
					if (response.status === HttpStatusCode.OK) {
						this.eventList = response.data;
					} else {
						this.eventList = [];

					}
				});
		
	}

	zoomEvent(imagePath){
		this.eventImage = imagePath;
	}

	slickInit(e) {
		this.event = e;
		// console.log('slick initialized');
	}

	breakpoint(e) {
		// console.log('breakpoint');
	}

	afterChange(e) {
		// console.log('afterChange');
	}

	beforeChange(e) {
		// console.log('beforeChange');
	}


	SelectSubTypeRol(RoleId: any) {
		this.RoleId = RoleId;
		// localStorage.setItem('roleId', String(this.RoleId));
		// this.route.navigate(['auth/register/', this.RoleId]);
		this.authService.RoleId = RoleId;
		this.route.navigate(['auth/lookingfor']);
	}

	MobSelectSubTypeRol(elementId: string, RoleId: any) {
		document.getElementById(elementId).style.backgroundImage =
			'linear-gradient(to right, #14264e , #033197)';
		document.getElementById(elementId).style.color = '#fff';
		this.timerSubscription = interval(1000).subscribe(() => {
			this.RoleId = RoleId;
			this.route.navigate(['auth/register/', this.RoleId]);
			this.timerSubscription.unsubscribe();
		});
	}

	openloginform() {
		this.route.navigate(['auth/login']);
	}

	showpopup() {
		document.getElementById('openPopup').click();
		setTimeout(() => {
			document.getElementById('closePopup').click();
		}, 3000);
	}
}
