
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/Helper/services/common.service';
import { ChangePasswordComponent } from 'src/app/shared/change-password/change-password.component';
import { MatDialog } from '@angular/material';
import {
	ResponseModel,
	HttpStatusCode
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { EncrDecrService } from 'src/app/shared/Helper/services/encr-decr.service';

@Component({
	selector: 'app-left-profile-info',
	templateUrl: './left-profile-info.component.html',
	styleUrls: ['./left-profile-info.component.css']
})
export class LeftProfileInfoComponent implements OnInit, OnDestroy {
	isLoading:true;
	UserInfo: any;
	profileInfo = null;
	filterForm: FormGroup;
	roleData = {
		RoleId: 0,
		LookingForId: 0
	};
	lookingForId: number;
	priceRange = '0';
	List: any;
	IsProfile: boolean = false;
	DataFilter: any;
	rwidth = 0;
	scrollposition: number;
	public subscriptions$: Subscription[] = [];


	constructor(
		private getsetService: GetSetService,
		public behaviourSubjectService: BehavioursubService,
		public dialog: MatDialog,
		private commonService: CommonService,
		private fb: FormBuilder,
		private encrDecrService: EncrDecrService
	) {
		this.filterForm = this.fb.group({
			isPremium: [false],
			isVerified: [false]
			// industryId: [0],
			// countryId: [0],
			// cityId: [0]
		});
	}

	ngOnInit() {
		if (document.getElementById('searchinput') !== null) {
			(<HTMLInputElement>document.getElementById('searchinput')).value = '';
		}
		this.UserInfo = this.getsetService.getCurrentUserInfo();
		if (document.getElementById('overlay') !== null) {
			document.getElementById('overlay').style.display = 'none';
		}
		// this.getUserProfileInfo();

		this.checkProfileInfoCached();

		sessionStorage.removeItem('subStepId');
		this.getUserListForSearching();

		this.subscriptions$.push(
			this.behaviourSubjectService.getLookingFor$.subscribe(data => {
				//this.getUserProfileInfo();
				this.checkProfileInfoCached();
			}));

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsRoleData$.subscribe(flag => {
				if (flag) {
					//this.getUserProfileInfo();
					// this.checkProfileInfoCached();
				}
			}));

		this.subscriptions$.push(
			this.behaviourSubjectService.loginstatus$.subscribe(flag => {
				if (flag) {
					//this.getUserProfileInfo();
					// this.checkProfileInfoCached();
				}
			}));

		of('')
			.pipe(delay(2000))
			.subscribe(() => {
				this.roleData = this.getsetService.getRoleData();
				if (this.roleData !== undefined) {
					this.lookingForId = this.roleData.LookingForId;
				}
			});

		if (localStorage.getItem('dashfilter') !== null) {
			this.DataFilter = JSON.parse(localStorage.getItem('dashfilter'));
			// console.log(this.DataFilter);
			this.filterForm.controls.isPremium.setValue(
				this.DataFilter.premium
			);
			this.filterForm.controls.isVerified.setValue(
				this.DataFilter.verified
			);
			this.priceRange = this.DataFilter.rating;
		}

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsClearFilter$.subscribe((flag) => {
				if (flag) {
					localStorage.removeItem('dashfilter');
					this.filterForm.reset();
					this.priceRange = '0';
				}
			}));
	}

	ngOnDestroy() {
		sessionStorage.removeItem('subStepId');
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}


	@HostListener('window:scroll', [])
	onWindowScroll() {

		this.scrollposition = window.scrollY;
	}

	openChangePassword(): void {
		this.dialog.open(ChangePasswordComponent, {
			width: '500',
			height: 'auto'
		});
	}
	activeFollowing() {
		this.behaviourSubjectService.currentIsFollowingActive(true);
	}
	clearFilter() {
		this.filterForm.reset();
		this.priceRange = '0';
		localStorage.removeItem('dashfilter');
		this.ApplyFilter();
	}

	checkProfileInfoCached() {
		this.IsProfile = true;
		if (this.profileInfo !== null) {
			switch (localStorage.getItem('mainProfileId')) {
				case '1':
					if (localStorage.getItem('1') !== null) {
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('1'));
						this.IsProfile = false;
					} else {
						this.getUserProfileInfo();
					}
					break;

				case '2':
					if (localStorage.getItem('2') !== null) {
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('2'));
						this.IsProfile = false;
					} else {
						this.getUserProfileInfo();
					}
					break;

				case '3':
					if (localStorage.getItem('3') !== null) {
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('3'));
						this.IsProfile = false;
					} else {
						this.getUserProfileInfo();
					}
					break;

				case '4':
					if (localStorage.getItem('4') !== null) {
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('4'));
						this.IsProfile = false;
					} else {
						this.getUserProfileInfo();
					}
					break;

				default:
					break;
			}
		} else {
			this.getUserProfileInfo();
			// this.IsProfile = false;
		}
	}

	getUserProfileInfo() {
		this.IsProfile = true;
		const url = `${environment.ApiUrl}/api/User/Myprofie`;
		this.commonService.get(url).pipe(take(1)).subscribe((response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
				this.IsProfile = false;
				this.profileInfo = response.data;

				switch (this.profileInfo.comingFrom) {
					case 1:
						localStorage.setItem('1', this.encrDecrService.encrypt(this.profileInfo));
						break;
					case 2:
						localStorage.setItem('2', this.encrDecrService.encrypt(this.profileInfo));
						break;
					case 3:
						localStorage.setItem('3', this.encrDecrService.encrypt(this.profileInfo));
						break;
					case 4:
						localStorage.setItem('4', this.encrDecrService.encrypt(this.profileInfo));
						break;

					default:
						break;
				}
			}
		});
	}

	getUserListForSearching() {
		const url = `${environment.ApiUrl}/api/User/NamesForSearching`;
		this.commonService.get(url).subscribe((response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
				this.getsetService.SetUserList(response.data);
				this.behaviourSubjectService.currentUserList(true);
			}
		});
	}


	ApplyFilter() {
		this.isLoading = true;
		const filterData = this.getsetService.getFilter();
		// console.log(filterData);

		let data;

		if (filterData !== null) {
			data = {
				userProfileId: 0,
				commingFrom: this.UserInfo.profileId,
				lookingFor: this.lookingForId,
				budgetId: 0,
				industryId: this.filterForm.value.industryId
					? Number(this.filterForm.value.industryId)
					: 0,
				saleId: filterData.saleId ? filterData.saleId : 0,
				stageId: filterData.stageId ? filterData.stageId : 0,
				roleId: filterData.roleId ? filterData.roleId : 0,
				exp_Year: filterData.exp_Year ? filterData.exp_Year : 0,
				timeCommitment: filterData.timeCommitment
					? filterData.timeCommitment
					: 0,
				countryId: filterData.countryId ? filterData.countryId : 0,
				cityId: filterData.cityId ? filterData.cityId : 0,
				currencyId: filterData.currencyId ? filterData.currencyId : 0,
				expectedJoiningId: filterData.expectedJoiningId
					? filterData.expectedJoiningId
					: 0,
				pageSize: 10,
				pageNumber: 1,
				premium: this.filterForm.value.isPremium ? 1 : 0,
				verified: this.filterForm.value.isVerified ? 1 : 0,
				rating:
					this.priceRange !== null && this.priceRange !== undefined
						? Number(this.priceRange)
						: 0,
				searchStr: ''
			};
		} else {
			data = {
				userProfileId: 0,
				commingFrom: this.UserInfo.profileId,
				lookingFor: this.lookingForId,
				budgetId: 0,
				industryId: this.filterForm.value.industryId
					? Number(this.filterForm.value.industryId)
					: 0,
				saleId: 0,
				stageId: 0,
				roleId: 0,
				exp_Year: 0,
				timeCommitment: 0,
				countryId: 0,
				cityId: 0,
				currencyId: 0,
				expectedJoiningId: 0,
				pageSize: 10,
				pageNumber: 1,
				premium: this.filterForm.value.isPremium ? 1 : 0,
				verified: this.filterForm.value.isVerified ? 1 : 0,
				rating:
					this.priceRange !== null && this.priceRange !== undefined
						? Number(this.priceRange)
						: 0,
				searchStr: ''
			};
		}

		this.behaviourSubjectService.currentFilter(data);
		// this.filterForm.reset();
		// this.priceRange = '';
		// const url = `${environment.ApiUrl}/api/Dashboard/NewsFeedFilter`;
		// this.commonService
		// 	.post(url, data)
		// 	.subscribe((response: ResponseModel<any>) => {
		// 		if (response.status === HttpStatusCode.OK) {
		// 			this.List = response.data;
		// 		} else {
		// 			//this.toastrService.success(response.statusMessage);
		// 		}
		// 	});
	}

	RateSlider(event: any) {
		if (event.target.value === '0') {
			this.rwidth = 9;
		}
		if (event.target.value === '1') {
			this.rwidth = 44;
		}
		if (event.target.value === '2') {
			this.rwidth = 82;
		}
		if (event.target.value === '3') {
			this.rwidth = 124;
		}
		if (event.target.value === '4') {
			this.rwidth = 165;
		}
		if (event.target.value === '5') {
			this.rwidth = 205;
		}
	}
}
