import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../Helper/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { BehavioursubService } from '../Helper/services/behavioursub.service';
import { GetSetService } from '../Helper/services/get-set.service';
import { HeaderService } from './header.service';
import {
	ResponseModel,
	HttpStatusCode,
} from '../Helper/Responsemodel/responsemodel';
import {
	IUserCompleteLookingFor,
	ItokenForSwitchProfile,
	INotification,
} from './header.model';
import { environment } from 'src/environments/environment';
import { LoginResponseModel } from 'src/app/auth/login/login.model';
import { Steps } from '../Helper/Enum/Step';
import { FormGroup, FormBuilder } from '@angular/forms';

import { CommonService } from '../Helper/services/common.service';
import { LogoutPopupComponent } from './logout-popup/logout-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../shared/change-password/change-password.component';
// tslint:disable-next-line: max-line-length
import { RejectRequestPopupComponent } from 'src/app/home/profile/edit-profile/notification/reject-request-popup/reject-request-popup.component';

import { ApprovenotificationComponent } from './approvenotification/approvenotification.component';
import { RemovenotificationComponent } from './removenotification/removenotification.component';
import { PushNotificationService } from '../Helper/services/push-notification.service';
import { Subscription } from 'rxjs';
import { EncrDecrService } from '../Helper/services/encr-decr.service';

declare var $: any;

// tslint:disable-next-line: ban
$(document).mouseup((e) => {
	// tslint:disable-next-line: ban
	const container = $('#searchbox');

	if (!container.is(e.target) && container.has(e.target).length === 0) {
		container.css('display', 'none');
	}
});

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	foods: any[] = [
		{ value: 'Marathi', viewValue: 'Marathi' },
		{ value: 'hindi', viewValue: 'Hindi' },
		{ value: 'en', viewValue: 'English' },
		{ value: 'fr', viewValue: 'French' },
	];

	ProfileRoles: any[] = [];
	defaultRoleForm: FormGroup;

	selectedvalue: any;
	IsLogin = false;
	UserInfo: any;
	searchquery: string;

	data = {
		RoleId: 0,
		LookingForId: 0,
	};
	selected: any = 3;
	notificationList: INotification[];
	isDashboard: boolean = false;
	followFollowingCount: any;
	counts: any;
	routerLink: any;
	profileInfo: any;
	wasInside = false;
	searchUserList: any = [];
	copySearchUserList: any = [];
	index: number = 0;
	isOnLoginPage: boolean = false;

	startupSearchId = 0;
	investorSearchId = 0;
	cofounderSearchId = 0;
	mfgSearchId = 0;
	RoleIds: any = [0, 0, 0, 0];
	isRoleEmpty = true;
	roleFilterArr: any = [];
	nameSearch: any;
	count: any;
	placeholder ='Search for Investor, Co-founder, and Buyer';
	IsSwitchProfile = false;
	public subscriptions$: Subscription[] = [];

	showblog = true;

	constructor(
		private route: Router,
		private authservice: AuthService,
		private translate: TranslateService,
		private getSetService: GetSetService,
		private headerService: HeaderService,
		private behaviourSubjectService: BehavioursubService,
		private router: Router,
		private formBuilder: FormBuilder,
		private commonService: CommonService,
		public dialog: MatDialog,
		public pushNotificationService: PushNotificationService,
		private encrDecrService: EncrDecrService
	) {
		this.defaultRoleForm = this.formBuilder.group({
			roleControl: [1],
		});
		// console.log('constructor call');
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				// console.log('router end');
				if (localStorage.getItem('roleinfo') !== null) {
					this.configLookingFor(this.getSetService.getRole());
				}
			}
		});
	}

	ngOnInit() {
		// if(this.IsLogin){
			setInterval(()=> { 
				if(this.IsLogin){this.getNotificationCount() }}, 30 * 1000);
		// }
		
		// tslint:disable-next-line: ban
		$('#searchbox').css('display', 'none');
		// Hide Blog icon on header when navigate on blog page
		// this.router.events.subscribe(val => {
		// 	const url = window.location.href;
		// 	if (url.includes('blog')) {
		// 		this.showblog = false;
		// 	} else {
		// 		this.showblog = true;
		// 	}
		// });
		
		this.subscriptions$.push(
			this.behaviourSubjectService.switchstatus$.subscribe((data) => {
				this.IsSwitchProfile = data
			})
		);
		

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsDashboard$.subscribe((data) => {
				this.isDashboard = data;
				this.configProfieRole();
			})
		);

		if (localStorage.getItem('isDashboard') !== null) {
			this.isDashboard = Boolean(localStorage.getItem('isDashboard'));
			// this.getpeofilecount();
			this.getUserProfileInfo();
		}

		this.subscriptions$.push(
			this.behaviourSubjectService.getloginInfo$.subscribe((data) => {
				this.profileInfo = data;
				this.getUserProfileInfo();
			})
		);
		this.subscriptions$.push(
			this.behaviourSubjectService.getIsNotification$.subscribe((flag) => {
				if (flag) {
					this.getNotificationCount();
				}
			})
		);

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsRoleData$.subscribe((flag) => {
				if (flag) {
					this.getUserProfileInfo();
				}
			})
		);

		this.subscriptions$.push(
			this.behaviourSubjectService.getUserInfo$.subscribe((data) => {
				if (data.hasOwnProperty('fullName')) {
					this.UserInfo = data;
				} else {
					this.UserInfo = this.getSetService.getCurrentUserInfo();
				}
			})
		);

		this.subscriptions$.push(
			this.behaviourSubjectService.loginstatus$.subscribe((islogin) => {
				this.IsLogin = this.authservice.isLoggedIn();
			})
		);

		this.subscriptions$.push(
			this.behaviourSubjectService.getRole$.subscribe((data) => {
				if (data) {
					this.configProfieRole();
				}
			})
		);

		if (localStorage.getItem('User') !== null) {
			this.configProfieRole();
		}
		if (localStorage.getItem('User') !== null) {
			this.UserInfo = this.getSetService.getCurrentUserInfo();
			this.selected = this.UserInfo.profileId;

			if (this.UserInfo.isScubscriptionComplete) {
				// this.getpeofilecount();
				this.getUserProfileInfo();
			}
		}

		this.subscriptions$.push(
			this.behaviourSubjectService.getCurrentUserList$.subscribe((flag) => {
				if (flag) {
					switch (this.UserInfo.profileId) {
						case 1:
							this.searchUserList = [];
							this.mfgSearchId = 0;
							this.startupSearchId = 0;
							this.cofounderSearchId = 0;
							this.investorSearchId = 0;
							this.copySearchUserList = [];
							const tempStartupList = this.getSetService.getUserList();
							(tempStartupList as Array<any>).forEach((element) => {
								if (element.profileId === 2 || element.profileId === 3) {
									this.searchUserList.push(element);
									this.copySearchUserList.push(element);
								}
							});
							break;

						case 2:
							this.searchUserList = [];
							this.startupSearchId = 1;
							this.cofounderSearchId = 0;
							this.mfgSearchId = 0;
							this.copySearchUserList = [];
							const tempInvestorList = this.getSetService.getUserList();
							(tempInvestorList as Array<any>).forEach((element) => {
								if (element.profileId === 1) {
									this.searchUserList.push(element);
									this.copySearchUserList.push(element);
								}
							});
							break;

						case 3:
							this.searchUserList = [];
							this.cofounderSearchId = 0;
							this.mfgSearchId = 0;
							this.startupSearchId = 1;
							this.investorSearchId = 0;
							this.copySearchUserList = [];
							const tempCofounderList = this.getSetService.getUserList();
							(tempCofounderList as Array<any>).forEach((element) => {
								if (element.profileId === 1) {
									this.searchUserList.push(element);
									this.copySearchUserList.push(element);
								}
							});
							break;

						case 4:
							this.searchUserList = [];
							this.mfgSearchId = 1;
							this.startupSearchId = 0;
							this.cofounderSearchId = 0;
							this.investorSearchId = 0;
							this.copySearchUserList = [];
							const tempMfgList = this.getSetService.getUserList();
							(tempMfgList as Array<any>).forEach((element) => {
								if (element.profileId === 4) {
									this.searchUserList.push(element);
									this.copySearchUserList.push(element);
								}
							});
							break;

						default:
							break;
					}
				} else {
					if (localStorage.getItem('list') !== null) {
						switch (this.UserInfo.profileId) {
							case 1:
								this.searchUserList = [];
								this.mfgSearchId = 0;
								this.startupSearchId = 0;
								this.cofounderSearchId = 0;
								this.investorSearchId = 0;
								this.copySearchUserList = [];
								const tempStartupList = this.getSetService.getUserList();
								(tempStartupList as Array<any>).forEach((element) => {
									if (element.profileId === 2 || element.profileId === 3) {
										this.searchUserList.push(element);
										this.copySearchUserList.push(element);
									}
								});
								break;

							case 2:
								this.searchUserList = [];
								this.startupSearchId = 1;
								this.cofounderSearchId = 0;
								this.mfgSearchId = 0;
								this.copySearchUserList = [];
								const tempInvestorList = this.getSetService.getUserList();
								(tempInvestorList as Array<any>).forEach((element) => {
									if (element.profileId === 1) {
										this.searchUserList.push(element);
										this.copySearchUserList.push(element);
									}
								});
								break;

							case 3:
								this.searchUserList = [];
								this.cofounderSearchId = 0;
								this.mfgSearchId = 0;
								this.startupSearchId = 1;
								this.investorSearchId = 0;
								this.copySearchUserList = [];
								const tempCofounderList = this.getSetService.getUserList();
								(tempCofounderList as Array<any>).forEach((element) => {
									if (element.profileId === 1) {
										this.searchUserList.push(element);
										this.copySearchUserList.push(element);
									}
								});
								break;

							case 4:
								this.searchUserList = [];
								this.mfgSearchId = 1;
								this.startupSearchId = 0;
								this.cofounderSearchId = 0;
								this.investorSearchId = 0;
								this.copySearchUserList = [];
								const tempMfgList = this.getSetService.getUserList();
								(tempMfgList as Array<any>).forEach((element) => {
									if (element.profileId === 4) {
										this.searchUserList.push(element);
										this.copySearchUserList.push(element);
									}
								});
								break;

							default:
								break;
						}
						// this.searchUserList = this.getSetService.getUserList();
						// this.copySearchUserList = this.getSetService.getUserList();
					}
				}
			})
		);

		this.subscriptions$.push(
			this.behaviourSubjectService.getOnLoginPageStatus$.subscribe((status) => {
				this.isOnLoginPage = status;
			})
		);

		this.subscriptions$.push(
			this.behaviourSubjectService.loginstatus$.subscribe((flag) => {
				if (flag) {
					this.getUserProfileInfo();
					this.IsLogin = this.authservice.isLoggedIn();
				}
			})
		);

		this.configProfieRole();
		this.getUserNotificationList();

		if (this.UserInfo !== null) {
			if (this.UserInfo.profileId === 1) {
				this.placeholder = 'Search for Investor and Cofounder';
			} else if (
				this.UserInfo.profileId === 2 ||
				this.UserInfo.profileId === 3
			) {
				this.placeholder = 'Search for Startups';
			} else if (this.UserInfo.profileId === 4) {
				this.placeholder = 'Search for Manufacturing Buisness';
			}
		}
		
	}

	updatelokkingfor(roleid) {
		this.behaviourSubjectService.currentRole(roleid);
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}

	getpeofilecount() {
		const url = `${environment.ApiUrl}/api/User/Myprofie`;

		this.commonService.get(url).subscribe((response) => {
			if (response.status === 200) {
				this.counts = response.data;
			} else {
			}
		});
	}

	openChangePassword(): void {
		this.dialog.open(ChangePasswordComponent, {
			width: '500',
			height: 'auto',
			panelClass: 'changePassword',
			disableClose: true,
		});
	}
	getUserProfileInfo() {
		const url = `${environment.ApiUrl}/api/User/Myprofie`;
		this.commonService.get(url).subscribe((response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
				this.profileInfo = response.data;
				this.defaultRoleForm.controls.roleControl.setValue(
					this.profileInfo.comingFrom
				);
				// 		localStorage.setItem('profileId', this.profileInfo.comingFrom);
				// 		localStorage.setItem('userProfileId', this.profileInfo.userprofileId);
			}
		});


		var delay;
		delay = setInterval(() => {
			if (this.profileInfo == null) {
				switch (localStorage.getItem('mainProfileId')) {
					case '1':
						if (localStorage.getItem('1') !== null) {
							this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('1'));
							localStorage.setItem('profileId', this.profileInfo.comingFrom);
							localStorage.setItem('userProfileId', this.profileInfo.userprofileId);
						}
						break;

					case '2':
						if (localStorage.getItem('2') !== null) {
							this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('2'));
							localStorage.setItem('profileId', this.profileInfo.comingFrom);
							localStorage.setItem('userProfileId', this.profileInfo.userprofileId);
						}
						break;

					case '3':
						if (localStorage.getItem('3') !== null) {
							this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('3'));
							localStorage.setItem('profileId', this.profileInfo.comingFrom);
							localStorage.setItem('userProfileId', this.profileInfo.userprofileId);
						}
						break;

					case '4':
						if (localStorage.getItem('4')) {
							this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('4'));
							localStorage.setItem('profileId', this.profileInfo.comingFrom);
							localStorage.setItem('userProfileId', this.profileInfo.userprofileId);
						}
						break;

					default:
						break;
				}

			} else {
				clearInterval(delay);
				//this.IsProfile = false;
			}
		}, 5000);
	}

	activeFollowing() {
		this.behaviourSubjectService.currentIsFollowingActive(true);
	}

	activeFollowers() {
		this.behaviourSubjectService.currentIsFollowingActive(false);
	}

	OnSelect(a: any) {
		this.selectedvalue = a.value;
		this.translate.setDefaultLang(this.selectedvalue);
	}

	openloginform() {
		this.route.navigate(['auth/login']);
	}
	openRegistationForm() {
		this.route.navigate(['/register']);

	}

	editProfile() {
		this.route.navigate(['home/editprofile']);
	}

	LogOut() {
		this.dialog.open(LogoutPopupComponent, {
			width: '480px',
			height: '180px',
			panelClass: 'logout-class',
			disableClose: true,
		});
	}

	MyProfile() {
		this.route.navigate(['home/profile']);
	}

	OpenDashboard() {
		if(!this.IsSwitchProfile){
			if (this.isDashboard === true) {
				this.route.navigate(['home']);
			} else if (this.isDashboard === false) {
				this.route.navigate(['']);
			}
		} else{
			this.LogOut();
		}
		
	}

	opennotification(link: any) {
		this.routerLink = link;
	}
	openMessage(link: any) {
		this.routerLink = link;
	}
	/**
	 * Using this function configure role dropdown according to user's
	 * which profile completes.
	 * If one of the role is not complete show ADD button in front of dropdown label.
	 */
	configProfieRole() {
		//debugger;
		if (localStorage.getItem('User') !== null && this.isDashboard) {
			this.ProfileRoles = [
				{ value: 1, viewValue: 'Startup', lookingFor: [2, 3, 5] },
				{ value: 2, viewValue: 'Investor', lookingFor: [1, 5] },
				{ value: 3, viewValue: 'Co-Founder', lookingFor: [1] },
			];

			const url = `${environment.ApiUrl}/api/Auth/GetUserProfileDD`;
			this.headerService
				.getProfileComplete(url)
				.subscribe((response: ResponseModel<IUserCompleteLookingFor[]>) => {
					if (response.status === 200) {
						const demoArr = [];

						(response.data as Array<IUserCompleteLookingFor>).forEach(
							(data) => {
								if (demoArr.length !== 1) {
									if (data.isComplete === true) {
										demoArr.push(data);
									}
								}
							}
						);

						demoArr.forEach((element) => {
							(response.data as Array<IUserCompleteLookingFor>).forEach(
								(data) => {
									if (
										data.isComplete === true &&
										data.profile !== element.profile
									) {
										demoArr.push(data);
									}
								}
							);
						});

						this.ProfileRoles.forEach((data: any) => {
							demoArr.forEach((element) => {
								if (data.value === element.profile) {
									data.profile = element.profile;
									data.userProfileId = element.userProfileId;
									data.subProfile = element.subProfile;
									data.step = element.step;
									data.isComplete = element.isComplete;

									if (
										element.isComplete === true &&
										localStorage.getItem('roleData') === null
									) {
										const data2 = {
											RoleId: element.profile,
											LookingForId: element.subProfile,
										};

										this.getSetService.SetRoleData(data2);
									}
								} else {
									if (
										!(data as Object).hasOwnProperty('profile') ||
										data.profile === 0
									) {
										data.profile = 0;
										data.userProfileId = 0;
										data.subProfile = 0;
										data.step = 1;
										data.isComplete = false;
									}
								}
							});
						});
						console.log('Profile Role'+this.ProfileRoles);
					}
				});
		}
	}

	/**
	 * This function call when user click on Role dropdown(Startup / Investor / CoFounder / Mfg. Buisness).
	 * function call in HTML file on role dropdown.
	 * @param role
	 */
	configLookingFor(role: any) {
		sessionStorage.setItem('RoleId', role.value);
		localStorage.setItem('AddProfile', 'true');
		switch (role.value) {
			case 1:
				if (role.profile === 0) {
					this.behaviourSubjectService.currentIncompleteId(2);
					sessionStorage.setItem('RoleId', role.value);
					// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
					this.switchAddProfile(1, 0, role.subProfile, role.isComplete);
					this.behaviourSubjectService.updateSwitch(true);
					if (role.step === Steps.Lookingfor) {
						this.behaviourSubjectService.IsProfileFor(false);
						localStorage.removeItem('isProfileinfo');
						this.router.navigateByUrl(`auth/lookingfor/startup`);
					} else if (role.step === Steps.Profile) {
						this.router.navigate(['auth/registerprofile']);
					}
				} else {
					this.data.RoleId = role.value;
					if (this.data.LookingForId === 0) {
						this.data.LookingForId = role.subProfile;
					}
					if (role.isComplete === false) {
						if (role.step === Steps.Lookingfor) {
							// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
							this.switchAddProfile(1, 0, role.subProfile, role.isComplete);
							this.behaviourSubjectService.IsProfileFor(false);
							localStorage.removeItem('isProfileinfo');
							this.router.navigateByUrl(`auth/lookingfor/startup`);
						} else if (role.step === Steps.Profile) {
							// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
							this.switchAddProfile(1, 0, role.subProfile, role.isComplete);
							this.router.navigate(['auth/registerprofile']);
						}
					} else {
						this.getSetService.SetRoleData(this.data);
						this.behaviourSubjectService.currentRole(role);
						this.getSetService.SetRole(role);
						// this.switchAddProfile(1, 0, role.subProfile, role.isComplete);
						this.switchAddProfile(
							0,
							role.userProfileId,
							role.subProfile,
							role.isComplete
						);
					}
				}
				break;

			case 2:
				if (role.profile === 0) {
					this.behaviourSubjectService.currentIncompleteId(1);
					sessionStorage.setItem('RoleId', role.value);
					sessionStorage.setItem('LookingForId', '1');
					// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
					this.switchAddProfile(2, 0, role.subProfile, role.isComplete);
					this.behaviourSubjectService.updateSwitch(true);

					if (role.step === Steps.Lookingfor) {
						this.behaviourSubjectService.IsProfileFor(false);
						localStorage.removeItem('isProfileinfo');
						this.router.navigateByUrl(`auth/lookingfor/Investor`);
					} else if (role.step === Steps.Profile) {
						this.router.navigate(['auth/registerprofile']);
					}
				} else {
					this.data.RoleId = role.value;
					if (this.data.LookingForId === 0) {
						this.data.LookingForId = role.subProfile;
						sessionStorage.setItem('LookingForId', role.subProfile);
					}
					if (role.isComplete === false) {
						if (role.step === Steps.Lookingfor) {
							// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
							this.switchAddProfile(2, 0, role.subProfile, role.isComplete);
							this.behaviourSubjectService.IsProfileFor(false);
							localStorage.removeItem('isProfileinfo');
							this.router.navigateByUrl(`auth/lookingfor/Investor`);
						} else if (role.step === Steps.Profile) {
							// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
							this.switchAddProfile(2, 0, role.subProfile, role.isComplete);
							this.router.navigate(['auth/registerprofile']);
						}
					} else {
						this.getSetService.SetRoleData(this.data);
						this.behaviourSubjectService.currentRole(role);
						this.getSetService.SetRole(role);
						// this.switchAddProfile(2, 0, role.subProfile, role.isComplete);
						this.switchAddProfile(
							0,
							role.userProfileId,
							role.subProfile,
							role.isComplete
						);
					}
				}
				break;

			case 3:
				if (role.profile === 0) {
					this.behaviourSubjectService.currentIncompleteId(1);
					sessionStorage.setItem('RoleId', role.value);
					// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
					this.switchAddProfile(3, 0, role.subProfile, role.isComplete);
					this.behaviourSubjectService.updateSwitch(true);

					if (role.step === Steps.Lookingfor) {
						this.behaviourSubjectService.IsProfileFor(false);
						localStorage.removeItem('isProfileinfo');
						this.router.navigateByUrl(`auth/lookingfor/cofounder`);
					} else if (role.step === Steps.Profile) {
						this.router.navigate(['auth/registerprofile']);
					}
				} else {
					this.data.RoleId = role.value;
					if (this.data.LookingForId === 0) {
						this.data.LookingForId = role.subProfile;
					}
					if (role.isComplete === false) {
						if (role.step === Steps.Lookingfor) {
							// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
							this.switchAddProfile(3, 0, role.subProfile, role.isComplete);
							this.behaviourSubjectService.IsProfileFor(false);
							localStorage.removeItem('isProfileinfo');
							this.router.navigateByUrl(`auth/lookingfor/cofounder`);
						} else if (role.step === Steps.Profile) {
							// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
							this.switchAddProfile(3, 0, role.subProfile, role.isComplete);
							this.router.navigate(['auth/registerprofile']);
						}
					} else {
						this.getSetService.SetRoleData(this.data);
						this.behaviourSubjectService.currentRole(role);
						this.getSetService.SetRole(role);
						// this.switchAddProfile(3, 0, role.subProfile, role.isComplete);
						this.switchAddProfile(
							0,
							role.userProfileId,
							role.subProfile,
							role.isComplete
						);
					}
				}
				break;

			case 4:
				if (role.profile === 0) {
					this.behaviourSubjectService.currentIncompleteId(7);
					sessionStorage.setItem('RoleIdcurrentRole', role.value);
					// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
					this.switchAddProfile(4, 0, role.subProfile, role.isComplete);
					if (role.step === Steps.Lookingfor) {
						this.behaviourSubjectService.IsProfileFor(false);
						localStorage.removeItem('isProfileinfo');
						this.router.navigateByUrl(`auth/lookingfor/mgf`);
					} else if (role.step === Steps.Profile) {
						this.router.navigate(['auth/registerprofile']);
					}
				} else {
					this.data.RoleId = role.value;
					if (this.data.LookingForId === 0) {
						this.data.LookingForId = role.subProfile;
					}
					if (role.isComplete === false) {
						if (role.step === Steps.Lookingfor) {
							// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
							this.switchAddProfile(4, 0, role.subProfile, role.isComplete);
							this.behaviourSubjectService.IsProfileFor(false);
							localStorage.removeItem('isProfileinfo');
							this.router.navigateByUrl(`auth/lookingfor/mgf`);
						} else if (role.step === Steps.Profile) {
							// this.switchAddProfile(0, role.userProfileId, role.subProfile, role.isComplete);
							this.switchAddProfile(4, 0, role.subProfile, role.isComplete);
							this.router.navigate(['auth/registerprofile']);
						}
					} else {
						this.getSetService.SetRoleData(this.data);
						this.behaviourSubjectService.currentRole(role);
						this.getSetService.SetRole(role);
						// this.switchAddProfile(4, 0, role.subProfile, role.isComplete);
						this.switchAddProfile(
							0,
							role.userProfileId,
							role.subProfile,
							role.isComplete
						);
					}
				}
				break;
		}

		this.getUserProfileInfo();
	}

	/**
	 * This function is called when user click on role dropdown to change role.
	 * If multiple role added, user clicks on dropdown to get subtoken
	 * and corresponding sutoken will sent to API
	 * @param profileId
	 * @param userProfileId
	 * @param subProfile
	 * @param isComplete
	 */
	switchAddProfile(
		profileId: number,
		userProfileId: number,
		subProfile?: number,
		isComplete?: boolean
	) {
		const data: ItokenForSwitchProfile = {
			profile: profileId,
			userProfileId: userProfileId,
		};
		const url = `${environment.ApiUrl}/api/User/Switch`;
		this.headerService
			.switchAddProfile(url, data)
			.subscribe((response: ResponseModel<LoginResponseModel>) => {
				if (response.status === HttpStatusCode.OK) {
					if (data.profile === 0 && subProfile !== 0 && isComplete === true) {
						// const info = this.getSetService.getCurrentUserInfo();
						// info.token = this.getSetService.getSubtoken();
						// this.getSetService.SetUser(info);
						// sessionStorage.removeItem('subtoken');
						localStorage.removeItem('User');
						this.getSetService.SetUser(response.data);
						this.getSetService.setmainToken(response.data.token);
						this.getSetService.setSubToken(response.data.token);
						this.getSetService.setmainProfileId(response.data.profileId);
						// this.toastrService.success('Profile Switch');
						this.behaviourSubjectService.currentIsRoleData(true);
					} else {
						this.getSetService.setSubToken(response.data.token);
						// localStorage.removeItem('User');
						this.getSetService.SetUser(response.data);
						// sessionStorage.setItem('subtoken', response.data.token)
						// this.toastrService.success('New Profile Stored');
					}

					this.UserInfo = this.getSetService.getCurrentUserInfo();
					if (this.UserInfo.profileId === 1) {
						this.placeholder = 'Search for Investor, Co-founder, and Buyer';
					} else if (
						this.UserInfo.profileId === 2 ||
						this.UserInfo.profileId === 3
					) {
						this.placeholder = 'Search for Startups';
					} else if (this.UserInfo.profileId === 4) {
						this.placeholder = 'Search for Manufacturing Buisness';
					}
					this.behaviourSubjectService.currentUserList(true);
				}
			});
	}

	/**
	 * Get login user notification list to show mini-notification list.
	 */
	getUserNotificationList() {
		// document.getElementById('skipSwitchp').click();
		if (localStorage.getItem('User') !== null) {
			const url = `${environment.ApiUrl}/api/Notification/GetNotification?IsUnseen=false`;
			this.headerService
				.getNotificationList(url)
				.subscribe((response: ResponseModel<INotification[]>) => {
					if (response.status === HttpStatusCode.OK) {
						this.notificationList = response.data;
						this.getNotificationCount();

					}
					if (response.status === 204) {
						this.notificationList = [];
					}
				});
		}
	}

	DeactivateProfile(): void {
		alert("Deactive Profile");
	}

	OpenKnowledgecenter() {
		this.router.navigate(['home/knowledgecenter']);
	}

	filterSearch(str: string) {
		if (str === '') {
			// tslint:disable-next-line: ban
			$('#searchbox').css('display', 'none');
		} else {
			// document.getElementById('searchCard').style.display = 'none';
			// tslint:disable-next-line: ban
			$('#searchbox').css('display', 'block');
			this.index = 0;
			this.searchUserList = this.copySearchUserList.filter((item) => {
				return String(item.fullName)
					.toLowerCase()
					.startsWith(str.toLowerCase().trim());
			});

			this.roleFilterArr = this.searchUserList;
		}
	}

	navigateProfile(user) {
		// document.getElementById('searchinput').innerText = user.fullName;
		this.searchquery = user.fullName;
		this.router.navigate(['/home/profile', user.userProfileId, user.slug ? user.slug : '']);
	}

	highlight(user) {
		return user.fullName.replace(
			new RegExp(this.searchquery, 'gi'),
			(match) => {
				return '<span class="highlightText">' + match + '</span>';
			}
		);
	}

	// highlighListElement(event) {
	// 	try {
	// 		if (event.keyCode === 40) {
	// 			if (this.searchUserList.length - 1 !== this.index) {
	// 				if (this.index !== 0) {
	// 					document.getElementById(`list${this.index - 1}`).className = '';
	// 				}
	// 				document.getElementById(`list${this.index}`).className = 'hightlightList';
	// 				// this.searchquery = document.getElementById(`list${0}`).innerText;
	// 				this.index++;
	// 			} else {
	// 				console.log('err')
	// 			}
	// 		}

	// 		if (event.keyCode === 38) {
	//
	// 			if(this.searchUserList.length >= this.index){
	// 				if(this.index >= 0){
	// 					document.getElementById(`list${this.index + 1}`).className = '';
	// 				}
	// 				this.index--;
	// 				document.getElementById(`list${this.index}`).className = 'hightlightList';
	// 				this.searchquery = document.getElementById(`list${0}`).innerText;
	// 			}

	// 		}

	// 		console.log(this.index);
	// 	} catch (error) {
	// 		console.log(error)
	// 	}

	// }

	searchResult(Div: any) {
		// tslint:disable-next-line: ban
		$('#searchCard').css('display', 'block');
	}

	@HostListener('click')
	clickInside() {
		this.wasInside = true;
	}

	@HostListener('document:click')
	clickout() {
		if (!this.wasInside) {
			// tslint:disable-next-line: ban
			$('#searchCard').css('display', 'none');
		}
		this.wasInside = false;
	}

	someEvent(event: any) {
		if (event.keyCode === 13) {
			if(!this.searchquery ||  this.searchquery.match(/^ *$/) !== null ){
				alert('Please Enter Search Text ')

			}
			else{
			document.getElementById('searchbox').style.display = 'none';
			this.router.navigate(['/search', this.searchquery]);
			}

		}
	}
	opensearch() {
		
		if(!this.IsSwitchProfile){
			if(!this.searchquery ||  this.searchquery.match(/^ *$/) !== null ){
				alert('Please Enter Search Text ')
			}
			else{
				this.router.navigate(['/search', this.searchquery]);
			}
		}else{
			this.LogOut();
		}
		
	}

	selectRole(roleId) {
		switch (roleId) {
			case 1:
				this.startupSearchId === 0
					? (this.startupSearchId = 1)
					: (this.startupSearchId = 0);
				if (this.startupSearchId === 1) {
					if (!this.RoleIds.includes(1)) this.RoleIds.splice(0, 1, 1);
				} else {
					this.RoleIds.splice(0, 1, 0);
				}
				break;

			case 2:
				this.investorSearchId === 0
					? (this.investorSearchId = 1)
					: (this.investorSearchId = 0);
				if (this.investorSearchId === 1) {
					if (!this.RoleIds.includes(2)) this.RoleIds.splice(1, 1, 2);
				} else {
					this.RoleIds.splice(1, 1, 0);
				}
				break;

			case 3:
				this.cofounderSearchId === 0
					? (this.cofounderSearchId = 1)
					: (this.cofounderSearchId = 0);
				if (this.cofounderSearchId === 1) {
					if (!this.RoleIds.includes(3)) this.RoleIds.splice(2, 1, 3);
				} else {
					this.RoleIds.splice(2, 1, 0);
				}
				break;

			case 4:
				this.mfgSearchId === 0
					? (this.mfgSearchId = 1)
					: (this.mfgSearchId = 0);
				if (this.mfgSearchId === 1) {
					if (!this.RoleIds.includes(4)) this.RoleIds.splice(3, 1, 4);
				} else {
					this.RoleIds.splice(3, 1, 0);
				}
				break;
		}

		if (
			this.RoleIds[0] === 0 &&
			this.RoleIds[1] === 0 &&
			this.RoleIds[2] === 0 &&
			this.RoleIds[3] === 0
		) {
			this.isRoleEmpty = false;
		} else {
			this.isRoleEmpty = true;
		}

		this.searchUserList = this.roleFilterArr.filter((item) => {
			return this.RoleIds.length !== 0 && this.isRoleEmpty === true
				? this.RoleIds.includes(item.profileId)
				: 1;
		});
	}

	openApproveRequestPopup(msg) {
		const dialogRef = this.dialog.open(ApprovenotificationComponent, {
			width: '480px',
			height: '180px',
			disableClose: true,
			panelClass: 'custom-modalbox',
			data: msg, // for send data for other component//
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.getUserNotificationList();
			// this.getNotificationCount();

		});
	}

	removeNotification(msg) {
		const dialogRef = this.dialog.open(RemovenotificationComponent, {
			width: '480px',
			height: '180px',
			disableClose: true,
			panelClass: 'custom-modalbox',
			data: msg, // for send data for other component//
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.getUserNotificationList();
			// this.getNotificationCount();
		});
	}

	rejectApproveRequestPopup(msg) {
		const dialogRef = this.dialog.open(RejectRequestPopupComponent, {
			width: '480px',
			height: '180px',
			disableClose: true,
			panelClass: 'custom-modalbox',
			data: msg, // for send data for other component//
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.getNotificationCount();

		});
	}

	getNotificationCount() {

		const url = `${environment.ApiUrl}/api/Notification/GetUnseenNotificationCount`;
		this.commonService.get(url).subscribe((response) => {
			this.count = response.data;
		});
	}

	// private getSocketNotifications() {
	// 	this.signalRService.ReceiveNotification.subscribe((notification: any) => {
	// 		// console.log(notification);
	// 		this.pushNotificationService.notify(notification.message);
	// 		this.notificationList.unshift(notification);
	// 	});
	// }
}
