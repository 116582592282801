
import * as io from 'socket.io-client';
// import { Observable } from 'rxjs/Observable';
import { Injectable, EventEmitter } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { GetSetService } from './get-set.service';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  public notificationLists: AngularFireList<any>;
  notification = new EventEmitter<any>();
  userId: any;

  constructor(private authService: AuthService,
    private firebase: AngularFireDatabase,
    private af: AngularFireAuth,
    private getSetService: GetSetService
  ) {
    this.SendPushNotification({});
  }

  // getNotification(id) {
  //   this.notificationLists = this.firebase.list('Notification/1', ref => ref.orderByChild('status').equalTo('unseen'));
  //   return this.notificationLists;
  // }

  setNotification(Receiver, msg) {
    
    const profileInfo = this.getSetService.getCurrentUserInfo();

    const sender = profileInfo.userId;
    const Type = 1;
    const arr: any[] = [];
    const users = {
      message: msg,
      Type: Type,
      status: 'unseen',
    };
    let sr: string;
    sr = Receiver.toString();
    this.getAllNotification(sr);
    const data = this.notificationLists.push({
      message: msg,
      Type: Type,
      status: 'unseen',
    });
    
    //this.notification.emit(data);
  }

  getAllNotification(sr) {
    
    this.notificationLists = this.firebase.list('Notification/' + sr);
    return (this.notificationLists.snapshotChanges());
  }
  // push user socket
  public SendPushNotification(data: any) {

  }
  // Send Push Notification to multiple user
  public SendPushToMultipleUserNotification(data: any) {

  }

}
