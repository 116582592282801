import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UploadpostRoutingModule } from './uploadpost-routing.module';
import { UploadpostComponent } from './uploadpost/uploadpost.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {
// 	MatButtonModule,
// 	MatDialogModule,
// 	MatIconModule,
// 	MatSelectModule,
// 	MAT_DIALOG_DATA,
// 	MatInputModule,
// 	MatFormFieldModule
// } from '@angular/material';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
	declarations: [UploadpostComponent],
	imports: [
		CommonModule,
		UploadpostRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatDialogModule,
		MatIconModule,
		MatSelectModule,
		MatInputModule,
		MatFormFieldModule,
		NgxSkeletonLoaderModule
	],
	exports: [UploadpostComponent],
	entryComponents: [UploadpostComponent],
	providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }]
})
export class UploadpostModule {}
