import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KnowledgecenterRoutingModule } from './knowledgecenter-routing.module';
import { KnowledgecenterComponent } from './knowledgecenter/knowledgecenter.component';
import { KnowledgecenterdetailComponent } from './knowledgecenterdetail/knowledgecenterdetail.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [KnowledgecenterComponent, KnowledgecenterdetailComponent],
  imports: [
    CommonModule,
    KnowledgecenterRoutingModule,
    NgxSkeletonLoaderModule,
    SharedModule
  ],
  exports: [KnowledgecenterdetailComponent, KnowledgecenterComponent]
})
export class KnowledgecenterModule { }
