import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ResponseModel, HttpStatusCode } from '../Responsemodel/responsemodel';
import { EncrDecrService } from './encr-decr.service';
import {
	Register,
	RegisterProfile,
} from 'src/app/auth/register/register.model';
import { LoginResponseModel } from 'src/app/auth/login/login.model';
import { BehavioursubService } from './behavioursub.service';
// import { SignalRService } from '../../../signal-r.service';
import { subtokenHeader } from 'src/app/shared/Helper/Interceptor/HeaderInterceptor';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	@Output() getLoggedIn: EventEmitter<any> = new EventEmitter();
	redirectUrl: string;
	RoleId: number = 0;
	LookingForId = 0;

	constructor(
		private http: HttpClient,
		private encrDecrService: EncrDecrService,
		private router: Router,
		private behavioursubservice: BehavioursubService // private signalRService: SignalRService
	) {}

	// Login User

	Login(
		url: string,
		model: any
	): Observable<ResponseModel<LoginResponseModel>> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<LoginResponseModel>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				}
			})
		);
	}

	// Get Role
	GetRole(url: string, model: any) {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}

	GetLookingFor(url: string) {
		return this.http.get(url).pipe(
			map((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// alert(response.statusMessage);
					return response;
				} else {
					return response;
				}
			})
		);
	}

	// Register User

	Register(
		url: string,
		model: Register
	): Observable<ResponseModel<LoginResponseModel>> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<LoginResponseModel>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}

	// ForgotPassword

	ForgotPassWord(url: string, model: any): Observable<any> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<any>) => {
				if (
					response.status === HttpStatusCode.OK ||
					HttpStatusCode.NO_CONTENT
				) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
				} else {
					return response;
				}
			})
		);
	}

	// Check user login status
	isLoggedIn() {
		if (localStorage.getItem('User') !== null) {
			const info = this.encrDecrService.decrypt(localStorage.getItem('User'));
			const expTime = new Date(info.validTill);
			const currentDate = new Date(Date.now());

			if (expTime > currentDate) {
				return true;
			}
		}
		localStorage.removeItem('User');
		this.router.navigateByUrl(`/`);
		return false;
	}

	// logout user
	logout() {
		localStorage.clear();
		sessionStorage.clear();
		this.behavioursubservice.Updateloginuser(false);
		this.router.navigate(['auth/login']);
	}

	// Get Quize questions
	GetQuizequestions(url: string) {
		return this.http.get(url).pipe(
			map((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}

	// External login User api
	ExternalLogin(url: string, model: any): Observable<any> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<any>) => {
				if (
					response.status === HttpStatusCode.OK ||
					HttpStatusCode.NO_CONTENT
				) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
				} else {
					return response;
				}
			})
		);
	}

	// Get Quize questions
	GetUserDetails(url: string) {
		const headers = new HttpHeaders().set(subtokenHeader, '');
		return this.http.get(url, { headers }).pipe(
			map((response: ResponseModel<RegisterProfile>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}

	// verify otp

	verifyOtp(
		url: string,
		model: Register
	): Observable<ResponseModel<LoginResponseModel>> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<LoginResponseModel>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}
}
