import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewAllComponent } from './dashboard/news-feed/view-all/view-all.component';
// import { ChattingFormComponent } from '../../chatwithsignalr/chatting-form/chatting-form.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'viewall/:id',
    component: ViewAllComponent
  },
  // {
  //   path:'chat',
  //   component:ChattingFormComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
