import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { ExternalLoginUserDetails } from 'src/app/auth/login/login.model';
import { NgxLinkedinService } from 'ngx-linkedin';



@Injectable({
  providedIn: 'root'
})
export class ExternalAuthService {

  SocialUser = new EventEmitter<ExternalLoginUserDetails>();
  linkDinSocialUser = new EventEmitter<any>();


  constructor(
    private authService: AuthService,
     private ngxLinkedinService: NgxLinkedinService
  ) { }

   public isAuthorized$ = this.ngxLinkedinService.isAuthorized();


  loginWithLinkdin() {
    this.ngxLinkedinService.signIn().subscribe(user => {
      console.log('signIn', user);
      return user;
    });
    console.log('LINKDIN LOGIN::'+this.isAuthorized$);

  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialusers => {
      this.SocialUser.emit(socialusers);
    });
  }

  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }

  signOut(): void {
    this.authService.signOut();
  }
}
