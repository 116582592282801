import { Injectable } from '@angular/core';
import { PushNotificationsService } from 'ng-push';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private pushNotifications: PushNotificationsService) {

  }

  getPermission() {
    this.pushNotifications.requestPermission();
  }


  notify(data) {
    const options = {
      body: data,
      icon: 'assets/images/22.jpg'
    };

    this.pushNotifications.create('Notification', options).subscribe(
      res => {
        // console.log(res);
      } ,
      err => {
        // console.log(err);
      }
    );
  }
}
