import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import {
	ResponseModel,
	HttpStatusCode
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { ICuratedResult } from '../left-profile-curated/left-profile-curated.model';
import { map } from 'rxjs/operators';
import {
	INewsFeedResults,
	IRequestContactFromNewsFeed
} from './newsfeed.model';
import { IFilterSearch } from '../../dashboard-search/startup-search/startup-search.model';

@Injectable({
	providedIn: 'root'
})
export class NewsfeedService {
	constructor(
		private http: HttpClient
	) { }

	getNewsFeedResults(
		url: string,
		model: IFilterSearch
	): Observable<ResponseModel<INewsFeedResults[]>> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<INewsFeedResults[]>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// //this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}

	requestContact(
		url: string,
		model: IRequestContactFromNewsFeed
	): Observable<ResponseModel<IRequestContactFromNewsFeed>> {
		const body = JSON.stringify(model);
		return this.http.post(url, body).pipe(
			map((response: ResponseModel<IRequestContactFromNewsFeed>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}

	getRecomendedResults(
		url: string
	): Observable<ResponseModel<ICuratedResult[]>> {
		return this.http.get(url).pipe(
			map((response: ResponseModel<ICuratedResult[]>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}

	getNewOnResults(url: string): Observable<ResponseModel<ICuratedResult[]>> {
		return this.http.get(url).pipe(
			map((response: ResponseModel<ICuratedResult[]>) => {
				if (response.status === HttpStatusCode.OK) {
					return response;
				} else if (response.status === HttpStatusCode.UNAUTHORIZED) {
					// this.toastrService.warning(response.statusMessage, '');
					return response;
				} else {
					return response;
				}
			})
		);
	}
}
