import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormBuilder,
	Validators,
} from '@angular/forms';

import { CommonService } from 'src/app/shared/Helper/services/common.service';
// import { HttpEventType, HttpEvent } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehavioursubService } from 'src/app/shared/Helper/services/behavioursub.service';
import { GetSetService } from 'src/app/shared/Helper/services/get-set.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	ResponseModel,
	HttpStatusCode,
} from 'src/app/shared/Helper/Responsemodel/responsemodel';
import { Subscription } from 'rxjs';
import { EncrDecrService } from 'src/app/shared/Helper/services/encr-decr.service';
// import { element } from 'protractor';

@Component({
	selector: 'app-uploadpost',
	templateUrl: './uploadpost.component.html',
	styleUrls: ['./uploadpost.component.css'],
})
export class UploadpostComponent implements OnInit, OnDestroy {
	public subscriptions$: Subscription[] = [];

	myControl = new FormControl();
	postinfo: any;
	style: any;
	scrollHeight: any;
	filenamelist: any = [];
	FormFileList: any = [];
	uploadForm: FormGroup;
	AddnewtagsForm: FormGroup;
	submitForm = false;
	userInfo: any;
	selectedvalue = false;
	uploadedPercentage: number;
	uploadedProgress = 0;
	UserId: any = 0;
	postsdetails: any;
	subdomainsid: any;
	showtag = false;
	showbtn = true;
	InterestCount: any;
	interests = [];
	show: boolean;
	searchdetails: any;
	displayerror = false;
	subdomainid: any;
	showmsg: boolean;
	AllDomains: any;
	addtag: any = [];
	name: any;
	removed: boolean;
	IntrestList: any = [];
	AllDomains1: any;
	Allsubdomains: any = [];
	AllDomains2: any;
	selectposttag = false;
	profiledetails: any;
	showerrormesg: boolean;
	Isedit = false;
	isEdit = false;
	isDraft = false;
	i: number;
	addnewtag = false;
	AboutSuperCategory: any;

	deisplaytagdropdown = false;
	uploaddoclist: any = [];
	uploadFileList: any = [];
	showdoc = false;
	showfund = false;
	Addedusertag: any = [];
	userselectedtags: any = [];
	UserInfo: any;
	User: any;
	closemodule: boolean = false;
	selected: any;
	postInfo: any;
	isDelete = false;
	profileInfo: any;
	postMessage = '';
	listTag = [];
	copyListTag = [];
	tagString: string;
	isRecLoading = false;
	enAddBtn = false;
	tagAddMsg = false;

	constructor(
		public dialogRef: MatDialogRef<UploadpostComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private formbuilder: FormBuilder,
		private getSetService: GetSetService,
		private behaviourSubjectService: BehavioursubService,
		private commonService: CommonService,
		private encrDecrService: EncrDecrService
	) {
		if (data !== null) {
			this.postInfo = data.postinfo;
		}
		// console.log(this.postInfo);
	}

	ngOnInit() {
		this.getUserProfileInfo();
		this.getTagList();
		this.InitializeForm();
		if (this.postInfo !== null && this.postInfo !== undefined) {
			this.uploadForm.controls.Description.setValue(this.postInfo.title);

			if (this.postInfo.imagePath !== null) {
				const obj = {
					fileurl: this.postInfo.imagePath,
				};
				this.filenamelist.push(obj);
			}
		}
		this.subscriptions$.push(
			this.behaviourSubjectService.getUserInfo$.subscribe((data) => {
				if (data.hasOwnProperty('fullName')) {
					this.UserInfo = data;
				} else {
					this.UserInfo = this.getSetService.getCurrentUserInfo();
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}

	InitializeForm() {
		this.uploadForm = this.formbuilder.group({
			Description: new FormControl('', [Validators.required]),
			UserProfileId: new FormControl(''),
		});
	}

	getUserProfileInfo() {
		this.isRecLoading = true;
		// const url = `${environment.ApiUrl}/api/User/Myprofie`;
		// this.commonService.get(url).subscribe((response: ResponseModel<any>) => {
		// 	if (response.status === HttpStatusCode.OK) {
		// 		this.isRecLoading = false;
		// 		this.profileInfo = response.data;
		// 	}
		// });


		var delay;
		delay = setInterval(() => {
			if (this.profileInfo == null) {
				switch (localStorage.getItem('profileId')) {
					case '1':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('1'));
						break;

					case '2':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('2'));
						break;

					case '3':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('3'));
						break;

					case '4':
						this.profileInfo = this.encrDecrService.decrypt(localStorage.getItem('4'));
						break;

					default:
						break;
				}
			} else {
				clearInterval(delay);
				this.isRecLoading = false;
			}
		}, 1000);
	}

	// Upload post
	formSubmit() {
		// if (this.uploadForm.value.Description.trim() !== '') {
		this.User = this.getSetService.getCurrentUserInfo();
		this.UserId = this.User.userProfileId;
		this.submitForm = true;
		if (
			this.uploadForm.value.Description.trim() !== '' ||
			this.FormFileList.length !== 0
		) {
			this.showbtn = false;
			this.showerrormesg = false;
			const FD: FormData = new FormData();
			FD.append('UserProfileId', this.UserId);
			FD.append(
				'PostId',
				this.postInfo !== null && this.postInfo !== undefined
					? this.postInfo.id
					: 0
			);
			FD.append(
				'DeleteImage',
				String(this.isDelete) && this.filenamelist.length === 0
					? String(this.isDelete)
					: 'false'
			);
			FD.append('Title', this.uploadForm.value.Description);
			// FD.append('Intrests', this.interests[0]);

			this.interests.forEach((tag, i) => {
				FD.append(`Intrests[${i}]`, tag.id);
			});

			if (this.FormFileList.length !== 0) {
				const files: FileList = this.FormFileList;
				// tslint:disable-next-line:prefer-for-of
				for (let i = 0; i < files.length; i++) {
					const file: File = files[i];
					FD.append('File', file, file.name);
				}
			}

			FD.forEach((value, key) => {
				// console.log(key + ' ' + value);
			});
			let url = environment.ApiUrl + '/api/Post/UploadPost';
			if (this.postInfo !== null && this.postInfo !== undefined) {
				url = environment.ApiUrl + '/api/Post/EditPost';
			}
			this.commonService.upload(url, FD).subscribe(
				(response) => {
					if (response.status === 200) {
						this.FormFileList = [];
						this.filenamelist = [];
						this.uploadForm.reset();
						// document.getElementById('uploadpostmodal').click();
						this.onNoClick();
						this.behaviourSubjectService.currentMyPostId(
							this.postInfo !== null && this.postInfo !== undefined
								? this.postInfo.id
								: 0
						);
						this.behaviourSubjectService.currentPostList(true);
						document.getElementById('openSave').click();
					}
					setTimeout(() => {
						document.getElementById('closeuppop').click();
					}, 3000);
				},
				(error) => {
					// console.log(error);
					alert('somthing Went Wrong');
					this.uploadForm.reset();
					this.FormFileList = [];
					this.filenamelist = [];
					this.closeuploadpost();
					// document.getElementById('uploadpostmodal').click();
					// this.closemodule = true;
					// this.dialogRef.close();
					this.onNoClick();
					this.behaviourSubjectService.currentMyPostId(
						this.postInfo !== null && this.postInfo !== undefined
							? this.postInfo.id
							: 0
					);
					this.behaviourSubjectService.currentPostList(true);
					// document.getElementById('openSave').click();
				}
			);
		}
		this.submitForm = false;
		if (
			this.uploadForm.value.Description.trim() === '' &&
			this.FormFileList.length === 0
		) {
			this.showerrormesg = true;
		}
		// } else {
		// 	this.showerrormesg = true;
		// }
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
	// Select images to post upload
	onSelectFile(e: any) {
		this.filenamelist = [];
		if (e.target.files && e.target.files[0]) {
			const filesAmount = e.target.files.length;
			for (let i = 0; i < filesAmount; i++) {
				const reader = new FileReader();
				const filetype = e.target.files[i].type.split('/')[0];
				const filename = e.target.files[i].name;
				reader.onload = (event: any) => {
					const fileobj: object = {
						filetype,
						fileurl: event.target.result,
						filename,
					};
					this.filenamelist.push(fileobj);
				};
				reader.readAsDataURL(e.target.files[i]);
				this.FormFileList.splice(0, 1);
				this.FormFileList.push(e.target.files[i]);
			}
		}

		this.removeerrormsg();
	}

	// delete a selected file
	deletefile(index: number, url: string) {
		if (index >= 0) {
			this.FormFileList.splice(index, 1);
			this.filenamelist.splice(index, 1);
			this.isDelete = true;
		}
		this.filenamelist = [];

		// const pos = url.slice(0, 10);
		// if ((pos === 'data:image') || (pos === 'data:video')) {
		//   if (index !== -1) {
		//     this.FormFileList.splice(index, 1);
		//     this.filenamelist.splice(index, 1);
		//   }
		// } else {
		//   const posi = url.search('upload');
		//   const Url = url.substring(posi);
		//   const ApiPath = environment.ApiUrl + 'post/removepost';
		//   const obj = {
		//     PostId: this.postinfo.Id,
		//     FilePath: Url
		//   };

		//   this.commonService.post(ApiPath, obj).subscribe(
		//     data => {
		//       if (data.statusCode === 200) {
		//         if (index !== -1) {
		//           this.filenamelist.splice(index, 1);
		//         }
		//       }
		//     });
		// }
	}

	// Open upload post modal
	openuploadpostmodal() {
		document.getElementById('uploadpostmodal').click();
	}

	clsemodel() {
		document.getElementById('uploadpostmodal').click();
	}

	// Auto increase description textarea upload modal
	KeyPress() {
		this.showerrormesg = false;
		const tx = document.getElementsByTagName('textarea');
		// tslint:disable-next-line:prefer-for-of
		for (let i = 0; i < tx.length; i++) {
			tx[i].addEventListener('input', this.OnInput, false);
		}
	}

	OnInput() {
		this.style.height = 'auto';
		this.style.height = this.scrollHeight + 'px';
		this.style.overflow = 'hidden';
	}
	removeerrormsg() {
		this.showerrormesg = false;
	}

	autoresizeselect(Selectedvalue: any) {
		if (Selectedvalue === '2') {
			this.selectedvalue = true;
		} else {
			this.selectedvalue = false;
		}
	}
	// Display subdomain tag
	openposttagmodal() {
		// if (this.uploadForm.controls.Description.valid) {

		//   this.uploadForm.controls.Title.clearValidators();
		//   this.uploadForm.controls.Title.updateValueAndValidity();
		// }
		// if (this.uploadForm.controls.Title.valid) {
		//   this.uploadForm.controls.Description.clearValidators();
		//   this.uploadForm.controls.Description.updateValueAndValidity();
		// }

		// if (this.FormFileList.length !== 0 || this.filenamelist.length !== 0) {
		//   this.uploadForm.controls.Description.clearValidators();
		//   this.uploadForm.controls.Description.updateValueAndValidity();
		//   // this.uploadForm.controls.Title.clearValidators();
		//   // this.uploadForm.controls.Title.updateValueAndValidity();
		//   this.showerrormesg = false;

		// }

		if (this.uploadForm.valid) {
			this.showerrormesg = false;
		} else {
			this.showerrormesg = true;
		}

		if (
			this.uploadForm.valid &&
			(this.FormFileList.length < 5 || this.FormFileList.length === 5)
		) {
			this.showerrormesg = false;
			this.showtag = true;
			this.showfund = false;
		}
	}

	openfundmodel() {
		if (this.uploadForm.controls.AllowFund.value === true) {
			this.showfund = true;
			// this.showtag = false;
			this.addnewtag = false;
		} else {
			this.openposttagmodal();
		}
	}

	addtagtrue() {
		if (this.IntrestList.length === 0) {
			this.selectposttag = true;
		}
	}

	// Check Extension of images
	chkimagesrcExt(filepath: any) {
		const filename = filepath.split('\\').pop().split('/').pop();
		const lastIndex = filename.lastIndexOf('.');
		if (lastIndex < 1) {
			return '';
		}
		return filename;
	}

	// For remove document
	RemoveDoc(index, producttype) {
		this.uploaddoclist.splice(index, 1);
	}

	// Close upload post model
	closeuploadpost() {
		this.uploadForm.reset();
		// this.AddnewtagsForm.reset();
		// document.getElementById('closeupload').click();
		this.onNoClick();
		this.showtag = false;
		this.showfund = false;
		this.uploaddoclist = [];
		this.FormFileList = [];
		this.AllDomains1 = [];
		this.filenamelist = [];
		this.FormFileList = [];
		this.userselectedtags = [];
		this.Addedusertag = [];
		document.getElementById('hdnuploadpostrefresh').click();
	}

	select(id: any) {
		this.selected = id;
	}

	getTagList() {
		const url = `${environment.ApiUrl}/api/Post/listTags?IsAdmin=false`;
		this.commonService.get(url).subscribe((response: ResponseModel<any>) => {
			if (response.status === HttpStatusCode.OK) {
				// this.listTag = response.data;
				(response.data as Array<any>).forEach((element1) => {
					element1['isSelect'] = false;
					this.copyListTag.push(element1);
					this.listTag.push(element1);
				});

				if (this.postInfo !== undefined) {
					const temInterest = this.postInfo.intrests;
					if (temInterest !== null) {
						this.listTag.forEach((element3) => {
							(temInterest as Array<any>).forEach((item) => {
								if (item === element3.id) {
									element3.isSelect = true;
									const obj = {
										id: item,
									};
									this.interests.push(obj);
									this.Addedusertag.push(element3);
								}
							});
						});
					}
				}
				// console.log(this.listTag);
			}
		});
	}

	selectTag(tag, id) {
		tag.isSelect = !tag.isSelect;
		if (tag.isSelect === true) {
			this.interests.push(tag);
			this.Addedusertag.push(tag);
		} else {
			// this.interests.splice(index, 1);
			this.interests.forEach((ele, index) => {
				if (ele.id === id) {
					this.interests.splice(index, 1);
					this.Addedusertag.splice(index, 1);
				}
			});
		}

		// console.log(this.interests);
	}

	filter(str: string) {
		if (str.trim() !== '') {
			this.tagString = str;
			this.listTag = this.copyListTag.filter((item) => {
				return String(item.intrest).toLowerCase().startsWith(str.toLowerCase());
			});

			if (this.listTag.length === 0) {
				this.enAddBtn = true;
			}
		}
	}

	addTag() {
		if (this.tagString.trim() !== '') {
			const data = {
				id: 0,
				intrest: this.tagString,
				isActive: true,
			};

			const url = `${environment.ApiUrl}/api/Post/UpdateTags`;
			this.commonService
				.post(url, data)
				.subscribe((response: ResponseModel<any>) => {
					if (response.status === HttpStatusCode.OK) {
						// alert('Tag added');
						this.tagAddMsg = true;
						this.enAddBtn = false;
					}
				});
		}
	}

	OpenBox(id: string) {
		// document.getElementById(id);
		(document.getElementById(id) as HTMLInputElement).click();
	}
}
