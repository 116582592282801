import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetSetService } from '../services/get-set.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const subtokenHeader = 'use-sub-token';
export const formDataHeader = 'use-form-data';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
	token: string;
	constructor(private setgetService: GetSetService) {}

	req: HttpRequest<any>;

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		// get token
		// if (sessionStorage.getItem('subtoken') === null) {
		//     this.token = this.setgetService.getAuthKey();
		// } else {
		//     this.token = sessionStorage.getItem('subtoken');
		// }

		if (
			request.headers.has(subtokenHeader) ||
			request.headers.has(formDataHeader)
		) {
			// console.log(' HAS sub token header');
			this.token = sessionStorage.getItem('subtoken');
		} else {
			// console.log('main token == no header');
			// this.token = this.setgetService.getAuthKey();
			this.token = this.setgetService.getmaintoken();
		}


		if (request.headers.has(InterceptorSkipHeader)) {
			// const headers = request.headers.delete(InterceptorSkipHeader);
			this.req = request.clone({
				setHeaders: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${this.token}`,
				},
			});
		}
		if (request.headers.has(formDataHeader)) {
			this.req = request.clone({
				setHeaders: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${this.token}`,
				},
			});
		} else {
			this.req = request.clone({
				setHeaders: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${this.token}`,
				},
			});
		}

		// tslint:disable-next-line: no-console
		// console.log('/n call service' + new Date().toDateString());

		return next.handle(this.req);
	}
}
