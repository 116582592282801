import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ValidationService } from '../Helper/services/validation.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../Helper/services/common.service';
import { ResponseModel } from '../Helper/Responsemodel/responsemodel';

import { AuthService } from '../Helper/services/auth.service';
import { Router } from '@angular/router';
import { BehavioursubService } from '../Helper/services/behavioursub.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  ChangePasswordfrm: FormGroup;
  submitForm: boolean = false;
  oldpassword: AbstractControl;
  newpassword: AbstractControl;
  confirmpassword: AbstractControl;
  old: any;
  new: any;
  error: any;
  msg: string;
  IsSubmit: boolean = false;
  hide = true;
  newhide = true;
  confhide = true;
  statusMessage: boolean =false;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private validate: ValidationService,
    private commonService: CommonService,
    // private toastrService: ToastrService,
    private authservice: AuthService,
    private route: Router,
    private behavioursubservice: BehavioursubService
  ) {


    this.ChangePasswordfrm = this.formBuilder.group({
      OldPassword: ['', [Validators.required, Validators.minLength(6)]],
      NewPassword: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, 
       this.validate.MustMatch('NewPassword', 'ConfirmPassword')
    );

    this.oldpassword = this.ChangePasswordfrm.controls['OldPassword'];
    this.oldpassword.valueChanges.subscribe((pass) => {
      this.old = pass;
    });

    this.newpassword = this.ChangePasswordfrm.controls['NewPassword'];
    this.newpassword.valueChanges.subscribe((password) => {
      this.new = password;
      if (String(this.old).length === String(this.new).length) {
        if (this.old === this.new) {
          this.msg = 'Old Password and New Password Can\'t be Same';
        } else {
          this.msg = '';
        }
      } else {
        this.msg = '';
      }
    });

    this.confirmpassword = this.ChangePasswordfrm.controls['ConfirmPassword'];
    this.confirmpassword.valueChanges.subscribe((password) => {
      if (String(this.new).length === password.length) {
        if (this.new === password) {
          this.error = '';
        } else {
          this.error = 'Password Does Not Match, Please recheck and re-enter.';
        }
      } else {
        if (String(password).length === 0) {
          this.error = '';
        } else {
          this.error = 'Password Does Not Match, Please recheck and re-enter .';
        }
      }
    });
  }

  ngOnInit() {
    if (localStorage.getItem('dashfilter') !== null) {
      localStorage.removeItem('dashfilter');
    }
    if (localStorage.getItem('viewFilter') !== null) {
      localStorage.removeItem('viewFilter');
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.submitForm = true;
    // tslint:disable-next-line: max-line-length
    const url = `${environment.ApiUrl}/api/Auth/ChangePassword?OldPassword=` + this.old + `&NewPassword=` + this.new + `&IsAdmin=false`;
    const data = {
      OldPassword: this.ChangePasswordfrm.value.OldPassword,
      NewPassword: this.ChangePasswordfrm.value.NewPassword,
      IsAdmin: false
    };

    this.commonService.post(url, data).subscribe(
      (Response: ResponseModel<any>) => {
        if (Response.status === 200) {
          // //this.toastrService.success(Response.statusMessage, '');
          this.IsSubmit = true;
          this.submitForm = false;

        } else {
          // this.toastrService.warning(Response.statusMessage, '');
          this.error = 'Old Password Does Not Match, Please recheck and re-enter.';
          this.submitForm = false;

        }
      });
  }

  LogIn() {
    this.authservice.logout();
    this.behavioursubservice.Updateloginuser(false);
    this.behavioursubservice.isOnDashboard(false);
    this.onNoClick();
    this.route.navigate(['auth/login']);
  }

}
