import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationDashboardComponent } from './registration-dashboard.component';
// import { AuthGuard } from 'src/app/shared/Helper/Guard/auth.guard';

const routes: Routes = [
	// {
	// 	path: '',
	// 	redirectTo: '',
	// 	pathMatch: 'full'
	// },
	{
		path: '',
		component: RegistrationDashboardComponent,
	//	canActivate: [AuthGuard]
	}
];


@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class RegistartionDashboardRouteModule { }
