import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { GetSetService } from '../Helper/services/get-set.service';
import { BehavioursubService } from '../Helper/services/behavioursub.service';
import { IFilterSearch } from 'src/app/home/dashboard/dashboard-search/startup-search/startup-search.model';
import { SubHeaderService } from './sub-header.service';
import { environment } from 'src/environments/environment';
import {
	ResponseModel,
	HttpStatusCode
} from '../Helper/Responsemodel/responsemodel';
import { IUserCompleteLookingFor } from './sub-header.model';
import {
	Router
} from '@angular/router';
import { CommonService } from '../Helper/services/common.service';
import { Steps } from '../Helper/Enum/Step';

import { UploadpostComponent } from 'src/app/home/uploadpost/uploadpost/uploadpost.component';
import { MatDialog } from '@angular/material/dialog';
// tslint:disable-next-line: max-line-length
import { LeftProfileCuratedService } from 'src/app/home/dashboard/dashboard/left-profile-curated/left-profile-curated.service';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FiltersModalComponent } from 'src/app/home/dashboard/dashboard/filters-modal/filters-modal.component';

@Component({
	selector: 'app-sub-header',
	templateUrl: './sub-header.component.html',
	styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
	ProfileId: any;
	roleId: number = 0;
	Roles: any[] = [];
	LookingForList: any[] = [];
	lookingId: number = 0;
	UserInfo: any;
	routelink: any;
	userFilter: any;
	isOnMyPost: boolean = false;
	isViewPage: boolean = false;
	isArticlePost: boolean = false;
	DataFilter: any;
	routerLink: any;
	public subscriptions$: Subscription[] = [];

	constructor(
		private getSetService: GetSetService,
		private behaviourSubjectService: BehavioursubService,
		private subHeaderService: SubHeaderService,
		private router: Router,
		public dialog: MatDialog,
		private commonService: CommonService,
		private leftCuratedService: LeftProfileCuratedService
	) {
		this.Roles = [
			{ value: 1, viewValue: 'Startup', lookingFor: [2, 3, 5] },
			{ value: 2, viewValue: 'Investor', lookingFor: [1, 5] },
			{ value: 3, viewValue: 'Co-Founder', lookingFor: [1] },
			{ value: 4, viewValue: 'Manufacturing-Business', lookingFor: [7, 6] }
		];
		this.LookingForList = this.commonService.LookingForList;
	}

	LookingFor: any[] = [];

	data = {
		RoleId: 0,
		LookingForId: 0
	};

	ngOnInit() {
		this.routelink = this.router.url;
		this.subscriptions$.push(
			this.behaviourSubjectService.getMyPostPageStatus$.subscribe(flag => {
				if (flag) {
					this.isOnMyPost = flag;
				}
			}));
		this.subscriptions$.push(
			this.behaviourSubjectService.getArticlePost$.subscribe(flag => {
				if (flag) {
					this.isArticlePost = flag;
				}
			}));

		this.subscriptions$.push(
			this.behaviourSubjectService.getViewPageStatus$.subscribe(flag => {
				if (flag) {
					this.isViewPage = flag;
				}
			}));
		this.UserInfo = this.getSetService.getCurrentUserInfo();
		this.ProfileId = this.getSetService.GetProfileId();
		if (localStorage.getItem('roleData') !== null) {
			this.lookingId = this.getSetService.getRoleData().LookingForId;
		}

		this.subscriptions$.push(
			this.behaviourSubjectService.getIsRoleData$.subscribe(flag => {
				this.userFilter = this.getSetService.getRoleData();
			}));

		// this.behaviourSubjectService.getRole.subscribe(flag => {
		// 	if (flag) {
		// 		console.log(this.getSetService.getRoleData());
		// 		this.lookingForBtn(this.getSetService.getRoleData());
		// 	}
		// });

		// This subject calls on click event
		this.subscriptions$.push(
			this.behaviourSubjectService.getRoleId$.subscribe(Id => {
				if (Id !== 0) {
					this.LookingFor = [];
					if (localStorage.getItem('roleData') !== null) {
						this.lookingId = this.getSetService.getRoleData().LookingForId;
						this.lookingForBtn(this.getSetService.getRoleData());
					}
				} else {
					const UserInfo = +this.getSetService.getmainProfileId();
					this.setLookingForBtn(UserInfo);
				}
			}));

		const UserInfo1 = +this.getSetService.getmainProfileId();
		this.setLookingForBtn(UserInfo1);

	}

	ngAfterViewInit() {

	}

	ngOnDestroy() {
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
	}


	setLookingForBtn(profileId) {
		switch (profileId) {
			case 1:
				this.data.RoleId = 1;
				if (this.lookingId === 0) {
					this.data.LookingForId = 2;
					this.lookingId = 2;
					this.lookingForBtn(this.data);
				} else {
					this.data.LookingForId = this.lookingId;
					this.lookingId = this.lookingId;
					this.lookingForBtn(this.data);
				}

				break;

			case 2:
				this.data.RoleId = 2;
				if (this.lookingId === 0) {
					this.data.LookingForId = 1;
					this.lookingId = 1;
					this.lookingForBtn(this.data);
				} else {
					this.data.LookingForId = this.lookingId;
					this.lookingId = this.lookingId;
					this.lookingForBtn(this.data);
				}
				break;

			case 3:
				this.data.RoleId = 3;
				if (this.lookingId === 0) {
					this.data.LookingForId = 1;
					this.lookingId = 1;
					this.lookingForBtn(this.data);
				} else {
					this.data.LookingForId = this.lookingId;
					this.lookingId = this.lookingId;
					this.lookingForBtn(this.data);
				}
				break;

			case 4:
				this.data.RoleId = 4;
				if (this.lookingId === 0) {
					this.data.LookingForId = 6;
					this.lookingId = 6;
					this.lookingForBtn(this.data);
				} else {
					this.data.LookingForId = this.lookingId;
					this.lookingId = this.lookingId;
					this.lookingForBtn(this.data);
				}
				break;
		}
	}



	openFiltersModal() {
		const dialogRef = this.dialog.open(FiltersModalComponent, {
			width: '35%',
			panelClass: 'my-dialog-container-class'
		});

		dialogRef.afterClosed().subscribe(result => {
			// console.log('The dialog was closed');
		});
	}


	lookingForBtn(data) {
		this.LookingFor = [];
		this.Roles = [
			{ value: 1, viewValue: 'Startup', lookingFor: [2, 3, 5] },
			{ value: 2, viewValue: 'Investor', lookingFor: [1, 5] },
			{ value: 3, viewValue: 'Co-Founder', lookingFor: [1] },
			{ value: 4, viewValue: 'Manufacturing-Business', lookingFor: [7, 6] }
		];
		this.roleId = data.RoleId;
		const looking = this.Roles.find(role => {
			if (role.value === data.RoleId) {
				return role;
			}
		});

		looking.lookingFor.forEach(element => {
			this.commonService.LookingForList.forEach(data1 => {
				if (element === data1.value) {
					data1.profileId = looking.value;
					if (data1.profileId === 2 && data1.value === 1) {
						data1.viewValue = 'Startup to Invest';
					}

					if (data1.profileId === 2 && data1.value === 5) {
						data1.viewValue = 'Startup to Buy';
					}

					if (data1.profileId === 1 && data1.value === 5) {
						data1.viewValue = 'Buyer';
					}

					if (data1.profileId === 3 && data1.value === 1) {
						// data.viewValue = 'Startup';
						data1.viewValue = 'Co-Founding Opportunities';
						// data.viewValue = 'Identifying Co-Founders for you';
					}
					this.LookingFor.push(data1);
				}
			});
		});

		of('')
			.pipe(delay(2000))
			.subscribe(() => {
				this.getLookingForList();
			});

	}

	getLookingForList() {
		const url = `${environment.ApiUrl}/api/Auth/GetUserProfileDD`;
		this.subHeaderService
			.getCuratesResults(url)
			.subscribe((response: ResponseModel<IUserCompleteLookingFor[]>) => {
				if (response.status === HttpStatusCode.OK) {
					const user = this.getSetService.getCurrentUserInfo();
					const tempArr = [];
					(response.data as IUserCompleteLookingFor[]).forEach(
						(data: IUserCompleteLookingFor) => {
							if (user.userProfileId === data.userProfileId) {
								tempArr.push(data);
							}
						}
					);

					tempArr.forEach((data: IUserCompleteLookingFor) => {
						this.LookingFor.forEach(element => {
							if (data.subProfile === element.value) {
								element.subProfile = data.subProfile;
								element.step = data.step;
								element.isComplete = data.isComplete;
								element.profile = data.profile;
								// && data.subProfile === +localStorage.getItem('lookingId')
								if (data.isComplete === true) {
									this.behaviourSubjectService.currentLookingForId(
										this.lookingId
									);
									const user1 = this.getSetService.getCurrentUserInfo();
									if (localStorage.getItem('roleData') !== null) {
										const info = {
											RoleId: user1.profileId,
											LookingForId: data.subProfile
										};
										this.lookingId = data.subProfile;
										this.getSetService.SetRoleData(info);
									}
									if (
										localStorage.getItem('roleData') !== null &&
										localStorage.getItem('roleData') !== undefined
									) {
										this.lookingId = this.getSetService.getRoleData().LookingForId;
										this.behaviourSubjectService.currentIsRoleData(true);
									}
								}
							} else {
								if (
									!element.hasOwnProperty('subProfile') ||
									element.subProfile === 0
								) {
									element.subProfile = 0;
									element.step = 1;
									element.isComplete = false;
								}
							}
						});
					});
				}
			});
	}

	getLookingForId(lookingForId: number, lookingObj): void {
		this.lookingId = lookingForId;
		sessionStorage.setItem('LookingForId', String(lookingForId));
		this.leftCuratedService.prevPageNo = 0;
		this.behaviourSubjectService.currentIsClearFilter(true);
		if (
			localStorage.getItem('roleData') === null &&
			lookingObj.isComplete === true
		) {
			const data = {
				RoleId: this.UserInfo.profileId,
				LookingForId: lookingForId
			};
			this.getSetService.SetRoleData(data);
		}

		if (lookingObj.subProfile !== 0 && lookingObj.isComplete === true) {
			this.behaviourSubjectService.currentLookingForId(lookingForId);
			const data: IFilterSearch = {
				userProfileId: 0,
				commingFrom: this.ProfileId,
				lookingFor: lookingForId,
				budgetId: 0,
				industryId: 0,
				saleId: 0,
				stageId: 0,
				roleId: 0,
				exp_Year: 0,
				timeCommitment: 0,
				countryId: 0,
				cityId: 0,
				currencyId: 0,
				expectedJoiningId: 0,
				pageSize: 10,
				pageNumber: 1
			};
			// this.behaviourSubjectService.currentIsRoleData(true);
			this.behaviourSubjectService.currentFilterData(data);
			if (localStorage.getItem('roleData') !== null) {
				this.lookingId = lookingForId;
				const changeLookingId = this.getSetService.getRoleData();
				changeLookingId.LookingForId = lookingForId;
				this.getSetService.SetRoleData(changeLookingId);
			}
		} else {
			if (
				(this.roleId === 1 && lookingForId === 2 && lookingObj.isComplete === false) ||
				(this.roleId === 1 && lookingForId === 3 && lookingObj.isComplete === false) ||
				(this.roleId === 1 && lookingForId === 5 && lookingObj.isComplete === false)
			) {
				if (lookingObj.step === Steps.Lookingfor) {
					this.behaviourSubjectService.currentIncompleteId(lookingForId);
					this.behaviourSubjectService.IsProfileFor(true);
					localStorage.setItem('isProfileinfo',String(true));

					this.router.navigateByUrl(`auth/lookingfor/startup`);
				} else if (lookingObj.step === Steps.Profile) {
					this.router.navigate(['auth/registerprofile']);
				}
			} else if (
				(this.roleId === 2 &&
					lookingForId === 1 &&
					lookingObj.isComplete === false) ||
				(this.roleId === 2 &&
					lookingForId === 5 &&
					lookingObj.isComplete === false)
			) {
				if (lookingObj.step === Steps.Lookingfor) {
					this.behaviourSubjectService.currentIncompleteId(lookingForId);
					this.behaviourSubjectService.IsProfileFor(true);
					localStorage.setItem('isProfileinfo',String(true));

					this.router.navigateByUrl(`auth/lookingfor/Investor`);
				} else if (lookingObj.step === Steps.Profile) {
					this.router.navigate(['auth/registerprofile']);
				}
			} else if (
				(this.roleId === 4 &&
					lookingForId === 6 &&
					lookingObj.isComplete === false) ||
				(this.roleId === 4 &&
					lookingForId === 7 &&
					lookingObj.isComplete === false)
			) {
				if (lookingObj.step === Steps.Lookingfor) {
					this.behaviourSubjectService.currentIncompleteId(lookingForId);
					this.behaviourSubjectService.IsProfileFor(true);
					this.router.navigateByUrl(`auth/lookingfor/mgf`);
				} else if (lookingObj.step === Steps.Profile) {
					this.router.navigate(['auth/registerprofile']);
				}
			} else if (this.roleId === 3 && lookingForId === 1) {
				if (lookingObj.step === Steps.Lookingfor) {
					this.behaviourSubjectService.currentIncompleteId(lookingForId);
					this.behaviourSubjectService.IsProfileFor(true);
					this.router.navigateByUrl(`auth/lookingfor/cofounder`);
				} else {
					this.router.navigate(['auth/registerprofile']);
				}
			}
		}
	}

	OpenKnowledgecenter() {
		this.router.navigate(['home/knowledgecenter']);
	}

	openMyPost() {
		this.router.navigate(['/mypost']);
	}
	openpostmodl() {
		// document.getElementById('uploadpostmodal').click();

		const dialogRef = this.dialog.open(UploadpostComponent, {
			width: '565px',
			height: 'auto',
			panelClass: 'upload-dialog-container-class',
			disableClose: true
		});

		dialogRef.afterClosed().subscribe(result => {
			// console.log('The dialog was closed');
		});
	}

	openFilters() {
		try {
			document.getElementById('filterBtn').click();
		} catch (error) { }
	}
}
