import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncrDecrService {
  secret = '123456$#@$^@1ERF';
  constructor() { }

  encrypt(data: any) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.secret, {
      keySize: 128 / 8,
      iv: this.secret,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  decrypt(data: any) {
    if (data !== null) {
      return JSON.parse(
        CryptoJS.enc.Utf8.stringify(
          CryptoJS.AES.decrypt(data, this.secret, {
            keySize: 128 / 8,
            iv: this.secret,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          })
        )
      );
    }
  }

  SetRememberMe(UserName: string, Password: string, encryptSecretKey: string | CryptoJS.WordArray) {
    // tslint:disable-next-line: max-line-length
    localStorage.setItem('Rem', CryptoJS.AES.encrypt(JSON.stringify(UserName + '/' + Password), encryptSecretKey).toString());
  }
  GetRemberMe(encryptSecretKey: string | CryptoJS.WordArray) {
    return JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Rem'), encryptSecretKey).toString(CryptoJS.enc.Utf8));
  }


}
