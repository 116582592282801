import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OpenPopupComponent } from 'src/app/home/profile/edit-profile/notification/open-popup/open-popup.component';
import { CommonService } from '../../Helper/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-removenotification',
  templateUrl: './removenotification.component.html',
  styleUrls: ['./removenotification.component.css']
})
export class RemovenotificationComponent implements OnInit {
  removed: any;
  notification: any;
  constructor(public dialogRef: MatDialogRef<OpenPopupComponent>, private dataService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.notification = data;
  }
  ngOnInit() {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


  // for removed notifications//
  removeNotification() {
    // tslint:disable-next-line: max-line-length
    const url = `${environment.ApiUrl}/api/Notification/RemoveNotification?NotificationId=${this.notification.notificationId}`;
    this.dataService.post(url, {}).subscribe(
      (response) => {
        this.removed = response.data;
        if (response.status === 200) {
          this.onNoClick();
        }
      });

  }


}
