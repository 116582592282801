import { Injectable } from '@angular/core';
import { EncrDecrService } from './encr-decr.service';

@Injectable({
	providedIn: 'root',
})
export class GetSetService {
	constructor(private encrDecrService: EncrDecrService) { }

	// Set Curret User

	SetUser(userInfo: any) {
		localStorage.setItem('User', this.encrDecrService.encrypt(userInfo));
	}

	getAuthKey(): string {
		const tokan = localStorage.getItem('User');

		if (tokan === null) {
			return null;
		} else {
			const user = this.encrDecrService.decrypt(tokan);
			if (user !== null) {
				return this.encrDecrService.decrypt(tokan).token;
			} else {
				return null;
			}
		}
	}

	// get user information
	getCurrentUserInfo() {
		let currentUser: any;
		const user = localStorage.getItem('User');
		if (user != null) {
			currentUser = this.encrDecrService.decrypt(user);
		} else {
			return null;
		}
		return currentUser;
	}

	// Get Login User Id
	GetLoginUserId() {
		let currentUser: any;
		const user = localStorage.getItem('User');
		if (user != null) {
			currentUser = this.encrDecrService.decrypt(user);
		} else {
			return 0;
		}
		return currentUser.Id;
	}

	// Get User Step Id
	GetStepId() {
		const user = this.getCurrentUserInfo();
		if (user !== null) {
			return user.stepId;
		} else {
			return null;
		}
	}

	// get user Substep Id
	GetSubStepId() {
		const user = this.getCurrentUserInfo();
		if (user !== null) {
			return user.subStepId;
		} else {
			return null;
		}
	}

	// Get User Role/Profile Id
	GetProfileId() {
		const user = this.getCurrentUserInfo();

		if (user !== null) {
			return user.profileId;
		} else {
			return null;
		}
	}

	// Get UserUserId
	GetUserId() {
		const user = this.getCurrentUserInfo();
		if (user !== null) {
			return user.userProfileId;
		} else {
			return null;
		}
	}

	/**
	 * Sets roleId and lookingForId in localstorage to maintain state
	 * @param roledata
	 */
	SetRoleData(roledata: any) {
		localStorage.setItem('roleData', this.encrDecrService.encrypt(roledata));
	}

	getRoleData() {
		return this.encrDecrService.decrypt(localStorage.getItem('roleData'));
	}

	setSubToken(subToken: any) {
		sessionStorage.setItem('subtoken', subToken);
	}

	getSubtoken() {
		return sessionStorage.getItem('subtoken');
	}


	setmainToken(token: any) {
		localStorage.setItem('mytoken', token);
	}

	setmainProfileId(id: any) {
		localStorage.setItem('mainProfileId', id);
	}

	getmainProfileId() {
		return localStorage.getItem('mainProfileId');
	}

	getmaintoken() {
		return localStorage.getItem('mytoken');
	}

	SetUserList(userInfo: any) {
		localStorage.setItem('list', this.encrDecrService.encrypt(userInfo));
	}

	getUserList() {
		let userList: any;
		const info = localStorage.getItem('list');
		if (info != null) {
			userList = this.encrDecrService.decrypt(info);
		} else {
			return null;
		}
		return userList;
	}

	SetRole(role: any) {
		localStorage.setItem('roleinfo', this.encrDecrService.encrypt(role));
	}

	getRole() {
		return this.encrDecrService.decrypt(localStorage.getItem('roleinfo'));
	}

	SetFilter(data: any) {
		localStorage.setItem('filterObj', this.encrDecrService.encrypt(data));
	}

	getFilter() {
		return this.encrDecrService.decrypt(localStorage.getItem('filterObj'));
	}

	SetDashboardFilter(data: any) {
		localStorage.setItem('dashfilter', this.encrDecrService.encrypt(data));
	}

	getDashboardFilter() {
		return this.encrDecrService.decrypt(localStorage.getItem('dashfilter'));
	}
}
